import React, { useState } from "react";
import { useEffect } from 'react'
import Axios from "axios"; 
import API from './Softcraftapi'; 

function Murigah() {
const[Itemlist,setItemlist] = useState([]); 
//const URL ="https://bray.softcraft.co.ke";
//Function Department
useEffect(()=> {
    const LoadItems =async () => {
      API.get("user").then((res) => {
      // http://supablogapi.supatechie.ga/api/posts’
     // Axios.get("http://localhost:5000/searchdepartment/").then((response) => {
    //  Axios.get("https://bray.softcraft.co.ke/user").then((res) => {
      //  Axios.get("http://shop.softcraft.co.ke/api/searchuser/").then((response) => {
       // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
           setItemlist(res.data) ;
           
           console.log(res.data);
        });   
       
        
      }; 
      LoadItems();
     
  
  },[])
  
  //End Department
  return (
    <div>
        {Itemlist.slice(0, 15).map((value, key) => {
            return (
              <>  
              <ul>
                xxxxxx
              {value.name}            
                
              </ul>             
                
              
              </>
   
            );
          })}
          
          

    </div>
  )
}

export default Murigah
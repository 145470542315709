import React from 'react'
import API from './Softcraftapi';
import { useState,useEffect } from "react";
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap';
import Goldman from './Goldman';

function GoldmanMain() {
    const [posts, setPosts] = useState([]); 
    
    const fetchPost = async () => {    
        API.get("searchitem").then((response) => {      
          setPosts(response.data);    
          console.log(response.data);     
        });
      };
    
      useEffect(() => {
        fetchPost();
      }, []);

      const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
    
  return (
    <>
    <Row style={{
        backgroundColor: '#8ad0d3',
      }} className="justify-content-md-center">
    <Col xs={12}>
    
    <Goldman
    suggestions  = {posts.map((person)=>(person.itemdesc))}          
    /> 
    </Col>   
    
    </Row>  

   {/*  <Row className="mb-2"> 

                <Form.Group as={Col} controlId="totalamount">
                
                <Button  value="newprint" onClick={handleClick}> PRINT</Button> 
                </Form.Group>
    </Row>  */}
    </>
  )
}

export default GoldmanMain
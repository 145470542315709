import React, {Fragment,useState,useEffect} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import API from '../Pages/Softcraftapi';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        //borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '70%',
        textAlign: 'left',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
       // paddingLeft: 8,
    },
    qty: {
        width: '10%',
       // borderRightColor: borderColor,
      //  borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
       // borderRightColor: borderColor,
       // borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 10,
    },
  });


const InvoiceTableRow = ({items}) => {
    const [orderslist, setOrders] = useState([]);
    const Mbikadoc= JSON.parse(localStorage.getItem('receiptdoc'));
    //alert(Mbikadoc)
    useEffect(() => {
        const CapUsers = async () => {
    API.get("captain/",{
        params:{
          shanzuloccode:"101",
          shanzudocno:Mbikadoc,
         
  
        },
      }).then((response) => {
        setOrders(response.data);
        console.log(response.data)


      });
    };
    CapUsers();


  }, [])

    const rows = orderslist.map( item => 
        <View style={styles.row} key={item.itemcode.toString()}>
            <Text style={styles.description}>{item.itemname}</Text>
            <Text style={styles.qty}>{item.qty}</Text>
            <Text style={styles.rate}>{item.price}</Text>
            {/* <Text style={styles.amount}>{(item.qty * item.price).toFixed(2)}</Text> */}
            <Text style={styles.amount}>{(item.qty * item.price)}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow
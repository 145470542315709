import React, { useState, useEffect, useContext, useRef  } from 'react'
import { render } from "react-dom";
import Autocomplete from "./Autocomplete";
import { useReactToPrint } from "react-to-print";
import userInput from "./Autocomplete";
import API from './Softcraftapi';
import  './Madre.css';
import { UserContext } from './UserContext';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap';





function Madre({userInput}) {  
  const componentRef = useRef();
  const ref = useRef();
  const current = new Date();
  const datenew = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
  const [softdoc, setSoftdoc] = useState(0);
    const [softdocnew, setSoftdocnew] = useState({ });
    const [posts, setPosts] = useState([]); 
    const {user,setUser}= useContext(UserContext);
    const [custname, setCust] = useState([]); 
    const [orderslist, setOrders] = useState([]);
    const [itemDetails, setitemDetails] = useState([]);
    const[tenderamount, setTenderamount]= useState(0);
    const[Change,setChange]= useState(0);
    
    const [accname, setAccname] = useState("2001");
    const Customername = accname;
    const [counter, setCounter] = useState(0);
    const [count, setCount] = useState(0);
    const [accname1, setAccname1] = useState([]);
    const [SaleTotal1, setSaleTotal] = useState({
      TotalsaleN: "0",
  
    });  
    const [Parasc, setParasc] = useState({
      newdocno: "2022",
      orderp: "0",
    });
    const [TotalAmnt, setTotalSale] = useState();
  
    const [TotalQty, setTotalItems] = useState({
      TotalSaleQty: "",
    });
    const [MajengoInfo, setMajengoInfo] = useState({
      querydocno: "",
      
    });    
    
    const [contactInfo, setContactInfo] = useState({
      itemcode: "",
      qty: "",
      retail: "",
      unitcost:"0"
      /* accname: "",
      tel: "",
      creditlimit: "",
      town: "",
      pricelevel: "",
      chkcustomer: "" */
    });
  
    
    
     var muriga = 0;
    
    
    
      const GetSoftdoc = async () => {
        //alert("Morining dumbsell")
        API.get("findsoftdoc",{
          params: {
            softsearch: accname,
          },
  
        }).then((res) => {
          
          //console.log(res.data)
          muriga = (res.data.map((person)=>(person.newdocno)))
         // alert(muriga);
          
  
          res.data.map((ItemLabels, index) => {
            setSoftdoc(ItemLabels.newdocno);
            setSoftdocnew( ItemLabels.newdocno)
            setMajengoInfo({...MajengoInfo,querydocno: ItemLabels.newdocno})
  
  
  
          })

          const ordersView = async () => {
            //alert(muriga)
          
            API.get("captain/",{
              params:{
                shanzuloccode:accname,
                shanzudocno:muriga,
               
        
              },
            }).then((response) => {
              setOrders(response.data);
              console.log(response.data)
      
      
            });
            //alert("New doc 1  " + muriga)
      
          };
          ordersView();

          const SaleReload = async () => {  
    
            API.get('totalsale/',{
              params:{
                shanzuloccode:accname,
                shanzudocno:muriga,
        
              },
            }).then((res) => { 
        
              setTotalSale(res.data);
              setSaleTotal(res.data)
              console.log(res.data)
        
              res.data.map((ItemLabels, index) => {
                setTotalSale(ItemLabels.sale);
                setSaleTotal({...SaleTotal1, TotalsaleN: ItemLabels.sale})
                // setRec(cnt+1);
               
        
              })
        
            });
        
        
          };
          SaleReload();

          

          
         
          
  
        });

        
  
  
      };

      
  
  
    

    /*  useEffect(() => {
      GetSoftdoc();
            
    }, [count])  */
  
  const fetchPost = async () => {    
    API.get("searchitem").then((response) => {      
      setPosts(response.data);    
      console.log(response.data);     
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  

  
  

  
  
  
  


  

  

  //End Search &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  //Add item $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  

  //table header
  const renderHeader1 = () => {
    let headerElement = ['item', 'qty', 'price', 'total']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toUpperCase ()} </th>
    })
  }

  
  //table body2
  const renderBody1 = () => {
    

    return orderslist.map(({ id,itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={id}>
          <td style={{ textAlign: 'left', width: '100%', fontSize: '9px',fontWeight: 'bold' }}>{itemname}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{qty}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{price}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{price * qty} </td>



        </tr>

      )
     

    })
    
  }
  

   //PRINT RECEIPT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
   
   const ComponentToPrint = React.forwardRef((props, ref) => {
    //console.log("Printing");
    //setChange(Math.round({tenderamount} - {TotalAmnt}))
    setChange(tenderamount - TotalAmnt)

   //console.log(Change)
    return (
      
      <div ref={ref} style={{ margin: "1000", padding: "0" }}>

        <br></br>
        MBIKA AGENCIES
        <br></br>
        KERUGOYA
        <br></br>
        Sale Date:{datenew};
        <br></br>
        {/* Customer: {Customername}; */}





        <table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </table>

        
       
        {/* ({TotalAmnt}) */}
        
        
        
        <br></br>
        <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {SaleTotal1.TotalsaleN}</label>
        <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Amount Tendered: {tenderamount}</label>
        <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Change: {Change}</label>
        
        Served By: Cashier
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label>

      </div>


    );
    
  });
 

  //END RECEIPT PRINT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  const handlePrint = useReactToPrint({
    documentTitle: "new documentation",
    /*  marginTop:"10px",
     marginRight:"5px",
   marginBottom:"10px",
   marginLeft:"5px", */
    //pageStyle:"@page { size: 10mm 80mm}",





    //pageStyle:"@page { size: 2.5in 4in }",
    //pageStyle: "@page { size: 80mm 1mm }",
    //pageStyle:"@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }",
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,


    

    //pagestyle: "print",
    // onAfterPrint={() => { console.log("Document Printed") }},
  });

  
  const Topbar = () => {
    const user = useContext(UserContext);
    
  
    // use `user` here
  };

  console.log(user.userInput)
 // alert(user)

  

  

  //Para Docno Save 8888888888888888888888888888888888888888888888888888888888888888888888
  const ParaAdd = () => {
    alert("Printing........9")

   API.post("paraadd/", {

    
      shanzuloccode:accname,
       shanzudocno: muriga ,
       shanzunewdocno:  Number(muriga) + Number(1),
      
         
       
   }).then(() => {



   });


 };

 //Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
 const CompSale = () => { 
  API.post("compsale/", {    
      shanzuloccode:accname1,
      shanzudocno:muriga,
      
  }).then(() => {



  });


};
 

//end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

 //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

  function SoftcraftSave(e) {
    
    window.location.reload(false);
    
   // window.location.reload(false);
   // setCount(count+1);
    
   // alert(count)
    
   //e.preventDefault();
    //window.location.reload(false);
    //alert('Receipt Completed');
    //GetSoftdoc();     
    //CompSale();
   // ParaAdd();
   // handlePrint();
   // setCount(count+1);
   // ParaAdd();
    
    //handlePrint();
    
    //window.location.reload();    
   // CartRefresh();
   // window.location.reload(false);
    


    // OrdersRefresh();
  }
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  /* useEffect(() => {
    async function makeRequest() {
      alert('before');

      await delay(10000);

      alert('after');
    }

    makeRequest();
  }); */

  const handleClick = async event => {
    
    console.log('before');
    GetSoftdoc();
    await delay(1000);
    console.log('after');
    ParaAdd();
    await delay(2000);
    console.log('end');
    
    handlePrint();
    await delay(3000);
    console.log('finish');

    window.location.reload(); 
  };
  //onChangeamount
 
  const onChangeamount = async event => {
  // const  Tenderamount = event.currentTarget.value
   setTenderamount(event.target.value)
  // handleClick();

   // alert(Tenderamount)

  }

 const onKeyDownSubmit = e => {
    //handleSubmit()

    if (e.keyCode === 13) {

      
      handleClick();
    
    }

    if (e.keyCode === 35) {

      
      handleClick();
    
    }

  }



  function SoftcraftSave1(e) {  
    GetSoftdoc();
   
    handlePrint();    
    ParaAdd();

    alert("10000")

     delay(1000000000);

    alert(100000)
    
    //handlePrint();
    
    //window.location.reload();    
   // CartRefresh();
   // window.location.reload(false);
   //SoftcraftSave();


    // OrdersRefresh();
  }

  const butstyle3 = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '110px',
    fontSize: '14px',
    height: 50,

  };

  const labelstyle3 = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '200px',
    fontSize: '20px',
    height: 50,
   // backgroundColor: '#8d8d8d',
    backgroundColor: '#ffff00',
    // #8d8d8d
    

  };


  const labelstyle2 = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '200px',
    fontSize: '20px',
    height: 50,
   // backgroundColor: '#8d8d8d',
    backgroundColor: '#ff6347',
    // #8d8d8d
    

  };

  const labelstyle = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '200px',
    fontSize: '20px',
    height: 50,
   // backgroundColor: '#8d8d8d',
    backgroundColor: '#0000cd',
    // #8d8d8d
    

  };

  
  
 
  return (
    <> 
    <Row style={{
        backgroundColor: '#8ad0d3',
      }} className="justify-content-md-center">
    <Col xs={12}>
    
    <Autocomplete
    suggestions  = {posts.map((person)=>(person.itemname))}          
    /> 
    </Col>   
    
    </Row>   
     <Row className="mb-2"> 

                <Form.Group as={Col} controlId="totalamount">
                
                <Button style={butstyle3} value="newprint" onClick={handleClick}> PRINT</Button> 
                </Form.Group>

                <Form.Group as={Col} controlId="totalamount">
                <Form.Label>CHANGE:</Form.Label>
                {/* <Form.Control style ={labelstyle3} type="text"  onChange={(e) => setTenderamount(e.target.value)}  placeholder="00.00" required/>   */}
                <Form.Control style ={labelstyle} type="text"   placeholder="00.00" value={Change} required/>  
                </Form.Group>

                <Form.Group as={Col} controlId="totalamount">
                <Form.Label>CHANGE:</Form.Label>
                {/* <Form.Control style ={labelstyle3} type="text"  onChange={(e) => setTenderamount(e.target.value)}  placeholder="00.00" required/>   */}
                <Form.Control style ={labelstyle2} type="text"   placeholder="00.00" value={Change} required/>  
                </Form.Group>
      
                <Form.Group as={Col} controlId="totalamount">
                <Form.Label>TENDERED:</Form.Label> 
                {/* <Form.Control style ={labelstyle3} type="text"  onChange={(e) => setTenderamount(e.target.value)}  placeholder="00.00" required/>   */}
                <Form.Control style ={labelstyle3} type="text"  onChange={onChangeamount}  onKeyDown={onKeyDownSubmit} placeholder="00.00" required/>  
                </Form.Group>
                
                
               
                    
                
                      
      {/* <Form.Control ref={(input) => { this.refInputitem = input; }} autoFocus type="text"  onChange={onChange} onKeyDown={onKeyDown} value={userInput} required/>       */}
        
    
                {/* <Button style={butstyle3} value="newprint" > CLEAR</Button> 
                <Button style={butstyle3} value="newprint" onClick={SoftcraftSave}> REFRESH </Button>  
                <button onClick={handleClick}>Click</button>    */}                          

            <div
 
               style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>

                

    </Row>      
   
  </>
  )
}

export default Madre
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Sabasaba.css";
import API from './Softcraftapi';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



function B12() {
    const [orderslist, setOrders] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [custname, setCust] = useState([]);
  const [accname, setAccname] = useState("");
  const [itemcode, setItemcode] = useState("");  
  const [itemname, setItemname] = useState([]);  
  const [custname0, setCust0] = useState([]);
  const [TotalAmnt, setTotalSale] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  

  
    //Load ******************************************************************
    //useEffect(() => {
        //API.get("itemdetails/" + selecteditem).then((response) => {
        const ordersView = async () => {
            console.log(accname);
            API.get("stockmovement",{
               
              params:{
                shanzuloccode:accname,
                shanzustartdate: startDate,
                shanzuenddate: endDate,
                shanzuitemcode: itemcode,
              }
            }).then((response) => {
                setOrders(response.data);
                setFilteredData(response.data);
                console.log(response.data);
            });
        };
       // ordersView();
        //ParaView();
    //}, [])

    //Customer list******************************************************
  useEffect(() => {
    const LoadCustomers = async () => {     
      API.get("searchcust").then((response) => {       
        setCust(response.data);
        console.log(response.data);
      });
    };
    LoadCustomers();
  }, [])

  //Customer list******************************************************
  useEffect(() => {
    const LoadItems = async () => {     
      API.get("stocklistcat").then((response) => {       
        setItemname(response.data);
        console.log(response.data);
      });
    };
    LoadItems();
  }, [])

  const TotalSale = async () => {
    var NewDocNo2 = accname;
    API.get("valueqty/"+ NewDocNo2).then((response) => {
      //setTotalSale(response.data);

      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  function PrintValue(e) {
    e.preventDefault();
    //additem();
    //CatAdd();    
    ordersView();
    TotalSale();
  
  }

    //End Reload Table**************************************************************
    //table header
    const renderHeader = () => {
        let headerElement = ['itemcode', 'item', 'cost', 'qtyin', 'qtyout', 'bal']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }



    //table body
    const renderBody = () => {

        return orderslist.map(({ itemcode, itemdesc, cost, qtyin, qtyout, bal }) => {
            const TotalAmount = 0;
            return (

                <tr key={itemcode} >
                    <td>{itemcode}</td>
                    <td>{itemdesc}</td>
                    <td>{cost}</td>
                    <td>{qtyin}</td>
                    <td>{qtyout}</td>
                    <td>{bal} </td>

                    {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

                </tr>

            )


        })
    }

    const handleFilter = (event) => {
      const searchWord = event.target.value;
  
      setWordEntered(searchWord);
      console.log(wordEntered)
      console.log(filteredData)
  
      const newFilter = filteredData.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchWord.toLowerCase())
  
      });
  
  
  
      if (searchWord === "") {
       // setFilteredData([]);
      } else {
        //setFilteredData(newFilter);
        setOrders(newFilter);
      }
  
      console.log(newFilter);
  
    };
  return (
    <Form>
    
    <Row className="mb-3">
    <Form.Group as={Col} controlId="accno">
    <h3>Stocks Movement</h3>
    <Row className="mb-3">
                {/* <input
                  type="text"
                  placeholder="Search Item"
                  value={wordEntered}
                  onChange={handleFilter}
                /> */}

<Form.Group as={Col} controlId="accno">
      <Form.Label>ITEM:</Form.Label>
      <br></br>
      
            <select onChange={(e) => setItemcode(e.target.value)}>
              {itemname.map(item => {
                return (<option key={item.itemname} value={item.itemcode} >{item.itemname}</option>);
              })}
            </select>
    </Form.Group>

 
  </Row>
    </Form.Group>
    <Form.Group as={Col} controlId="accno">
      <Form.Label>FROM DATE:</Form.Label>      
      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>TO DATE:</Form.Label>      
      <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
    <Form.Label>Select By:</Form.Label>
      <div>

        <input type="radio" value="Branch" name="branch" /> Branch

        <input type="radio" value="Location" name="location" /> Location        

        <input type="radio" value="All" name="all" /> All        

      </div>
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>Shop:</Form.Label>
      <br></br>
      
            <select onChange={(e) => setAccname(e.target.value)}>
              {custname.map(item => {
                return (<option key={item.accname} value={item.accno} >{item.accname}</option>);
              })}
            </select>
    </Form.Group>

    <Form.Group as={Col} controlId="accname">
        <br></br>
            <Button variant="primary" type="submit" onClick={
              PrintValue}>

              PREVIEW STOCKS
            </Button>
    </Form.Group>   

    </Row>


        <div>
            <div className="scrolling-wrappervalue">


                <Table striped bordered hover size="sm" id='employee'>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>


            </div>
        </div>

        <Form>
        <Row className="form-row">

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              PrintValue}>

              Update Stocks
            </Button>
          </div>

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit">

              Clear All
            </Button>
          </div>


          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              PrintValue}>

              Re-Print
            </Button>
          </div>

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              PrintValue}>

              EXIT
            </Button>
          </div>
          <div className="form-group col-md-3">
            <Form.Group as={Col} controlId="TotalSaleAmnt">
               <Form.Control type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} /> 
            </Form.Group>
          </div>



        </Row>
      </Form>

        </Form>

  )
}

export default B12
import React, { useState } from "react";
import { useEffect } from 'react'
import { Form, Row, Col, Button, Table } from 'react-bootstrap'
import Axios from "axios";
import API from './Softcraftapi';
import { Text, TextInput, View } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const useKeyPress = function (targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);


    function downHandler({ key }) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    });

    return keyPressed;
};

function Transfers({ placeholder, active }) {
    const [currentFruit, setCurrentFruit] = useState("")
    const [currentFruit1, setCurrentFruit1] = useState("")
     
    const [orderslist, setOrders] = useState([]);
    const [selected, setSelected] = useState(undefined);
    const [selecteditem, setItem] = useState("");
    const [saleprice, setSaleprice] = useState("");
    const downPress = useKeyPress("ArrowDown");
    const upPress = useKeyPress("ArrowUp");
    const enterPress = useKeyPress("Enter");
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);
    const [softdoc, setSoftdoc] = useState(0);
    const [count, setCount] = useState(0);
    const [softdocnew, setSoftdocnew] = useState("") 

 /*  useEffect(() => {

    }, [ordersView]) */


    useEffect(() => {
        const GetSoftdoc = async () => {
          API.get("findsofttran").then((res) => {
            //setSoftdoc(res.data);
            console.log(res.data);    
            res.data.map((ItemLabels, index) => {
              setSoftdoc(ItemLabels.trandocno);
              setSoftdocnew( ItemLabels.trandocno)  
    
    
            })
    
          });
    
    
        };
        GetSoftdoc();
    
    
      }, [])
  
  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit)
    console.log(newFruit);
    //alert(currentFruit)  
    
  }

  const changeFruit1 = (newFruit1) => {
    setCurrentFruit1(newFruit1)
    console.log(newFruit1);
    //alert(currentFruit)  
    
  }

  //Para Docno Save 8888888888888888888888888888888888888888888888888888888888888888888888
  const CompTran = () => {
    const NewDocNo1 = softdocnew
    API.post("comptran/" + NewDocNo1, {
      newdocno: softdocnew,
    }).then(() => {
      console.log("Success");

    });


  };

  const CompTran2 = () => {
    const NewDocNo1 = softdocnew
    API.post("comptran2/" + NewDocNo1, {
      newdocno: softdocnew,
    }).then(() => {
      console.log("Success");

    });


  };

  const TranAdd = () => {

    var NewDocNo2 = softdocnew;
    NewDocNo2 = NewDocNo2 + 1
    //alert(NewDocNo2);
    //NewDocNo2
    API.post("tranadd/" + NewDocNo2, {

      newdocno: softdocnew,



    }).then(() => {

      console.log(SaleInfo.itemname);



    });


  };

    
     
      
   

    useEffect(() => {
        if (filteredData.length && downPress) {
            setCursor(prevState =>
                prevState < filteredData.length - 1 ? prevState + 1 : prevState
            );
        }
    }, [downPress]);
    useEffect(() => {
        if (filteredData.length && upPress) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
        }
    }, [upPress]);
    useEffect(() => {
        if (filteredData.length && enterPress) {
            setSelected(filteredData[cursor]);
        }
    }, [cursor, enterPress]);
    useEffect(() => {
        if (filteredData.length && hovered) {
            setCursor(filteredData.indexOf(hovered));
        }
    }, [hovered]);
    //Get items $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    const [Itemlist, setItemlist] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [NitemDetails, setItemDetails] = useState([]);
    const [Parasc, setParasc] = useState({
        newdocno: "2022",
        orderp: "0",
    });
    const [Paradocno, setParadocno] = useState([]);
    const [accname, setAccname] = useState([]);
    const [accname1, setAccname1] = useState([]);
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const [custname, setCust] = useState([]);
    const [custname0, setCust0] = useState([]);
    

    

    const [TotalAmnt, setTotalSale] = useState({
        TotalSaleAmnt: "",
    });
    const [SaleTotal, setSaleTotal] = useState({
        TotalsaleN: "0",

    });
    var [SaleInfo, setSaleInfo] = useState({
        docno: "",
        itemcode: "",
        itemname: "",
        qty: "",
        nid: "",
        batchno: "",
        dated: "",
    });

    const [CustInfo1, setCustInfo1] = useState({
        fromcustacc: "",
        fromcustname: "",
                
    });

    const [CustInfo2, setCustInfo2] = useState({        
        tocustacc: "",
        tocustname: "",        
    });

    useEffect(() => {
        const LoadItems = async () => {
            API.get("searchitemstocks").then((response) => {
                setItemlist(response.data);
                console.log(response.data);
            });
        };
        LoadItems();
    }, [])

    //Load ******************************************************************
    useEffect(() => {        
        const ordersView = async () => {
            API.get("loadtrans").then((response) => {
                setOrders(response.data);

            });
        };
        ordersView();
        //ParaView();
    }, [count])

    //End Reload Table**************************************************************
    //table header
    const renderHeader = () => {
        let headerElement = ['itemcode', 'itemname', 'batchcode', 'qty', 'user']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }



    //table body
    const renderBody = () => {

        return orderslist.map(({ itemcode, itemname, orderno, qty, username }) => {
            const TotalAmount = 0;
            return (

                <tr key={itemcode} >
                    <td>{itemcode}</td>
                    <td>{itemname}</td>
                    <td>{orderno}</td>
                    <td>{qty}</td>
                    <td>{username} </td>

                    {/* <td className='opration'>
                    <button onClick={() => removeData(id)}>Delete</button>
                </td> */}

                </tr>

            )


        })
    }

    const handleFilter = (event) => {
        const searchWord = event.target.value;

        setWordEntered(searchWord);
        console.log(wordEntered)
        console.log(Itemlist)

        const newFilter = Itemlist.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchWord.toLowerCase())

        });



        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }

        console.log(filteredData);

    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };

    //End Filter $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    const items = Itemlist;
    //Filter Data $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    const Itemdetails = async () => {
        // alert(selecteditem);
        // selecteditem = selecteditem;
        // alert(selecteditem);
        API.get("transferitem/" + selecteditem).then((response) => {
            setItemDetails(response.data);
            console.log(response.data);
            NitemDetails.map((ItemLabels, index) => {
                setSaleInfo({ docno: Paradocno.newdocno, itemcode: ItemLabels.itemcode, itemname: ItemLabels.itemdesc, qty: ItemLabels.qty, nid: ItemLabels.nid, batchno: ItemLabels.batchno, dated: ItemLabels.dated });
            }
            )

        });

    };

    //End Reload Table**************************************************************
    const additem = () => {
        setCount(count+1);
        //alert(count);
       // alert(SaleInfo.itemname);
        API.post("transfer", {
            item: SaleInfo.itemname,
            icode: SaleInfo.itemcode,
            price: SaleInfo.price,
            qty: SaleInfo.qty,
            docno:  softdoc,
            unitcost: SaleInfo.unitcost,
            //dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            //dated: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`,
            dated: `${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()}`,
            customer: accname,
            batchno: SaleInfo.batchno,
            nid: SaleInfo.nid


        }).then(() => {
            console.log("Success");
            console.log(SaleInfo.itemname);

        });

    };
    //End Additem$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
    //Customer list******************************************************
    useEffect(() => {
        const LoadCustomers = async () => {
            // http://supablogapi.supatechie.ga/api/posts’
            API.get("searchcust").then((response) => {
                // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
                setCust(response.data);

                console.log(response.data);
            });


        };
        LoadCustomers();

    
    }, [])
    //Cust details
    //Customer details1******************************************************
    // useEffect(() => {
        const CustDetails1 = async () => {
            alert(test +currentFruit)
            accname = currentFruit;
            alert(accname)
            //alert(newFruit)
            // http://supablogapi.supatechie.ga/api/posts’
            API.get("custdetails1/" + accname).then((response) => {
                // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
                setCust0(response.data);
                custname0.map((ItemLabels, index) => {
                    setCustInfo1({ fromcustacc: ItemLabels.accno, fromcustname: ItemLabels.accname});
                })

                
            });


        };
      /*   CustDetails1();


    }, []) */
   
//Customer details2******************************************************
// useEffect(() => {
    const CustDetails2 = async () => {
        // http://supablogapi.supatechie.ga/api/posts’
        alert(test +currentFruit)
            accname = currentFruit;
            alert(accname)
        API.get("custdetails2").then((response) => {
            // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
            setCust0(response.data);
            custname0.map((ItemLabels, index) => {
                setCustInfo2({ tocustacc: ItemLabels.accno, tocustname: ItemLabels.accname});
            })

            
        });


    };
 /*    CustDetails2();


}, []) */

    //Function Stocks mov 1 8888888888888888888888888888888888888888888888888888888888888888888888
    const AddStock = () => {
        API.post("transmov", {
            qty: SaleInfo.qty,
            // dated: moment().format("DD-MMM-YYYY HH:mm:ss"),
            itemdesc: SaleInfo.itemname,
            itemcode: SaleInfo.itemcode,
            qtyin: SaleInfo.qty,
            docno: softdoc,
            unitcost: SaleInfo.unitcost,
            //dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            //dated: startDate,
            dated: `${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()}`,
            custname: accname,
            custcode:accname,
            loccode:accname,
            nid: SaleInfo.nid

            /* item: SaleInfo.itemname,
                icode: SaleInfo.itemcode,
                price: SaleInfo.price,
                qty: SaleInfo.qty,
                docno: Ndocno.NewDocNo,
                unitcost: SaleInfo.unitcost,
                orderp: Ndocno.orderp, */

        }).then(() => {
            console.log("Success mov");


        });

    };

    //Function Stocks mov 2 8888888888888888888888888888888888888888888888888888888888888888888888
    const AddStock2 = () => {
        API.post("transmov2", {
            qty: SaleInfo.qty,
            // dated: moment().format("DD-MMM-YYYY HH:mm:ss"),
            itemdesc: SaleInfo.itemname,
            itemcode: SaleInfo.itemcode,
            qtyin: SaleInfo.qty,
            docno: softdoc,
            unitcost: SaleInfo.unitcost,
            //dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            //dated: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`,
            dated: `${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()}`,
            custname: accname1,
            custcode:accname1,
            loccode:accname1,
            nid: SaleInfo.nid

            /* item: SaleInfo.itemname,
                icode: SaleInfo.itemcode,
                price: SaleInfo.price,
                qty: SaleInfo.qty,
                docno: Ndocno.NewDocNo,
                unitcost: SaleInfo.unitcost,
                orderp: Ndocno.orderp, */

        }).then(() => {
            console.log("Success mov");


        });

    };
    //End $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
    //Itemselect End $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
    function ApproveTrans(e) {
        e.preventDefault();        
        CompTran();
        CompTran2();
        TranAdd();
        window.location.reload(false);
      


    }
    function AddNewItem(e) {
        e.preventDefault();
        setCount(count+1);
        alert(accname)
        alert(accname1)
        CustDetails1();
        CustDetails2();
        additem();
        AddStock();
        AddStock2();
        setSaleInfo({ itemcode: "", itemname: "", qty: "1", price: 0, unitcost: 0 });
        //TableReload();
        clearInput();
        ReactCart();
        


        //  GetAmnt();



    }

    //Reload Table******************************************************************
    /*  const TableReload = async () => {
       API.get("captain").then((response) => {
         setOrders(response.data);
         console.log(response.data);
   
   
   
       });
       TableReload();
   
     };
    */
    //End Reload Table**************************************************************

    //Acc Check $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const CheckAcc =(e)=> {
    e.preventDefault();
    const getValue = e.target.value;
    setAccname(getValue)
    setCount(count + 1);
    alert(getValue);
  }

  const CheckAcc1 =(e)=> {
    e.preventDefault();
    const getValue1 = e.target.value;
    setAccname(getValue1)
    setCount(count + 1);
    alert(getValue1);
  }
    //Main UseEffect Function$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    function ReactCart() {
        console.log("Called Function");
        const [joke, setJoke] = useState("");
        useEffect(() => {
            console.log("Bush");
            const TableReload = async () =>
                API.get("captain").then((response) => {
                    setOrders(response.data);
                    console.log(response.data);
                });
            TableReload();




        }, [])

        return joke;
    }
    const [startDate, setStartDate] = useState(new Date());
    //End Main $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    //alert({softdoc})

    return (
        
        <div>
            <Form>
                <Row className="form-row">
                    <div className="form-group col-md-2">
                        <h3> Stocks Transfers</h3>
                    </div>

                    <div className="form-group col-md-2">
                        <Form.Group as={Col} controlId="accno">

                            <Form.Label>Transfer Date:</Form.Label>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                        </Form.Group>

                    </div>



                    <div className="form-group col-md-2">

                        <Form.Label>Transfer Docno:</Form.Label>

                        {/* {Parasc.map(item => {value={softdoc} */}
                        

                        <Form.Control type="text" name="docno" value={softdoc}  placeholder="orderno" required />
                        {/* })}      */}

                    </div>

                    <div className="form-group col-md-3">
                        <Form.Label>From Branch:</Form.Label>
                        <br></br>

                        {/* <select onChange={(e)=>CheckAcc(e)}> */}
                        <select onChange={(e) => setAccname(e.target.value)}>                        
                            {custname.map(item => {
                                return (<option key={item.accname} value={item.accno}  onClick={() => setAccname(item.accno)}>{item.accname}</option>);
                            })}
                        </select>
                        

                    </div>

                    <div className="form-group col-md-3">
                        <Form.Label>To Branch:</Form.Label>
                        <br></br>

                        <select onChange={(e) => setAccname1(e.target.value)}>
                            {custname.map(item => {
                                return (<option key={item.accname} value={item.accno}  onClick={() => setAccname1(item.accno)}>{item.accname}</option>);
                            })}
                        </select>

                    </div>


                </Row>

                <Row className="mb-3">
                    <Col xs={4} md={3}>

                        <Row className="mb-2">
                            <Col xs={1} md={1}>
                                <Form.Control type="text" name="itemcode" value={SaleInfo.itemcode} onChange={(e) => setSaleInfo({ ...SaleInfo, itemcode: e.target.value })} required />
                            </Col>
                            <Col>
                                <Form.Control name="itemnamevv" value={[selecteditem + "  " + saleprice]} onChange={(e) => setSaleInfo({ ...SaleInfo, itemname: e.target.value })} type="text" placeholder="Item Name" required />
                            </Col>
                        </Row>
                        <div className="search">

                            <div className="searchInputs">
                                <input
                                    type="text"
                                    placeholder={placeholder}
                                    value={wordEntered}
                                    onChange={handleFilter}

                                />
                            </div>
                        </div>

                        {filteredData.length != 0 && (

                            <div className="scrolling-wrapperorder" >

                                {filteredData.slice(0, 50).map((item, i) => (
                                    <ul>
                                        <div className={`item ${active ? "active" : ""}`}
                                            // setItem(item.name)
                                            onClick={Itemdetails}
                                            onMouseOver={() => { setItem(item.nid);  setSaleprice(item.qtyin);   }}
                                            key={item.id}
                                            active={i === cursor}

                                            onMouseEnter={() => setHovered(item)}
                                            onMouseLeave={() => setHovered(undefined)}
                                        //setSelected={setSelected}
                                        // setHovered={setHovered}
                                        >{item.name}  {item.qtyin}  </div>
                                    </ul>

                                ))}

                            </div>
                        )}
                    </Col>

                    <Col xs={8} md={8} className="mb-3 pt-2">
                        <Form>
                            <Row className="form-row">

                                <div className="form-group col-md-1">
                                    <Form.Label>code</Form.Label>
                                    <Form.Control type="text" name="itemcode" value={SaleInfo.itemcode} required />
                                </div>

                                <div className="form-group col-md-3">
                                    <Form.Label>Item</Form.Label>
                                    <Form.Control type="text" name="itemname" value={SaleInfo.itemname} required />
                                </div>

                                <div className="form-group col-md-2">
                                    <Form.Label>Qty</Form.Label>
                                    <Form.Control type="text" id="qty" name="qty"  onChange={(e) => setSaleInfo({ ...SaleInfo, qty: e.target.value })} required />
                                </div>

                                <div className="form-group col-md-1">
                                    <Form.Label>SKU</Form.Label>
                                    <Form.Control type="text" id="sku" name="sku" value={SaleInfo.nid} required />
                                </div>

                                <div className="form-group col-md-2">
                                    <Form.Label>Batch No:</Form.Label>
                                    <Form.Control type="text" id="qty2" name="qty" value={SaleInfo.batchno} required />
                                </div>

                                <div className="form-group col-md-2">
                                    <Form.Label>Dated:</Form.Label>
                                    <Form.Control type="text" id="qty" name="qty" value={SaleInfo.dated} required />
                                </div>



                                <div className="form-group col-md-1">
                                    <Form.Label>Add:</Form.Label>
                                    <Button variant="primary" type="submit" onClick={
                                        AddNewItem}>

                                        ADD
                                    </Button>
                                </div>


                            </Row>
                        </Form>
                    </Col>
                    {/* End SEarch bar$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}


                </Row>

                <div className="scrolling-wrapper2">
                    <Table striped bordered hover size="sm" id='employee'>
                        <thead>
                            <tr>{renderHeader()}</tr>
                        </thead>
                        <tbody>
                            {renderBody()}
                        </tbody>
                    </Table>

                </div>
            </Form>
            <Form>
                <Row className="form-row">
                   
                        <div className="form-group col-md-3">
                            
                            <Button variant="primary" type="submit" onClick={
                                ApproveTrans}>

                                Transfer
                            </Button>
                        </div>

                        <div className="form-group col-md-3">
                            
                            <Button variant="primary" type="submit" onClick={
                                AddNewItem}>

                               Clear All
                            </Button>
                        </div>


                        <div className="form-group col-md-3">
                            
                            <Button variant="primary" type="submit" onClick={
                                AddNewItem}>

                                Re-Print
                            </Button>
                        </div>

                        <div className="form-group col-md-3">
                           
                            <Button variant="primary" type="submit" onClick={
                                AddNewItem}>

                                EXIT
                            </Button>
                        </div>

                        


                    
                </Row>
            </Form>
        </div>
    )
}

export default Transfers
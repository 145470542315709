import React, { Component }  from 'react';
import { BrowserRouter as Router,  Routes, Route } from "react-router-dom";
import Login from "./components/Pages/Login";
import  Home  from "./components/Pages/Home";
import NavAccess from "./components/Pages/NavAccess";
import Softcraft from "./Softcraft";
import "./components/Pages/captain.css";
import { About } from "./components/Pages/About";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";
import SearchBar from "./components/Pages/SearchBar";
import Catalogue from "./components/Pages/Catalogue";
import ContactForm from "./components/Pages/ContactForm.jsx";
import Rubicon from "./components/Pages/Rubicon";
import Muriga from "./components/Pages/Muriga";
import TestNav from "./components/Pages/TestNav";
import Navbarmenu from "./components/Pages/Navbarmenu";

import Cashsale from "./components/Pages/Cashsale";
//import Autocomplete from "./AutoComplete";
import { autoCompleteData } from "./data.js";
import Murigah from "./components/Pages/Murigah";
import Captain from "./components/Pages/Madre";
import PrintComponent from "./components/Pages/PrintComponent";
import CapUser from "./components/Pages/CapUser";
import Orders from "./components/Pages/Orders";
import Grn from "./components/Pages/Grn";
import Stockvalue from "./components/Pages/Stockvalue";
import SalesRecon from "./components/Pages/SalesRecon";
import Transfers from "./components/Pages/Transfers";
import B12 from "./components/Pages/B12";
import Vipingo from "./components/Pages/Vipingo";
import NavBar from "./components/NavBar";
import Madre from "./components/Pages/Madre";
import Goldman from "./components/Pages/Goldman";
import Solace from "./components/Pages/Solace";
import Protected from "./Protected";
import {useState } from "react";
import TheSage from "./components/Pages/TheSage";
import Autocomplete from "./components/Pages/Autocomplete";
import RockBottom from "./components/Pages/RockBottom";
import Supreme from "./components/Pages/Supreme";
import FetchRandomUser from "./components/Pages/FetchRandomUser";
import Ace from "./components/Pages/Ace";
import { UserContext } from './components/Pages/UserContext';
import Menu from './components/Pages/Menu';
import Reprint from './components/Pages/Reprint';
import GoldmanMain from './components/Pages/GoldmanMain';
import GoodsRec from './components/Pages/GoodsRec';
import FirstKings from './components/Pages/FirstKings';
import GrnRep from './components/Pages/GrnRep';
import Mbika from './Mbika';
//const UserContext = React.createContext(null);




function App() {
  const [isLoggedIn, setisLoggedIn] = useState(true);
  const [user, setUser] = useState("");
  
  return (
    
    <div>
      <UserContext.Provider value={{user,setUser}}>
      {/* <UserContext.Provider value='hello Softcraft!!!!'> */}
           <NavBar/>
           {/* <Menu/> */} 
        <div className="pages">
          < Routes>
            <Route path="/" element={<Login/>} />
             {/* <Route path="/Home" element={<Home/>} />             */}
             <Route path="/Home" element={<Protected isLoggedIn={isLoggedIn}>
             <Home/>
              </Protected>} />             
            <Route path="/Capuser" element={<CapUser/>} />
            <Route path="/NavBar" element={<NavBar/>} />
            <Route path="/Softcraft" element={<Softcraft/>} />  
            <Route path="/mbika" element={<Mbika/>} />  
            {/* <Route path="/pos" element={<SearchBar/>} /> */}
          {/* <Route path="/pos" element={<Madre/>} />  */}
          <Route path="/pos" element={<GoldmanMain/>} /> 
                <Route path="/cashsale" element={<Cashsale />} />
                <Route path="/catalogue" element={<Catalogue/>} />
                <Route path="/account" element={<ContactForm/>} />
                <Route path="/murigah" element={<FirstKings/>} />
                <Route path="/receivedrep" element={<GrnRep/>} />
                <Route path="/captain" element={<Captain/>} />
                <Route path="/order" element={<Orders/>} />
                {/* <Route path="/received" element={<Grn/>} /> */}
                <Route path="/received" element={<GoodsRec/>} />
                <Route path="/transfer" element={<Transfers/>} />
                <Route path="/stockvalue" element={<Stockvalue/>} />
                <Route path="/salesrecon" element={<SalesRecon/>} />
                <Route path="/enquiry" element={<B12/>} />
                <Route path="/Madre" element={<Madre/>} />
                <Route path="/TheSage" element={<TheSage/>} />
                <Route path="/Autocomplete" element={<Autocomplete/>} />
                <Route path="/RockBottom" element={<RockBottom/>} />
                <Route path="/Supreme" element={<Supreme/>} />
                <Route path="/FetchRandomUser" element={<FetchRandomUser/>} />
                <Route path="/route" element={<Ace/>} />
                <Route path="/solace" element={<Solace/>} />
                <Route path="/reprint" element={<Reprint/>} />
                
                        
          </ Routes>
        </div>
    
        </UserContext.Provider>  
    </div>
  );
}

export default App;

import React, {Fragment,useState,useEffect} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import API from '../Pages/Softcraftapi';
import invoice from '../../data/invoice';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
       // borderBottomColor: '#bff0fd',
       // backgroundColor: '#bff0fd',
        //borderBottomWidth: 1,
        alignItems: 'center',
       // height: 10,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    row: {
        flexDirection: 'row',
       // borderBottomColor: '#bff0fd',
       // borderBottomWidth: 1,
      // marginTop: 8,
        alignItems: 'center',
        height: 8,
        fontSize: 8,
        //fontStyle: 'bold',
    },
    row1: {
        flexDirection: 'row',
       // borderBottomColor: '#bff0fd',
       // borderBottomWidth: 1,
      // marginTop: 8,
        alignItems: 'right',
        height: 8,
        fontSize: 8,
        fontStyle: 'bold',
        paddingLeft: 8
    },
    description: {
        width: '70%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        //borderRightWidth: 1,
        paddingRight: 8,
    },

    description2: {
        width: '100%',
        textAlign: 'centre',
        // borderRightColor: borderColor,
        //borderRightWidth: 1,
        paddingRight: 8,
    },

    description1: {
        width: '30%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        //borderRightWidth: 1,
        paddingRight: 8,
    },
    totalvalue: {
        width: '30%',
        textAlign: 'left',
        // borderRightColor: borderColor,
        //borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '30%',
        textAlign: 'left',
        paddingRight: 8,
    },

    total1: {
        width: '60%',
        textAlign: 'right',       
        paddingRight: 10,
    },

    Rate: {
        width: '20%',
        textAlign: 'left',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
    },
    Value: {
        width: '30%',
       // borderRightColor: borderColor,
       // borderRightWidth: 1,
    },
    Vat: {
        width: '30%',
       // borderRightColor: borderColor,
       // borderRightWidth: 1,
    },
    TotalA: {
        width: '20%'
    },
  });


const InvoiceTableFooter = ({invoice}) => {
    const [orderslist, setOrders] = useState([]);
    const Mbikadoc= JSON.parse(localStorage.getItem('receiptdoc'));
    const MbikaTendered = JSON.parse(localStorage.getItem('newtendered'));
    useEffect(() => {
        const CapUsers = async () => {
    API.get("captain/",{
        params:{
          shanzuloccode:"101",
          shanzudocno:Mbikadoc,
         
  
        },
      }).then((response) => {
        setOrders(response.data);
        console.log(response.data)


      });
    };
    CapUsers();


  }, [])
    const total = orderslist.map(item => item.qty * item.price)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    return( 
        <>
        <View style={styles.row1}>
        <Text style={styles.description}>TOTAL AMOUNT: </Text>
            {/* <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text> */}
            <Text style={styles.total1}>{ Number.parseFloat(total)}</Text>

        </View>
        

        

        
        

        <View style={styles.row1}>
            <Text style={styles.description}>TENDERED:</Text>
            {/* <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text> */}
             <Text style={styles.total1}> {MbikaTendered} </Text> 

             
            
            
        </View>
       

        <View style={styles.row1}>
            
            <Text style={styles.description}>CHANGE:</Text>
            {/* <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text> */}
            <Text style={styles.total1}>{MbikaTendered - Number.parseFloat(total)} </Text>
            
        </View>
        <View style={styles.description2}>
            <Text style={styles.totalvalue}>***************************************************************</Text> 

        </View>
        <View style={styles.description2}>
            <Text style={styles.totalvalue}>Vat analysis</Text> 

        </View>

        <View style={styles.container}>
        <Text style={styles.Rate}>Rate</Text>
        <Text style={styles.Value}>Value</Text>
        <Text style={styles.Vat}>Vat</Text>
        <Text style={styles.TotalA}>Amount</Text>
        </View>
        <View style={styles.row}>
        <Text style={styles.Value}>16%</Text>
           
            {/* <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text> */}
            <Text style={styles.Value}>{ Number.parseFloat(total/116*100).toFixed(2)}</Text>

            
            {/* <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text> */}
            <Text style={styles.Vat}>{ Number.parseFloat(total*16/116).toFixed(2)}</Text>

            <Text style={styles.TotalA}>{ Number.parseFloat(total).toFixed(2)}</Text>

            
            
        </View>
        <View style={styles.description2}>
            <Text style={styles.totalvalue}>***************************************************************</Text> 

        </View>

        

        {/* <View style={styles.row}>
            <Text style={styles.description}>*********************************************************</Text>
         
            
            
        </View> */}

        

        
        </>   

        
    )
};
  
  export default InvoiceTableFooter
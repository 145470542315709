import React from 'react'
import { useState } from "react";
import {Form,Row,Col,Button } from 'react-bootstrap'
import Axios from "axios";
import "./cashsale.css";

function cashsale() {
  return (
    <div>
        <p>Search Product</p>



        
      {/* <div className="autocomplete">      
        <input
          id="searchField" name="searchField"
          type="text"
          placeholder="Item" 
          value={wordEntered}
          onChange={handleFilter} onClick ={ItemSelect}  onKeyPress={(e) => handler(e)}
          //Testing
          //
        />
      </div> */}
     

    </div>
    
  )
}

export default cashsale
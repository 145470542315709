import React, { useState } from "react";
import { useEffect } from 'react'
import {Form,Row,Col,Button,Table } from 'react-bootstrap'
import Axios from "axios";
import API from './Softcraftapi'; 
import { Text, TextInput, View } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function SalesRecon() {
    const [orderslist, setOrders] = useState([]);
    const [text, setText] = useState('');
    const [branch, setBranch] = useState([]);
  const [branchcode, setBranchcode] = useState([]);
  const [QuerySelect, setQuerySelect] = useState("");
  const [startDate, setStartDate] = useState(new Date());
    const [custname, setCust] = useState([]);
    const [accname, setAccname] = useState("");
    const [endDate, setEndDate] = useState(new Date());
    const [TotalAmnt, setTotalSale] = useState();
  const [filteredData, setFilteredData] = useState([]);


     //Load ******************************************************************
    //useEffect(() => {
        //API.get("itemdetails/" + selecteditem).then((response) => {
          //VIew all $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
          const ordersViewall = async () => {
            console.log(accname);
            API.get("salesreconall",{
              params:{                
                shanzustartdate: startDate,
                shanzuenddate: endDate,                
              }
            }).then((response) => {
                setOrders(response.data);
                setFilteredData(response.data)
                console.log(response.data);
            });
        };
          //VIew by location $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
          const ordersView = async () => {
              console.log(accname);
              API.get("stockvalue/" + accname).then((response) => {
                  setOrders(response.data);
                  setFilteredData(response.data)
                  console.log(response.data);
              });
          };
          //View by Branch $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
          const BranchValue = async () => {
            console.log(accname);
            API.get("salesreconbranch",{
              params:{
                valBranchcode: branchcode,
                shanzustartdate: startDate,
                shanzuenddate: endDate, 
              }
            }).then((response) => {
                setOrders(response.data);
                setFilteredData(response.data)
                console.log(response.data);
            });
        };
        
    //Branch Sales $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    
  const TotalSaleall = async () => {    
    API.get("valuetotalall").then((response) => {
      //setTotalSale(response.data);
      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  const TotalSale = async () => {    
    API.get("valuetotal/"+ accname).then((response) => {
      //setTotalSale(response.data);

      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  const TotalSalebranch = async () => {    
    API.get("valuetotalbranch",{
      params:{
        valBranchcode: branchcode,
      }
      
    }).then((response) => {
      //setTotalSale(response.data);

      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  useEffect(() => {
    const LoadBranch = async () => {     
      API.get("searchcust").then((response) => {       
        setBranch(response.data);
        console.log(response.data);
      });
    };
    LoadBranch();
  }, [])

  //Customer list******************************************************
useEffect(() => {
  const LoadCustomers = async () => {     
    API.get("searchcust").then((response) => {       
      setCust(response.data);
      console.log(response.data);
    });
  };
  LoadCustomers();
}, [])

  
    
    /* //Load ******************************************************************
    useEffect(() => {
        const ordersView = async () => {
            API.get("salerecon").then((response) => {
                setOrders(response.data);

            });
        };
        ordersView();
        //ParaView();
    }, [])

    //End Reload Table************************************************************** */
    //table header
    const renderHeader = () => {
        let headerElement = ['item', 'price', 'qty','sale']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }



    //table body
    const renderBody = () => {

        return orderslist.map(({itemname,price,qty,value }) => {
            const TotalAmount = 0;
            return (              

                <tr key={itemname} >
                    <td>{itemname}</td>                    
                    <td>{price}</td>
                    <td>{qty}</td>
                    <td>{value}</td>                   

                    {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

                </tr>

            )


        })
    }

    function PrintValue(e) {
      e.preventDefault();
      //additem();
      //CatAdd();
      alert(QuerySelect);
      if(QuerySelect == 'All'){
      ordersViewall();
      TotalSaleall();
  
      } 
      else 
      if(QuerySelect == 'Branch'){
      BranchValue();
      TotalSalebranch();  
      }
      else
      if(QuerySelect == 'Location'){
      ordersView();
      TotalSale();  
        
      }
      
    
    }

 

  return (
    
    <Form>
    <h3>Sales Reconciliation</h3>
    <Row className="mb-3">
    <Form.Group as={Col} controlId="accno">
      <Form.Label>FROM DATE:</Form.Label>      
      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>TO DATE:</Form.Label>      
      <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
    </Form.Group>

    <Form.Group as={Col} controlId="accno">

    <Form.Check inline label="ALL" type="radio" id="radioA" value="All" checked={QuerySelect === "All"} onChange={(e)=>{setQuerySelect(e.target.value)}}/>
    <Form.Check inline label="by Branch" type="radio" id="radioB" value="Branch" checked={QuerySelect === "Branch"} onChange={(e)=>{setQuerySelect(e.target.value)}}/>
    <Form.Check inline label="by Location" type="radio" id="radioB" value="Location" checked={QuerySelect === "Location"} onChange={(e)=>{setQuerySelect(e.target.value)}}/>

    </Form.Group> 

    <Form.Group as={Col} controlId="accno">
      <Form.Label>Branch:</Form.Label>
      <br></br>
      
            <select onChange={(e) => setBranchcode(e.target.value)}>
              {branch.map(item => {
                return (<option key={item.branchcode} value={item.branchcode} >{item.branchname}</option>);
              })}
            </select>
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>Location:</Form.Label>
      <br></br>
      
            <select onChange={(e) => setAccname(e.target.value)}>
              {custname.map(item => {
                return (<option key={item.accno} value={item.accno} >{item.accname}</option>);
              })}
            </select>
    </Form.Group>

    <Form.Group as={Col} controlId="accname">
        <br></br>
            <Button variant="primary" type="submit" onClick={
              PrintValue}>
              PREVIEW STOCKS
            </Button>
    </Form.Group>   


    </Row>

<div className="scrolling-wrapper">


<Table striped bordered hover size="sm" id='employee'>
  <thead>
    <tr>{renderHeader()}</tr>
  </thead>
  <tbody>
    {renderBody()}
  </tbody>
</Table>


</div>


    </Form>
    
  )
}

export default SalesRecon
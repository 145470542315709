import React, { useState,useEffect } from "react";
import Axios from "axios";
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'
import "./Login.css";
import CapPass from "./CapPass";
import API from './Softcraftapi';
import {useNavigate } from "react-router-dom";

function CapUser(props) {
const [NewCapUser, setCapUser] = useState([]);
const [NewUser, setUser] = useState("Bray");
const navigate = useNavigate();

 

    //Function Department
  useEffect(() => {
    const CapUsers = async () => {      
      API.get("capusers").then((response) => {       
        setCapUser(response.data);        
      });


    };
    CapUsers();


  }, [])


  

  const handleSubmit3 = (e) => {
    e.preventDefault(); 
    setUser(e.target.value);    
    alert(e.target.value)
    console.log(NewUser)
   
    
     navigate("/Captain");
         window.location.reload(); 
    
  }
console.log(props)

  //End Department
  return (
    <div >
        <Form>
        <div className="scrolling-wrapperitem">
        
        <div className="wrapper2">

        
                {NewCapUser.slice(0, 5).map((value, key) => {
                  return (
                    <>                      
                      <button value={value.name} onClick={(e)=>handleSubmit3(e)}> {value.name}  </button>
                    </>

                  );
                })}
           </div>
           
           </div>     
        </Form>
        


              
    </div>
  )
}

export default CapUser
import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import Invoice from './components/reports/Invoice'
import invoice from './data/invoice'
import ReactPDF from '@react-pdf/renderer';

// import logo from './logo.svg';
//import './App.css';

class Mbika extends Component {
  render() {
    return (
        <Fragment>
             <PDFViewer width="1000" height="600" className="app" >
                <Invoice invoice={invoice}/>
                

                
            </PDFViewer> 
            
            {/* ReactPDF.renderToStream(<Invoice invoice={invoice} />); */}
            {/* ReactPDF.render(<Invoice invoice={invoice} />, `${__dirname}/example.pdf`); */}
        </Fragment>
    );
  }
}

export default Mbika;
import React, { forwardRef, useState, useRef } from "react";
import { useEffect } from 'react'
import Axios from "axios";
import "./SearchBar.css";
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { useReactToPrint } from "react-to-print";
import API from './Softcraftapi';
import ListExample from "./Sabasaba";
import { autoCompleteData } from "../../data.js";
import  './Madre.css';




function SearchBar({ placeholder, data, Newdata }) {

  /* constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();    this.focusTextInput = this.focusTextInput.bind(this);
  } */


  const componentRef = useRef();
  const ref = useRef();
  //const inputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [selecteditem, setItem] = useState("");
  const [itemname, setItemName] = useState("");
  const [Itemlist, setItemlist] = useState([]);
  const [custname, setCust] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [NitemDetails, setItemDetails] = useState([]);
  const get_title = useRef(null);
  const [orderslist, setOrders] = useState([]);
  const [color, setColor] = React.useState('red');
  const handleChangeO = e => setColor(e.target.value);
  const [ndocno, setNewdocno] = useState([]);
  const [TotalAmnt, setTotalSale] = useState({
    TotalSaleAmnt: "",
  });
  const [SaleTotal, setSaleTotal] = useState({
    TotalsaleN: "0",

  });

  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888
  const [Parasc, setParasc] = useState({
    newdocno: "2022",
    orderp: "0",
  });
  const [Paradocno, setParadocno] = useState([]);
  const [accname, setAccname] = useState([]);
  const Customername = accname;

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  useEffect(() => {
    const ParaView = () => {
      // http://supablogapi.supatechie.ga/api/posts’
      API.get("paraget").then((response) => {
        // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
        setParadocno(response.data);

        // console.log(response.data);
      });


    };
    ParaView();




  }, [])
  //********************* */
  function handleClick() {
    setTimeout(() => {
      API.get("paraget")
        .then(response => {
          setParadocno(response.data.results);
        });
      console.log(Paradocno);
    }, 100);
  }

  //******************************** */

  const marginTop = "10px"
  const marginRight = "5px"
  const marginBottom = "10px"
  const marginLeft = "5px"
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };
  //End style
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} style={{ margin: "1000", padding: "0" }}>

        <br></br>
        MBIKA AGENCIES
        <br></br>
        P.O BOX KERUGOYA
        <br></br>
        Sale Date:{date};
        Customer: {Customername};





        <Table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </Table>
        Served By: JM Thomas
        <br></br>
        <label style={{ textAlign: 'right', fontSize: '14px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {SaleTotal.TotalsaleN}</label>
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label>

      </div>


    );
  });

  //Total Sale***********************************************************
  useEffect(() => {
    const TotalSale = async () => {
      API.get("totalsale").then((response) => {
        //setTotalSale(response.data);
        console.log(response.data);
        setTotalSale(response.data)
      });
    };
    TotalSale();
  }, [])

  //Reload Table******************************************************************
  const TotalsaleReload = async () => {
    API.get("totalsale").then((response) => {
      setTotalSale(response.data);


    });


  };

  //End Reload Table**************************************************************

  //Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
  const CompSale = () => {
    const NewDocNo1 = Parasc.newdocno
    API.post("compsale/" + NewDocNo1, {
      newdocno: Parasc.newdocno,



    }).then(() => {
      console.log("Success");




    });


  };

  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

  //Para Docno Save 8888888888888888888888888888888888888888888888888888888888888888888888
  const ParaAdd = () => {

    var NewDocNo2 = Parasc.newdocno;
    NewDocNo2 = NewDocNo2 + 1
    //alert(NewDocNo2);
    //NewDocNo2
    API.post("paraadd/" + NewDocNo2, {

      newdocno: ndocno.newdocno,



    }).then(() => {

      console.log(SaleInfo.itemname);



    });


  };

  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

  //**************************************** */
  function SoftcraftSave(e) {
    e.preventDefault();
    //alert('Receipt Completed');     

    CompSale();
    ParaAdd();
    handlePrint();
   // TableReload();




    // OrdersRefresh();
  }
  function GetAmnt(e) {
    e.preventDefault();
    TotalsaleReload();
    console.log(TotalAmnt);
    console.log(Paradocno);
    setSaleTotal({ TotalSaleN: TotalAmnt.sale });
    console.log(SaleTotal);

    TotalAmnt.map(({ sale }) => {


      setSaleTotal({ TotalsaleN: sale })
    })

    Paradocno.map(({ newdocno, orderp }) => {


      setParasc({ newdocno: newdocno, orderp: orderp })
    })


    /*  Paradocno.map(({ newdocno, orderp }) => {
 
 
       setParasc({ newdocno: newdocno, orderp: orderp })
     }) */



  }

  function FindItem(e) {
    e.preventDefault();
    //alert('Receipt Completed');     

    Itemdetails();
    //setFilteredData([]);





    // OrdersRefresh();
  }

  function SoftScroll(e) {
    /* if (e.key === 'Enter') {
      console.log('enter press here! ')
      //ref.current.focus();
      //inputRef.focus();
      this.inputRef.current.focus();
    } */
    //this.inputRef.current.focus();
    // searchWord === "";
    //setFilteredData([]);
  }



  const handlePrint1 = (e) => {
    alert("softcraft")
    e.preventDefault();
    window.print()
  }



  const handlePrint = useReactToPrint({
    documentTitle: "new documentation",   
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,


  });
  //   const options = this.props.guests.map((guest, index) => {
  //     return {
  //        label: guest.name,
  //        value: guest,
  //        key: index
  //     }
  // })
  const [SaleInfo, setSaleInfo] = useState({
    docno: "",
    itemcode: "",
    itemname: "",
    qty: "",
    price: "",
    unitcost: "",
  });

  function AddNewItem(e) {
    e.preventDefault();
    additem();
    AddStock();
    setSaleInfo({ itemcode: "", itemname: "", qty: "1", price: 0, unitcost: 0 });

   // TableReload();
    GetAmnt();
  }
  //Clear Cart******************************************************
  const ClearCart = () => {
    var NewDocNo2 = Parasc.newdocno;

    API.delete("clearcart/" + NewDocNo2, {


    }).then(() => {
      console.log("Item deleted Successfully");
      // {StockList}
      //console.log(formData.itemname );
    });
    console.log("Test");
  };

  //StockClearCart

  const StockClearCart = () => {
    var NewDocNo2 = Parasc.newdocno;

    API.delete("clearcartstock/" + NewDocNo2, {


    }).then(() => {
      console.log("Item deleted Successfully");
      // {StockList}
      //console.log(formData.itemname );
    });
    console.log("Test");
  };

  function ClearItem(e) {
    alert('Receipt Cleared');
    ClearCart();
    StockClearCart();

  }

  //Function Stocks 8888888888888888888888888888888888888888888888888888888888888888888888
  const AddStock = () => {
    API.post("stockmov", {
      qty: SaleInfo.qty,
      // dated: moment().format("DD-MMM-YYYY HH:mm:ss"),
      itemdesc: SaleInfo.itemname,
      itemcode: SaleInfo.itemcode,
      qtyin: SaleInfo.qty,
      docno: Parasc.newdocno,
      unitcost: SaleInfo.unitcost,
      dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
      customer: "Mbika",

      /* item: SaleInfo.itemname,
          icode: SaleInfo.itemcode,
          price: SaleInfo.price,
          qty: SaleInfo.qty,
          docno: Ndocno.NewDocNo,
          unitcost: SaleInfo.unitcost,
          orderp: Ndocno.orderp, */

    }).then(() => {
      console.log("Success mov");


    });

  };

  //Reload Table******************************************************************
  const TableReload = async () => {
    API.get("captain").then((response) => {
      setOrders(response.data);


    });


  };

  //End Reload Table**************************************************************

  //Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
  const additem = () => {
    API.post("create", {
      item: SaleInfo.itemname,
      icode: SaleInfo.itemcode,
      price: SaleInfo.price,
      qty: SaleInfo.qty,
      docno: Parasc.newdocno,
      unitcost: SaleInfo.unitcost,
      dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
      customer: "Mbika",


    }).then(() => {
      console.log("Success");
      console.log(SaleInfo.itemname);

    });

  };


  useEffect(() => {
    const ordersView = async () => {
      API.get("captain").then((response) => {
        setOrders(response.data);


      });

    };
    ordersView();
    //ParaView();
  }, [])

 // useEffect(() => {
    const LoadItems = async () => {
      // http://supablogapi.supatechie.ga/api/posts’
      API.get("searchitem").then((response) => {
        // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
        setItemlist(response.data);
        //alert("Uda")

        console.log(response.data);
      });


    };
    LoadItems();


  //}, [Itemlist])

  //Customer list******************************************************
  useEffect(() => {
    const LoadCustomers = async () => {
      // http://supablogapi.supatechie.ga/api/posts’
      API.get("searchcust").then((response) => {
        // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
        setCust(response.data);

        console.log(response.data);
      });


    };
    LoadCustomers();


  }, [])

  //Once click $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  //useEffect(() => {
   /*  async function Itemdetails() {
      const response = await API.get("itemdetails/",{
      params:{    
         selecteditem: selecteditem
    
      },
    });
      const fetchedEmployees = await response.json(response);
      setItemDetails(fetchedEmployees);
    } */
   // Itemdetails();
  //}, []);

  //End click $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  //useEffect(()=> {
  //async
   const Itemdetails =  () => {
    console.log(selecteditem);
    API.get("itemdetails/",{
      params:{    
         selecteditem: selecteditem
    
      },
    } ).then((response) => {
      //console.log({itemname});
      setItemDetails(response.data);
      console.log(NitemDetails);
      NitemDetails.map((ItemLabels, index) => {
        setSaleInfo({ docno: Paradocno.newdocno, itemcode: ItemLabels.itemcode, itemname: ItemLabels.itemname, qty: "1", price: ItemLabels.retail, unitcost: ItemLabels.cost });        
        clearInput();

      }
      )     
      clearInput();

    });


  }; 

  // axios.post(url[, data[, config]])
  //     Itemdetails();


  // },[])

  useEffect(()=> {

    Itemdetails();


  },[NitemDetails])

  const deleteEmployee = (id) => {
    //const id = "TUSKER CAN";
    Axios.delete(`http://localhost:5000/delete/${id}`).then((response) => {
      setEmployeeList(
        employeeList.filter((val) => {
          return val.id != id;
        })
      );
    });
  };


  const handler = (event) => {
    setItemName(event.target.value);
    console.log(itemname);
    //setState(event.key);

    if (event.key === 'Enter') {
      console.log('enter press here! ')
    }
  };




  const ItemSelect = (event) => {
    setItem(event.target.value);
    console.log(event.target.value);
  };
  const handleFilter = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);
    console.log(wordEntered)
    console.log(Itemlist)

    const newFilter = Itemlist.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchWord.toLowerCase())

    });



    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }

    console.log(filteredData);

  };



  const handleChange = (event) => {
    setSaleInfo({ ...SaleInfo, [event.target.name]: event.target.value });
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const borderStyle = {
    border: "1px dashed navy"
  };

  const Testing = () => {
    console.log("Testing");
  };

  //const
  // HandleChange2=(event) =>{
  //       this.setPrice({price: event.target.value});  
  //       console.log(price);
  //     };

  const HandleSubmit = (event) => {
    alert('Your favorite flavor is: ' + this.state.value);
    event.preventDefault();
  }


  const StockListItems = () => {

    orderslist.map((val, key) => {
      return (
        <tr>
          <td>{val.itemcode}</td>
          <td>{val.itemname}</td>
          <td>{val.selling}</td>

        </tr>
      )


    })
  };
  //table header
  const renderHeader = () => {
    let headerElement = ['itemcode', 'itemname', 'price', 'qty', 'Amount']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  //table header
  const renderHeader1 = () => {
    let headerElement = ['item', 'qty', 'price', 'total']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toLowerCase()} </th>
    })
  }

  //table body
  const renderBody = () => {

    return orderslist.map(({ itemcode, itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={itemcode} onClick={ClearItem}>
          <td>{itemcode}</td>
          <td>{itemname}</td>
          <td>{price}</td>
          <td>{qty}</td>
          <td>{price * qty} </td>

          {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

        </tr>

      )


    })
  }

  //table body2
  const renderBody1 = () => {

    return orderslist.map(({ itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={itemname}>
          <td style={{ textAlign: 'left', width: '100%', fontSize: '9px' }}>{itemname}</td>
          <td style={{ textAlign: 'right', fontSize: '9px', width: '10%' }}>{qty}</td>
          <td style={{ textAlign: 'right', fontSize: '9px', width: '10%' }}>{price}</td>
          <td style={{ textAlign: 'right', fontSize: '9px', width: '20%' }}>{price * qty} </td>



        </tr>

      )


    })
  }


  return (
    
    <>
    
    <Row>
      <Row>
      <ListExample/>

      </Row>
     

      {/* Display Items  */}

      <Form >
      
        <Row className="form-row">


          <div ref={componentRef}>
            <div className="scrolling-wrapper2">


              <Table striped bordered hover size="sm" id='employee'>
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>
                  {renderBody()}
                </tbody>
              </Table>






            </div>
          </div>
        </Row>
      </Form>



      <form >
        <Row className="form-row">

          <div className="form-group col-md-1">
            <Button value="newprint" onClick={SoftcraftSave} size={22} color="#333"> PRINT</Button>

            {/* <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      /> */}
            <div
              style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>
          </div>

          <div className="form-group col-md-1">

            <Button value="newprint" onClick={SoftcraftSave}> CLEAR</Button>

          </div>

          <div className="form-group col-md-1">

            <Button value="newprint" onClick={GetAmnt}> HOLD</Button>

          </div>

          <div className="form-group col-md-2">

            <Button value="newprint" onClick={handleClick}> RE-PRINT</Button>

          </div>

          <div className="form-group col-md-2">

            <label> Total Qty</label>

          </div>

          <div className="form-group col-md-1">
            <label>Total sale</label>
            <label name="TotalsaleN" placeholder="Total cost 0.00" value={SaleTotal.TotalsaleN}> {SaleTotal.TotalsaleN}</label>

          </div>

        </Row>
      </form>


    </Row>
    




</>

  );

}

export default SearchBar;


class ComponentToPrint1 extends React.Component {
  render() {
    return (
      <table>
        <thead>
          <th>column 1</th>
          <th>column 2</th>
          <th>column 3</th>
        </thead>
        <tbody>
          <tr>
            <td>data 1</td>
            <td>data 2</td>
            <td>data 3</td>
          </tr>
          <tr>
            <td>data 1</td>
            <td>data 2</td>
            <td>data 3</td>
          </tr>
          <tr>
            <td>data 1</td>
            <td>data 2</td>
            <td>data 3</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
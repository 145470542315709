import React, { useState, useRef, forwardRef,useCallback  } from "react";
import { useEffect } from 'react'
import Axios from "axios";
//import "./captain.css";
import "./Vipingo";
import  './Madre.css';
// import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'
import Keyboard from "react-simple-keyboard";
import API from './Softcraftapi';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Posts from './Posts';
import Pagination from './Pagination';
import CapUser from "./CapUser";


function Captain() {

  //  let componentRef = useRef();
  const componentRef = useRef();
  const [toPrint, setToPrint] = useState(null);
  const [Itemlist, setItemlist] = useState([]);
  const [CapList, setCaplist] = useState([]);
  const [orderslist, setOrders] = useState([]);
  const [selecteditem, setItem] = useState("");
  const [Founditem, setFound] = useState("");
  const [NewQty, setNewQty] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const[recTotal,setRec] = useState("");
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);  
  const [invoiceno, setInvoiceno] = useState({
    newdocno: "12345678",
    orderp: "0"
  });
  const [softdoc, setSoftdoc] = useState(0);
  const [softdocnew, setSoftdocnew] = useState({    
  });
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
  const NewSoftcraftItem ="Bush";
  const [SaleTotal1, setSaleTotal] = useState({
    TotalsaleN: "0",

  });  
  const [TotalAmnt, setTotalSale] = useState();

  const [TotalQty, setTotalItems] = useState({
    TotalSaleQty: "",
  });
  const [founddocno, setFounddocno] = useState([]);
  const [orderno, setOrderno] = useState([]);

  const [ndocno, setNewdocno] = useState([]);
  const [accname, setAccname] = useState("2001");
  const Customername = accname;
  const Newid =0;
  var NewDocNo2 =0;

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const [movstock, setMovstock] = useState({
    qty: "",
    dated: "",
    itemdesc: "",
    itemcode: "",
    qtyin: "",
    docno: ""
  });
  
  //const [accname, setAccname] = useState("1001");

  const itemdescription="";
  const TotalRecSale =0;

  useEffect(() => {
    const VipingoView = async () => {
      API.get("searchitems").then((res) => {
        setPosts(res.data);     
      });
    };
    VipingoView();
  }, [])
   // Get current posts
   
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
   //alert(SoftcraftItem)
 
   // Change page
   const paginate = pageNumber => setCurrentPage(pageNumber);
  

  
   
  //PRINT RECEIPT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} style={{ margin: "1000", padding: "0" }}>

        <br></br>
        BUSH TEXTILES 
        <br></br>
        LEISURE
        <br></br>
        Sale Date:{date};
        <br></br>
        Customer: {Customername};





        <Table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </Table>
        Served By: Cashier1
        {/* ({TotalAmnt}) */}
        <br></br>
        <label style={{ textAlign: 'right', fontSize: '14px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {SaleTotal1.TotalsaleN}</label>
        
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label>

      </div>


    );
  });

  //END RECEIPT PRINT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

   






  const divStyle = {
    color: 'blue',
    float: 'right',
    marginRight: 20,
    marginBottom: 10,
    width: '80px',
    fontSize: '20px',
    border: '3px solid',
    text: 'center',
    height: 50,
    backgroundColor: '#2dd1d1',
    color: "white",

    // backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"

  };

  const butstyle = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '80px',
    fontSize: '14px',
    height: 50,

  };

  const butstyle3 = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '110px',
    fontSize: '14px',
    height: 50,

  };

  const butstyle1 = {
    color: 'red',
    backgroundColor: "DodgerBlue",
    float: 'left',
    marginRight: 10,
    marginBottom: 6,
    width: '150px',
    fontSize: '18px',
    height: 50,

  };

  /* const handlePrint = ReactToPrint({
    content: () => componentRef.current,
  }); */
  

  useEffect(() => {
    const GetSoftdoc = async () => {
      API.get("findsoftdoc",{
        params: {
          softsearch: accname,
        },

      }).then((res) => {
        

        res.data.map((ItemLabels, index) => {
          setSoftdoc(ItemLabels.newdocno);
          setSoftdocnew( ItemLabels.newdocno)
          setMajengoInfo({...MajengoInfo,querydocno: ItemLabels.newdocno})



        })

      });


    };
    GetSoftdoc();


  }, [])

  const GetSoftdocNew = async () => {
    API.get("findsoftdoc",{
      params: {
        softsearch: accname,
      },
    }).then((res) => {
      //setSoftdoc(res.data);

      res.data.map((ItemLabels, index) => {
        setSoftdoc(ItemLabels.newdocno);
        setSoftdocnew( ItemLabels.newdocno)



      })

    });


  };
  //Function Department
  useEffect(() => {
    const LoadItems = async () => {
      // http://supablogapi.supatechie.ga/api/posts’
      API.get("searchdepart").then((res) => {
        //Axios.get("http://shop.softcraft.co.ke/api/searchuser/").then((response) => {
        // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
        setItemlist(res.data);

        console.log(res.data);
      });


    };
    LoadItems();


  }, [])



  //Function Items 
  //useEffect(() => {
  const ItemsShow = (e) => {
    e.preventDefault();
    // http://supablogapi.supatechie.ga/api/posts’
    API.get("searchitems").then((res) => {
      //Axios.get("http://shop.softcraft.co.ke/api/searchuser/").then((response) => {
      // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
      setItemlist([]);
      setCaplist(res.data);

      console.log(res.data);
    });


  };


  

  

  //Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
  const CompSale = () => {
    const NewDocNo1 = softdocnew
    API.post("compsale/" + NewDocNo1, {
      newdocno: softdocnew,
    }).then(() => {
      console.log("Success");

    });


  };

  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

  //Para Docno Save 8888888888888888888888888888888888888888888888888888888888888888888888
  const ParaAdd = () => {

     NewDocNo2 = softdoc;
    NewDocNo2 = NewDocNo2 + 1
    //alert(NewDocNo2);
    //NewDocNo2
    API.post("paraadd/", {
      
        shanzuloccode:accname,
        shanzudocno:softdoc,
        shanzunewdocno:  softdoc + 1,

      



    }).then(() => {

      console.log(SaleInfo.itemname);



    });


  };

  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888
  function UpdateText() {
    SaleInfo.map((ItemLabels, index) => {
      setSaleInfo({ itemcode: ItemLabels.itemcode, itemname: ItemLabels.itemname, qty: "1", price: ItemLabels.retail, unitcost: ItemLabels.cost });
      //setNewamnt(ItemLabels.retail);
     alert(ItemLabels.itemname);
       itemdescription = ItemLabels.itemname;

    })
  }
  //Function Items  Ordered
  //useEffect(() => {

  const ItemOrdered =  async(e) => {
    e.preventDefault();
    // alert(selecteditem);    
    API.get("itemordered/" + selecteditem).then((res) => {
      //Axios.get("http://shop.softcraft.co.ke/searchuser/").then((response) => {
      // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {

      setFound(res.data);
      setSaleInfo(res.data);
      

      UpdateText();
      //StockCqty();

      //console.log(res.data);
      //console.log(SaleInfo);
      //setSaleInfo(res.data);
    });
    ItemOrdered();
    //ItemOrdered();
  };

async function fetchData(e) {
  //const res = await fetch("https://swapi.co/api/planets/4/");
  // selecteditem ="ALTA WINE";
 // API.get("salerecon").then((res) => {  
 // e.preventDefault();
   //  alert(selecteditem);    
    API.get("itemordered/" + selecteditem).then((res) => {  
      setFound(res.data);
    setSaleInfo(res.data);
    //setSaleInfo(res.data);
    console.log(res.data)
    
  });
  //res
   // .json()
   // .then(res => setCounter(res))
    
}

useEffect(() => {
  fetchData();  
}, []);



  
  const [SaleInfo, setSaleInfo] = useState({
    docno: "",
    itemcode: "Item",
    itemname: "",
    qty: "",
    price: "",
    unitcost: "",
  });

  const [MajengoInfo, setMajengoInfo] = useState({
    querydocno: "",
    
  });

  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

 /*  useEffect(() => {
    const ordersView = async () => {
            API.get("captain").then((res) => {
        setOrders(res.data);


      });

    };
    ordersView();
  }, []) */



 /*  function SaleTotal() {
    TotalAmnt.map((ItemLabels, index) => {
      setTotalSale({ TotalSaleAmnt: ItemLabels.sale });
      //setContactInfo({ name: "", email: "", phonenumber: "" });


    })
  }
 */

  const SaleReload = async () => {  
    
    API.get('totalsale/',{
      params:{
        shanzuloccode:accname,
        shanzudocno:softdoc,

      },
    }).then((res) => { 

      setTotalSale(res.data);
      setSaleTotal(res.data)

      res.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);
        setSaleTotal({...SaleTotal1, TotalsaleN: ItemLabels.sale})
        setRec(cnt+1);
       

      })

    });


  };

  const FindTotalsale= async()=>{   
    alert("Shanzu DocNo", + softdoc)
    API.get("totalsale",{
      params:{        
        shanzudocno:softdoc,

      },
    }).then((response) => {
      setTotalSale(response.data);
      setSaleTotal(response.data)

      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);
        setSaleTotal({...SaleTotal1, TotalsaleN: ItemLabels.sale})
        setRec(cnt+1);
        alert(SaleTotal1.TotalsaleN)

      })

     

    });

  };

  
 

  function Updatedocno(){
    alert("test 44");
  }


  //Total Sale***********************************************************
  useEffect(() => {

    const TotalSale = async () => {      
      API.get("totalsale",{
        params:{        
          shanzudocno:softdoc
  
        },


      }).then((response) => {
        setTotalSale(response.data);

        response.data.map((ItemLabels, index) => {
          setTotalSale(ItemLabels.sale);

        })


      });

    };

    TotalSale();
    TotalSale();

  }, [])
  //Total Qty************************************************************
  useEffect(() => {
    const TotalItems = async () => {
      API.get("totalitems").then((res) => {
        setTotalItems(res.data);

        res.data.map((ItemLabels, index) => {
          setTotalItems({ TotalSaleQty: ItemLabels.qty });
          //setContactInfo({ name: "", email: "", phonenumber: "" });


        })


      });

    };
    TotalItems();
  }, [])

  //depress stocks**************************************************************
  const [stockqty, setStockqty] = useState({
    newstockqty: "",
  });
  useEffect(() => {
    const StockCqty = async () => {
      // alert(selecteditem);
      API.get("itemqty").then((res) => {
        setStockqty(res.data);

        res.data.map((ItemLabels, index) => {
          setStockqty({ newstockqty: ItemLabels.stockqty });
          //setContactInfo({ name: "", email: "", phonenumber: "" });


        })


      });

    };
    StockCqty();
  }, [])
  //Clear Cart******************************************************
  const ClearCart = () => {
    var NewDocNo2 = ndocno.newdocno;

    Axios.delete("http://localhost:5000/clearcart/" + NewDocNo2, {


    }).then(() => {
      console.log("Item deleted Successfully");
      // {StockList}
      //console.log(formData.itemname );
    });
    console.log("Test");
  };

  //StockClearCart

  const StockClearCart = () => {
    var NewDocNo2 = ndocno.newdocno;

    Axios.delete("http://localhost:5000/clearcartstock/" + NewDocNo2, {


    }).then(() => {
      console.log("Item deleted Successfully");
      // {StockList}
      //console.log(formData.itemname );
    });
    console.log("Test");
  };




  //n_para***********************************************************
  /*   useEffect(() => {
      const FindPara = async () => {
        Axios.get("http://localhost:5000/findpara").then((response) => {
          setNewdocno(response.data);
  
          ndocno.map((ItemLabels, index) => {
  
            setNewdocno({ newdocno: ItemLabels.newdocno, orderp: ItemLabels.orderp});
            
    
    
          })
          console.log(response.data);
  
  
        });
  
      };
      FindPara();
    }, [])
   */
  //Reload Table******************************************************************
  const TableReload = async () => {   
    
    API.get('shanzu/',{
      params:{
        shanzuloccode:accname,
        shanzudocno:softdoc,

      },
    }).then((res) => {     
      setOrders(res.data);
      console.log(res.data)

    });


  };

  //End Reload Table**************************************************************

  //table header
  const renderHeader = () => {
    let headerElement = ['id','docno', 'itemname', 'price', 'qty', 'Amount']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }
  //table print$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  //table header
  const renderHeader1 = () => {
    let headerElement = ['item', 'qty', 'price', 'total']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toUpperCase ()} </th>
    })
  }

  //table body
  const renderBody = () => {

    return orderslist.map(({ id,itemcode,docno, itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        // onClick={() => handleDelete(id,itemcode,docno)}
        <tr key={id} >
          <td  onClick={() => handleDelete(id,itemcode,docno)}>{id} </td>
          {/* <td>{itemcode}</td> */}
          <td>{docno}</td>
          <td>{itemname}</td>
          <td>{price}</td>
          <td>{qty}</td>
          <td>{price * qty} </td>



        </tr>



      )



    })
  }
  //Table 2 Body $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  //table body2
  const renderBody1 = () => {

    return orderslist.map(({ itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={itemname}>
          <td style={{ textAlign: 'left', width: '100%', fontSize: '9px',fontWeight: 'bold' }}>{itemname}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{qty}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{price}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{price * qty} </td>



        </tr>

      )


    })
  }

  /* function SoftcraftSave(e) {
    alert('Receipt Completed');
    e.preventDefault();    
    CompSale();
    ParaAdd();
    handlePrint();
    

    
  } */
  //ClearCart
  function SoftcraftClear(e) {
    alert('Receipt Cleared');
    ClearCart();
    StockClearCart();

  }

  function SoftcraftItem(e) {
    e.preventDefault();
    alert('Receipt Completed');
    fetchData();
    // StockCqty();



    // OrdersRefresh();
  }

  function SoftcraftSave(e) {
    e.preventDefault();
    //alert('Receipt Completed');     
    CompSale();
    ParaAdd();
    handlePrint();    
    CartRefresh();
    window.location.reload(false);
    


    // OrdersRefresh();
  }

  /* const SoftcraftSave = (e) => {
    e.preventDefault();    console.log('You clicked submit.');
    {handlePrint};

  } */

  const handlePrint = useReactToPrint({
    documentTitle: "new documentation",
    /*  marginTop:"10px",
     marginRight:"5px",
   marginBottom:"10px",
   marginLeft:"5px", */
    //pageStyle:"@page { size: 10mm 80mm}",





    //pageStyle:"@page { size: 2.5in 4in }",
    //pageStyle: "@page { size: 80mm 1mm }",
    //pageStyle:"@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }",
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,




    //pagestyle: "print",
    // onAfterPrint={() => { console.log("Document Printed") }},
  });

  /* const ComponentToPrint = forwardRef((props, ref) => {
    return <div ref={ref}>hello world</div>;
  }); */
  const ref = useRef();


  const QtyAdd = (e) => {
    e.preventDefault()
    console.log(e.target.value);
    setSaleInfo({ ...SaleInfo, qty: 2 })

  }

  const handleDelete = (id,itemcode,docno)=>{
    alert(id +"DEleted!!")    
     API.delete("capdelete/",{
      params:{
        shanzuid:id,
        

      },

     }, {
    }).then(() => {
      console.log("Item deleted Successfully");      
    }); 

    API.delete("movdelete/",{
      params:{
        shanzuitemcode:itemcode,
        shanzudocno:softdoc,

      },
    }, {
    }).then(() => {
      console.log("Item deleted Successfully");      
    });
  }

  function AddNewItem(e) {
    e.preventDefault();
    alert(itemdescription)
   
   
    setCart([]);
    setSaleInfo({ itemcode: "", itemname: "", qty: "1", price: 0, unitcost: 0 });
    TableReload();    
    SaleReload();
    onChange3();
    setCnt("");
   



  }

  //const [cart, setCart] = useState([]);
  const [cart, setCart] = useState([]);
  const [cnt, setCnt] = useState("");
  const [softamnt, setSoftamnt] = useState("");
  
  //const prevCnt =0;
  

  function CartRefresh(e) {    
    e.preventDefault(); 
    //GetSoftdocNew();
    SaleReload();
    TableReload(); 
      
    
    
    
  }

  

  function Updatenum(e) {

    /* const newnum = e.target.value.replace(/\D/g, '');
    console.log(newnum);    
    setSaleInfo({ ...SaleInfo, qty: newnum }) */

    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      //  this.setState({value: e.target.value})
      //alert(e.target.value);
      setSaleInfo({ ...SaleInfo, qty: e.target.value })
    }
  }

  function WinRest(e) {
    window.location.reload();
   // CartRefresh();
  }

  function handleSubmit3(e) {
    e.preventDefault(); console.log('You clicked submit.');
    setSaleInfo({ ...SaleInfo, qty: e.target.value });


  }

  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();

  const onChange = input => {
    setInput(input);
    console.log("Input changed", input);
  };

  const handleChange = e => {
    const input = e.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  //const [cnt, setCnt] = useState(0);
  const onChange3 = (e) => {
    e.preventDefault();
    setCnt("")
    setCart([])
    setNewQty("")
    
    
    
    /* e.preventDefault();
  
    if (e.target.value === '') {
      setCnt(0)
      return;
    }
    if (e.target.checkValidity()) { //check if min=0
      setCnt(parseInt(e.target.value)) // allow only whole numbers using parseInt.
    } */
  }
  //const [cnt, setCnt] = useState(0);
  //const onIncrease = (value) => setCnt(prevCnt => prevCnt + value);

  useEffect(() => {
    function clickHanlder(e) {
      if (
        !(e.target.nodeName === "INPUT") &&
        !e.target.classList.contains("hg-button")
      ) {
        setKeyboardVisibility(false);
      }
    }

    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, []);

 // var SoftInvoice ={softdoc};
  //alert(SoftInvoice);

  const getInputValue = (event)=>{
    // show the user input value to console
    const userValue = event.target.value;

    console.log(userValue);
  };

  return (
    <Container>
      
    

    {/* Columns are always 50% wide, on mobile and desktop */}
    <Row style={{
        backgroundColor: '#8ad0d3',
      }} className="justify-content-md-center">

{/* <CapUser headline ="Software"/> */}
{/* <CapUser/> */}
<div className="scrolling-wrapperpagination">
    <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
</div>
      <Col xs={6}>
        <Row>
        <Form style={{backgroundColor: '#f1f7f5',}}>
        <div className="scrolling-wrapperitem2">
          

          <div className="wrapperbush">
          <Posts posts={currentPosts} loading={loading} />        
          </div>
          <Button style={butstyle1} value="Cart" onClick={CartRefresh}>ITEMS VIEW</Button>
        
        </div>
        <br></br>
        </Form>
        </Row>

        <Row>
          wwwwwww
        </Row>


      </Col>
      <Col xs={6}>
      <div className="scrolling-wrapperbrook">
            <Form style={{
              backgroundColor: '#f1f7f5',
            }}>

              <div ref={componentRef}>
                <Table style={{ width: "60%", border: "1px solid", margin: "auto" }} border="1">
                  <thead>
                    <tr>{renderHeader()}</tr>
                  </thead>
                  <tbody>
                    {renderBody()}
                  </tbody>
                </Table>

              </div>

            </Form>
          </div>

          <Row style={{
            backgroundColor: '#8ad0d3',
          }}>
            <Form>

              <Row className="mb-2">
                <Form.Group as={Col} controlId="newdocno">
                  <Form.Control type="text" name="newdocno" placeholder="Receipt No:" value={softdoc} onInput={Updatedocno}  required />
                </Form.Group>
                
                <Form.Group as={Col} controlId="totalqty">
                  <Form.Control type="text" name="totalqty" placeholder="QTY" value={TotalQty.TotalSaleQty} required />
                </Form.Group>


                <Form.Group as={Col} controlId="TotalSaleAmnt">
                  <Form.Control type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} />
                </Form.Group>
                <Form.Group as={Col} controlId="newstockqty">
                  <Form.Control type="text" name="newstockqty" placeholder="0" value={stockqty.newstockqty} />
                </Form.Group>                
              </Row>

              <Row className="mb-2">
                <Button style={butstyle3} value="newprint" onClick={SoftcraftSave}> PRINT</Button>
                <Button style={butstyle3} value="newprint" onClick={SoftcraftClear}> CLEAR</Button>
                <Button style={butstyle3} value="newprint" onClick={SoftcraftClear}> DELETE</Button>
                <Button style={butstyle3} value="newprint" > HOLD</Button>

                <div
 
              style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>

              </Row>

              <Row className="mb-2">
                  <Button style={butstyle1} value="refresh" onClick={WinRest}> REFRESH</Button>
                  <Button style={butstyle1} value="logout" onClick={WinRest}> LOGOUT</Button>
                  <Button style={butstyle1} value="reconciliation" onClick={WinRest}> RECONCILIATION</Button>
              </Row>

            </Form>
          </Row>

      </Col>
    </Row>
  </Container>
    
  )
}

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div>Captain</div>
    )
  }
}



export default Captain





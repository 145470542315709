import React, { Component }  from 'react';
import NavBar from "./components/NavBar";
import { BrowserRouter as Router,  Routes, Route } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";
import SearchBar from "./components/Pages/SearchBar";
import Catalogue from "./components/Pages/Catalogue";
import ContactForm from "./components/Pages/ContactForm.jsx";
import Rubicon from "./components/Pages/Rubicon";
import Muriga from "./components/Pages/Muriga";
import TestNav from "./components/Pages/TestNav";
import Navbarmenu from "./components/Pages/Navbarmenu";
import Login from "./components/Pages/Login";
import Cashsale from "./components/Pages/Cashsale";
import Autocomplete from "./AutoComplete";
import { autoCompleteData } from "./data.js";
import Murigah from "./components/Pages/Murigah";
import Captain from "./components/Pages/Captain";
import PrintComponent from "./components/Pages/PrintComponent";
import CapUser from "./components/Pages/CapUser";
import Orders from "./components/Pages/Orders";
import Grn from "./components/Pages/Grn";
import Stockvalue from "./components/Pages/Stockvalue";
import SalesRecon from "./components/Pages/SalesRecon";
import Transfers from "./components/Pages/Transfers";
import B12 from "./components/Pages/B12";
import Vipingo from "./components/Pages/Vipingo";

function Softcraft() {
  return (
<div>

{/* {<Router> */}
    
    <NavBar/>
            <div className="pages">
              < Routes>
                {/* <Route path="/" element={<Home/>} /> */}
                
              </ Routes>
            </div>
            
         {/*  </Router>
     
    }   */}

</div>

  )
}

export default Softcraft
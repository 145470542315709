import React, { Fragment,useState, useEffect } from 'react'
import API from './Softcraftapi';
import  './Madre.css';

function Supreme() {
    const [posts, setPosts] = useState([]);  
    
    const additem = () => {
    }

    const suggestionsListComponent =  () => {    
        API.get("searchitem").then((response) => {      
          setPosts(response.data);    
          console.log(response.data);     
        });
      };


  return (
    <>
    <Fragment>
        <input
          type="text"
          
        />
      
      </Fragment>

suggestionsListComponent
</>
  )
}

export default Supreme
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Sabasaba.css";
import API from './Softcraftapi';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};


function Grn({ placeholder, active }) {
  const [orderslist, setOrders] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [selecteditem, setItem] = useState("");
  const [saleprice, setSaleprice] = useState("");
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());


  useEffect(() => {
    if (filteredData.length && downPress) {
      setCursor(prevState =>
        prevState < filteredData.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);
  useEffect(() => {
    if (filteredData.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (filteredData.length && enterPress) {
      setSelected(filteredData[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (filteredData.length && hovered) {
      setCursor(filteredData.indexOf(hovered));
    }
  }, [hovered]);
  //Get items $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const [Itemlist, setItemlist] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [NitemDetails, setItemDetails] = useState([]);
  const [Parasc, setParasc] = useState({
    newdocno: "2022",
    orderp: "0",
  });
  const [Paradocno, setParadocno] = useState([]);  
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const [custname, setCust] = useState([]);
  const [accname, setAccname] = useState("1001");
  const [custname0, setCust0] = useState([]);
  const [softdocnew, setSoftdocnew] = useState({})
  const [softdoc, setSoftdoc] = useState(0);


  const [SaleTotal, setSaleTotal] = useState({
    TotalsaleN: "0",

  });
  const [SaleInfo, setSaleInfo] = useState({
    docno: "",
    itemcode: "",
    itemname: "",
    qty: "",
    price: "",
    unitcost: "",
  });

  const [TotalQty, setTotalItems] = useState({
    TotalSaleQty: "",
  });

  const [TotalAmnt, setTotalSale] = useState();


  //Total Sale***********************************************************
  //useEffect(() => {

    const TotalSale = async () => {
      var NewDocNo2 = accname;
      API.get("grnqty/"+ NewDocNo2).then((response) => {
        //setTotalSale(response.data);

        response.data.map((ItemLabels, index) => {
          setTotalSale(ItemLabels.sale);

        })


      });

    };

  //  TotalSale();


 // }, [count])

  useEffect(() => {
    const LoadItems = async () => {
      API.get("searchitem77").then((response) => {
        setItemlist(response.data);
        console.log(response.data);
      });
    };
    LoadItems();
  }, [])

  //Load ******************************************************************
  useEffect(() => {
    const ordersView = async () => {
      API.get("batchreload1").then((response) => {
        setOrders(response.data);

      });
    };
    ordersView();
    //ParaView();
  }, [count])

  //End Reload Table**************************************************************

  useEffect(() => {
    const GetSoftdoc = async () => {
      API.get("findsoftbatch").then((res) => {
        //setSoftdoc(res.data);
        console.log(res.data)

        res.data.map((ItemLabels, index) => {
          setSoftdoc(ItemLabels.batchdocno);
          setSoftdocnew(ItemLabels.batchdocno)



        })

      });


    };
    GetSoftdoc();


  }, [])
  //Para Docno Save 8888888888888888888888888888888888888888888888888888888888888888888888
  const CompBatch = () => {
    const NewDocNo1 = softdoc
    API.post("compbatch/" + NewDocNo1, {
      newdocno: softdoc,
    }).then(() => {
      console.log("Success");

    });


  };

  const [CustInfo1, setCustInfo1] = useState({
    fromcustacc: "",
    fromcustname: "",

  });


  const BatchAdd = () => {
    var NewDocNo2 = softdoc;
    NewDocNo2 = NewDocNo2 + 1
    //NewDocNo2
    API.post("batchadd/" + NewDocNo2, {
      newdocno: softdocnew,

    }).then(() => {

      console.log(SaleInfo.itemname);



    });


  };
  //Approve Batch $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  function ApproveBatch(e) {
    e.preventDefault();
    setCount(count + 1);
    CompBatch();
    BatchAdd();
    alert("Batch Saved Successfully!!!")



  }
  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  //table header
  const renderHeader = () => {
    let headerElement = ['code', 'itemcode', 'itemname', 'cost', 'qty', 'total']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }



  //table body
  const renderBody = () => {

    return orderslist.map(({ id, itemcode, itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={id} >
          <td>{id}</td>
          <td>{itemcode}</td>
          <td>{itemname}</td>
          <td>{price}</td>
          <td>{qty}</td>
          <td>{price * qty} </td>

          {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

        </tr>

      )


    })
  }

  const handleFilter = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);
    console.log(wordEntered)
    console.log(Itemlist)

    const newFilter = Itemlist.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchWord.toLowerCase())

    });



    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }

    console.log(filteredData);

  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  //End Filter $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const items = Itemlist;
  //Filter Data $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const Itemdetails = async () => {
    // alert(selecteditem);
    // selecteditem = selecteditem;
    // alert(selecteditem);
    API.get("itemdetails/" + selecteditem).then((response) => {
      setItemDetails(response.data);
      console.log(response.data);
      NitemDetails.map((ItemLabels, index) => {
        setSaleInfo({ docno: Paradocno.newdocno, itemcode: ItemLabels.itemcode, itemname: ItemLabels.itemname, qty: "1", price: ItemLabels.retail, unitcost: ItemLabels.cost });
      }
      )

    });

  };

  //End Reload Table**************************************************************
  const additem = () => {
    // alert(SaleInfo.itemname);
    API.post("createbatch", {
      item: SaleInfo.itemname,
      icode: SaleInfo.itemcode,
      price: SaleInfo.price,
      qty: SaleInfo.qty,
      docno: "2030",
      unitcost: SaleInfo.unitcost,
      //dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
      dated: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`,
      customer: accname,


    }).then(() => {
      console.log("Success");
      console.log(SaleInfo.itemname);

    });
    setCount(count + 1);

  };
  //End Additem$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
  //Customer list******************************************************
  useEffect(() => {
    const LoadCustomers = async () => {
      // http://supablogapi.supatechie.ga/api/posts’
      API.get("searchcust").then((response) => {
        // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
        setCust(response.data);

        console.log(response.data);
      });


    };
    LoadCustomers();


  }, [])
  //Function Stocks 8888888888888888888888888888888888888888888888888888888888888888888888
  useEffect(() => {
    const CustDetails = async () => {
      alert(accname)
      API.get("custdetails1/" + accname).then((response) => {
        setCust0(response.data);
        console.log(response.data);
      });
    };

  }, [accname])
  //LOcation
  const AddStock = () => {
    API.post("batchmov", {
      qty: SaleInfo.qty,
      // dated: moment().format("DD-MMM-YYYY HH:mm:ss"),
      itemdesc: SaleInfo.itemname,
      itemcode: SaleInfo.itemcode,
      qtyin: SaleInfo.qty,
      docno: "2030",
      unitcost: SaleInfo.unitcost,
      //dated: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
      dated: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`,
      customer: accname,

      /* item: SaleInfo.itemname,
          icode: SaleInfo.itemcode,
          price: SaleInfo.price,
          qty: SaleInfo.qty,
          docno: Ndocno.NewDocNo,
          unitcost: SaleInfo.unitcost,
          orderp: Ndocno.orderp, */

    }).then(() => {
      console.log("Success mov");


    });

  };
  //End $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
  //Itemselect End $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  function AddNewItem(e) {
    e.preventDefault();
    setCount(count + 1);
    additem();
    AddStock();
    TotalSale();
    setSaleInfo({ itemcode: "", itemname: "", qty: "1", price: 0, unitcost: 0 });
    //TableReload();
    clearInput();
    //ReactCart();   
  }
  //Acc Check $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  //const getValue ="Bush1";

  const CheckAcc = (e) => {
    e.preventDefault();
    const getValue = e.target.value;
    setAccname(getValue);
    setCount(count + 1);
    alert(getValue);
    alert(accname);
    //CustDetails();
  }
  //Clear cart Item $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const BatchClear = (e) => {
    e.preventDefault();
    setCount(count + 1);
    var NewDocNo2 = softdoc;

    API.delete("clearbatchcart/" + NewDocNo2, {
    }).then(() => {
      console.log("Item deleted Successfully");
      // {StockList}
      //console.log(formData.itemname );
    });
    console.log("Test");
    setCount(count + 1);
    alert("Batch Cleared!!!!")
  };
  //end delete $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$


  //Main UseEffect Function$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  function ReactCart() {
    console.log("Called Function");
    const [joke, setJoke] = useState("");
    useEffect(() => {

      console.log("Bush");
      const TableReload = async () =>
        API.get("batchreload1").then((response) => {
          setOrders(response.data);
          console.log(response.data);
        });
      TableReload();




    }, [count])

    return joke;
  }
  //End Main $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  return (
    <div>
      <Form>
        <Row className="mb-12">
          <Col xs={1} md={1}>
            <Form.Control type="text" name="itemcode" value={SaleInfo.itemcode} onChange={(e) => setSaleInfo({ ...SaleInfo, itemcode: e.target.value })} required />
          </Col >
          <Col xs={1} md={3}>
            <Form.Control name="itemnamevv" value={[selecteditem + "  " + saleprice]} onChange={(e) => setSaleInfo({ ...SaleInfo, itemname: e.target.value })} type="text" placeholder="Item Name" required />
          </Col>


          <Col xs={1} md={2}>

            {/* {Parasc.map(item => { */}
            {/* //value={softdoc} */}

            <Form.Control type="text" name="docno" value={softdoc} placeholder="orderno" required />
            {/* })}      */}

          </Col>

          <Col xs={1} md={3}>
            Location:

            {/* <select onChange={(e)=>CheckAcc(e)}>   */}
            <select onChange={(e) => setAccname(e.target.value)}>
              {custname.map(item => {
                return (<option key={item.accname} value={item.accno} >{item.accname}</option>);
              })}
            </select>

          </Col>

          <Col xs={1} md={3}>


            <Form.Label>Date Received:</Form.Label>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />


          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4} md={3}>


            <div className="search">

              <div className="searchInputs">
                <input
                  type="text"
                  placeholder={placeholder}
                  value={wordEntered}
                  onChange={handleFilter}

                />
              </div>
            </div>

            {filteredData.length != 0 && (

              <div className="scrolling-wrapperorder" >

                {filteredData.slice(0, 50).map((item, i) => (
                  <ul>
                    <div className={`item ${active ? "active" : ""}`}

                      onClick={Itemdetails}
                      onMouseOver={() => { setItem(item.name); }}
                      key={item.id}
                      active={i === cursor}

                      onMouseEnter={() => setHovered(item)}
                      onMouseLeave={() => setHovered(undefined)}
                    //setSelected={setSelected}
                    // setHovered={setHovered}
                    >{item.name}</div>
                  </ul>

                ))}

              </div>
            )}
          </Col>

          <Col xs={8} md={8} className="mb-3 pt-2">
            <Form>
              <Row className="form-row">

                <div className="form-group col-md-4">
                  <Form.Label>Item</Form.Label>
                  <Form.Control type="text" name="itemname" value={SaleInfo.itemname} required />
                </div>

                <div className="form-group col-md-2">
                  <Form.Label>Qty</Form.Label>
                  <Form.Control type="text" id="qty" name="qty" value={SaleInfo.qty} onChange={(e) => setSaleInfo({ ...SaleInfo, qty: e.target.value })} required />
                </div>

                <div className="form-group col-md-2">
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" name="price" value={SaleInfo.price} onChange={(e) => setSaleInfo({ ...SaleInfo, price: e.target.value })} placeholder="00" required />
                </div>

                <div className="form-group col-md-1">
                  <Form.Label>Add:</Form.Label>
                  <Button variant="primary" type="submit" onClick={
                    AddNewItem}>

                    ADD
                  </Button>
                </div>



              </Row>
            </Form>
          </Col>
          {/* End SEarch bar$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}


        </Row>



        <div className="scrolling-wrapper2">
          <Table striped bordered hover size="sm" id='employee'>
            <thead>
              <tr>{renderHeader()}</tr>
            </thead>
            <tbody>
              {renderBody()}
            </tbody>
          </Table>

        </div>
      </Form>

      <Form>
        <Row className="form-row">

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              ApproveBatch}>

              Update Stocks
            </Button>
          </div>

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit">

              Clear All
            </Button>
          </div>


          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              AddNewItem}>

              Re-Print
            </Button>
          </div>

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              AddNewItem}>

              EXIT
            </Button>
          </div>
          <div className="form-group col-md-3">
            <Form.Group as={Col} controlId="TotalSaleAmnt">
              <Form.Control type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} />
            </Form.Group>
          </div>



        </Row>
      </Form>
    </div>
  )
}

export default Grn
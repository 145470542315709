import React from 'react'
import Ace from "./Ace";
import API from './Softcraftapi';
import { useState,useEffect } from "react";
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap';
function Solace() {
    const [posts, setPosts] = useState([]); 
    
    const fetchPost = async () => {    
        API.get("searchitem").then((response) => {      
          setPosts(response.data);    
          console.log(response.data);     
        });
      };
    
      useEffect(() => {
        fetchPost();
      }, []);

      const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
    
      /* useEffect(() => {
        async function makeRequest() {
          alert('before');
    
          await delay(10000);
    
          alert('after');
        }
    
        makeRequest();
      }); */
    
      /* const handleClick = async event => {
        
        console.log('before');
        GetSoftdoc();
        await delay(1000);
        console.log('after');
        ParaAdd();
        await delay(2000);
        console.log('end');
        
        handlePrint();
        await delay(3000);
        console.log('finish');
    
        window.location.reload(); 
      }; */
  return (
    <>
    <Row style={{
        backgroundColor: '#8ad0d3',
      }} className="justify-content-md-center">
    <Col xs={12}>
    
    <Ace
    suggestions  = {posts.map((person)=>(person.itemname))}          
    /> 
    </Col>   
    
    </Row>  

   {/*  <Row className="mb-2"> 

                <Form.Group as={Col} controlId="totalamount">
                
                <Button  value="newprint" onClick={handleClick}> PRINT</Button> 
                </Form.Group>
    </Row>  */}
    </>
  )
}

export default Solace
import React, { useState } from "react";
import { useEffect } from 'react'
import {Form,Row,Col,Button,Table } from 'react-bootstrap'
import Axios from "axios";
import API from './Softcraftapi'; 



export default function UserForm() {

 
  
  
  const [color, setColor] = React.useState('red');
  const handleChangeO = e => setColor(e.target.value);
  const[Itemlist,setItemlist] = useState([]); 
  const[Cataloguelist,setStocklist] = useState([]);

  const [contactInfo, setContactInfo] = useState({
    accno: "",
    accname: "",
    tel: "",
    creditlimit: "",
    town: "",
    pricelevel: "",
    chkcustomer: ""
  });

  const handleChange = (event) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };

const handleSubmit = (event) => {
    event.preventDefault();
    console.log(contactInfo);
    setContactInfo({ accno: "", accname: "", tel: "",creditlimit: "",
    town: "",
    pricelevel: "",
    chkcustomer: "" });
  };

  //Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
const addacc =() => {
  API.post("addnew",{
  accno: contactInfo.accno,    
  accname: contactInfo.accname,
  tel: contactInfo.tel,
  creditlimit: contactInfo.creditlimit,
  town: contactInfo.town,
  pricelevel: contactInfo.pricelevel,
  chkcustomer: contactInfo.chkcustomer

  }).then(() => {
    console.log("Success");
    console.log(contactInfo);
    
  });
  
};

//end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888
//Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
const UpdateContact =() => {
 
  API.post("updatecontact/"+ contactInfo.accno,{
    accno: contactInfo.accno,
    accname: contactInfo.accname,
    acctel: contactInfo.tel,
    creditlimit: contactInfo.creditlimit,
    town: contactInfo.town,
    pricelevel: contactInfo.pricelevel     
    
  }).then(() => {
    console.log("Contact Updated Successfully");
   // {StockList}
   //console.log(formData.itemname );
  });
  console.log("Test");
};

//end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888
//Function Props delete customer 8888888888888888888888888888888888888888888888888888888888888888888888
const DeleteSupp =() => {
 
  API.delete('deletesupp/',{
    
    params:{    
      accno: contactInfo.accno
  
    },
        
    
  }).then(() => {
    console.log("Contact Deleted Successfully");
   // {StockList}
   //console.log(formData.itemname );
  });
  console.log("Test");
};

//end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

//Function Department
useEffect(()=> {
  const LoadItems =async () => {
    // http://supablogapi.supatechie.ga/api/posts’
    API.get("searchlevel").then((response) => {
     // Axios.get("http://pos.softcraft.co.ke/searchitem/").then((response) => {
         setItemlist(response.data) ;
         
         console.log(response.data);
      });   
     
      
    }; 
    LoadItems();
   

},[])

//End Department

 //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888
  //useEffect(()=> {
    const CustList =async() => {        
      API.get("custlist").then((response) => {      
        setStocklist(response.data) ;                 
        console.log(response.data); 
                 
      });   
     
      
    }; 

    useEffect(()=> {      
      CustList();      
    
    },[])
  //rowevents
  const rowEvents={
    onClick:(row)=>{console.log(row)}
  }

   //table header
   const renderHeader = () => {
    let headerElement = ['Accno','Accname','Tel','Creditlimit','Town','Category']

    return headerElement.map((key, index) => {
        return <th key={index}>{key.toUpperCase()}</th>
    })
}

//table body
const renderBody = () => {
 
  return Cataloguelist.map(({ accno,accname,acctel,creditlimit,town,pricelevel }) => {
    const TotalAmount =0;
      return (
        
          <tr key={accno} onClick={() => setContactInfo({accno: accno,accname: accname,tel: acctel,creditlimit: creditlimit,town: town,pricelevel: pricelevel})}>
              <td>{accno}</td>
              <td>{accname}</td>
              <td>{acctel}</td>
              <td>{creditlimit}</td>
              <td>{town}</td>              
              <td>{pricelevel}</td>            
          
          </tr>
         
      )
      
      
  })
}


//End Table ******************************************************************************
  

  return (
    <div className="form-container">
      <form>
        <div>
          <h3>Contact Form</h3>
        </div>

    

    <Row className="mb-3">
    <Form.Group as={Col} controlId="accno">
      <Form.Label>A/c No:</Form.Label>      
      <Form.Control  type="text" name="accno"  placeholder="A/c No:" onChange={(e) => setContactInfo({...contactInfo, accno: e.target.value})} value={contactInfo.accno} required/>      
    </Form.Group>

    <Form.Group as={Col} controlId="accname">
      <Form.Label>Names:</Form.Label>      
      <Form.Control  type="text" name="accname"  placeholder="Customer Details" onChange={(e) => setContactInfo({...contactInfo, accname: e.target.value})} value={contactInfo.accname} required/>      
    </Form.Group>

    <Form.Group as={Col} controlId="tel">
      <Form.Label>Tel:</Form.Label>      
      <Form.Control  type="text" name="tel"  placeholder="070000000" onChange={(e) => setContactInfo({...contactInfo, tel: e.target.value})} value={contactInfo.tel} required/>      
    </Form.Group>

    </Row>

    <Row className="mb-3">
    <Form.Group as={Col} controlId="chkcustomer">
      <Form.Label>Customer:</Form.Label>      
      <Form.Check type="checkbox" label="Can't check this" disabled />      
    </Form.Group>

    <Form.Group as={Col} controlId="creditlimit">
      <Form.Label>Credit Limit:</Form.Label>      
      <Form.Control  type="numeric" name="creditlimit"  placeholder="100000" onChange={(e) => setContactInfo({...contactInfo, creditlimit: e.target.value})} value={contactInfo.creditlimit} required/>      
    </Form.Group>

    <Form.Group as={Col} controlId="town">
      <Form.Label>Town:</Form.Label>      
      <Form.Control  type="text" name="town"  placeholder="070000000" onChange={(e) => setContactInfo({...contactInfo, town: e.target.value})} value={contactInfo.town} required/>      
    </Form.Group>

    <Form.Group as={Col} controlId="pricelevel">
      <Form.Label>Category</Form.Label>
      <Form.Select onChange={(e) => setContactInfo({...contactInfo, pricelevel: e.target.value})} value={contactInfo.pricelevel}>
      {Itemlist.map(item => {
                  return (<option key={item.levelname} value={item.levelname}>{item.levelname}</option>);
      })}
    </Form.Select>                 
    </Form.Group>
    </Row>

  <Row className="mb-3">
  <Form.Group as={Col} controlId="subsubmit"> 
    <Button variant="primary" type="submit" onClick ={addacc}>
      Save
    </Button>
  </Form.Group>

  <Form.Group as={Col} controlId="subupdate"> 
    <Button variant="primary" type="submit" onClick ={UpdateContact}>
      Update
    </Button>
  </Form.Group>

  <Form.Group as={Col} controlId="subdelete"> 
    <Button variant="primary" type="submit" onClick ={DeleteSupp}>
      Delete
    </Button>
  </Form.Group>
  
  <Form.Group as={Col} controlId="subrefresh">
  <Button variant="primary" type="submit" onClick ={CustList}>
    Refresh
  </Button>    
   </Form.Group>
  

  </Row>
    
    


        
    

    <div className="scrolling-wrapper2">                  
                      
                                   
                      <Table striped variant ="dark" bordered hover size="sm" id='stock'>
                          <thead>
                              <tr>{renderHeader()}</tr>
                          </thead>
                          <tbody>
                              {renderBody()}
                              
                          </tbody>
                      </Table>
         
       </div>
       </form> 

    </div>
  );
}
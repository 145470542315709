import React, { useState, useEffect,useRef } from "react";
import {Form,Button,Row,Col,Table } from 'react-bootstrap'
import API from './Softcraftapi'; 
import { useReactToPrint } from "react-to-print";

 

function Catalogue() {
  const componentRef = useRef();
  const ref = useRef();
  const[Cataloguelist,setStocklist] = useState([]);
  const[Itemlist,setItemlist] = useState([]); 
  const[SuppView,setSup] = useState([]); 
  const[CatView,setCat] = useState([]); 
  const [color, setColor] = React.useState('red');
  const [softdocnew, setSoftdocnew] = useState({ }) 
  const [softdoc, setSoftdoc] = useState(0);
  const handleChangeO = e => setColor(e.target.value);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  
    const [formData, setFormData] = useState({
        itemcode: "",
        itemname: "",        
        cost: "",
        superstockist: "",
        stockist: "",
        wholesale: "",
        retail: "",
        department: "",
        category: "",
        supplier: "",
        vat: ""
      })

      const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
    
    }

    const handleFilter = (event) => {
      const searchWord = event.target.value;
  
      setWordEntered(searchWord);
      console.log(wordEntered)
      console.log(filteredData)
  
      const newFilter = filteredData.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchWord.toLowerCase())
  
      });
  
  
  
      if (searchWord === "") {
       // setFilteredData([]);
      } else {
        //setFilteredData(newFilter);
        setStocklist(newFilter);
      }
  
      console.log(newFilter);
  
    };

   /*  changeDropdown(e) {
      this.setState({ dropdownValue: event.target.value });
    } */
//Get Stockcode
    useEffect(() => {
      const GetSoftdoc = async () => {
        API.get("findsoftcode").then((res) => {
          //setSoftdoc(res.data);
          console.log(res.data)
  
           res.data.map((ItemLabels, index) => {
            setSoftdoc(ItemLabels.stockcode);
            setSoftdocnew( ItemLabels.stockcode)
  
  
  
          }) 
  
        });
  
  
      };
      GetSoftdoc();
  
  
    }, [])
//Update stockcode $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
const CatAdd = () => {
  var NewDocNo2 = softdoc;
  NewDocNo2 = NewDocNo2 + 1    
  //NewDocNo2
  API.post("catadd/" + NewDocNo2, {
    newdocno: softdocnew,

  }).then(() => {

    



  });

  window.location.reload(); 
};
//Function Department
useEffect(()=> {
  const LoadItems =async () => {    
        API.get("searchdepartment").then((response) => {    
         setItemlist(response.data) ;         
         console.log(response.data);
      });   
     
      
    }; 
    LoadItems();  

},[])

//End Department

//Function category
useEffect(()=> {
  const LoadCat =async () => {    
        API.get("searchcat").then((response) => {    
         setCat(response.data) ;         
         console.log(response.data);
      });   
     
      
    }; 
    LoadCat();  

},[])

//End Department

//Function supplier
useEffect(()=> {
  const LoadSupp =async () => {    
        API.get("searchsupp").then((response) => {    
         setSup(response.data) ;         
         console.log(response.data);
      });   
     
      
    }; 
    LoadSupp();  

},[])

//End Department
    
//Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
const DeleteItem =() => {
 alert(formData.itemname);
 //const itemname = formData.itemname
 const itemname = formData.itemcode
 alert(itemname);
  API.delete('deleteitem/',{
     params:{    
    itemname: formData.itemname 

  },
  
  }).then(() => {
    console.log("Item deleted Successfully");
   // {StockList}
   //console.log(formData.itemname );
  });
  console.log("Test");
 
};

//end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888

//Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
const UpdateItem =() => {
 
  API.post("updateitem/"+ formData.itemcode,{      
    itemcode: formData.itemcode,    
    itemname: formData.itemname,    
    cost: formData.cost,
    superstockist: formData.superstockist,    
    stockist: formData.stockist,
    wholesale: formData.wholesale,
    retail: formData.retail,
    department: formData.department,
    category: formData.category,
    supplier: formData.supplier,
    vat: formData.vat 
  
  }).then(() => {
    console.log("Item Updated Successfully");
   // {StockList}
   //console.log(formData.itemname );
  });
  console.log("Test");
  window.location.reload(); 

};

//end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888


//Function Props 8888888888888888888888888888888888888888888888888888888888888888888888
const additem =() => {
  alert(formData.itemname +"  will be created")
    API.post("createitem",{
    itemcode: softdoc, //  formData.itemcode,  //
    itemname: formData.itemname,    
    cost: formData.cost,
    superstockist: formData.superstockist,    
    stockist: formData.stockist,
    wholesale: formData.wholesale,
    retail: formData.retail,
    department: formData.department,
    category: formData.category,
    supplier: formData.supplier,
    vat: formData.vat
    
      
      
    }).then(() => {
      console.log("Success");
     // {StockList}
     CatAdd();
      
    });
    
  };
  
  //end 888888888888888888888888888888888888888888888888888888888888888888888888888888888888  
  /* useEffect(() => {
    const StockList =async() => {        
        API.get("stocklist").then((response) => {      
          setStocklist(response.data) ;                 
          console.log(response.data); 
                   
        });   
       
        
      }; 
},[]); */


useEffect(() => {
  const StockListcat = async () => {
    API.get("stocklistcat").then((response) => {
      setFilteredData(response.data);
      setStocklist(response.data);
      
      console.log(response.data);
    });
  };
  StockListcat();
}, [])


function AddCatalogue(e) {
  additem();
  CatAdd();

  

}
    //rowevents
    const rowEvents={
      onClick:(row)=>{console.log(row)}
    }

    function PrintStocks(e) {
      e.preventDefault();
      handlePrint();
      
    
    }

    const handlePrint = useReactToPrint({    
      documentTitle: "new documentation",   
      headerElement: "BUSH TEXTILES",
      textAlign: 'center',
      format: 'CODE128',
      fontSize: 8,
  
  
      content: () => componentRef.current,
  
  
    });

  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} style={{ margin: "1000", padding: "0" }}>

        <br></br>
        BUSH TEXTILES
        <br></br>
        P.O BOX MOMBASA
        <br></br>
        





        <Table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </Table>
        Printed By: Admin
        <br></br>
        {/* <label style={{ textAlign: 'right', fontSize: '14px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {SaleTotal.TotalsaleN}</label> */}
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label>

      </div>


    );
  });
  
  //table header
    const renderHeader = () => {
      let headerElement = ['itemcode', 'itemname', 'cost', 'superstockist','stockist','wholesale','retail','department','category','supplier','vat']
  
      return headerElement.map((key, index) => {
          return <th key={index}>{key.toUpperCase()}</th>
      })
  }

   //table header1
   const renderHeader1 = () => {
    let headerElement = ['item',  'Price']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toLowerCase()} </th>
    })
  }
  
  

  //Total Sale***********************************************************
  
   const renderBody = () => {
   
    return Cataloguelist.map(({ itemcode,itemname,cost,superstockist,stockist,wholesale,retail,department,category,supplier,vat }) => {
      const TotalAmount =0;
        return (
          
          
            <tr key={itemcode}   onClick={() => setFormData({itemcode: itemcode, itemname: itemname,cost: cost,superstockist: superstockist,stockist: stockist,wholesale: wholesale,retail: retail,department: department, category: category, supplier: supplier, vat: vat})}>
                <td>{itemcode}</td>
                <td>{itemname}</td>
                <td>{cost}</td>
                <td>{superstockist}</td>
                <td>{stockist}</td>              
                <td>{wholesale}</td>              
                <td>{retail}</td>              
                <td>{department}</td>              
                <td>{category}</td>              
                <td>{supplier}</td>              
                <td>{vat}</td>              
                
                
            </tr>
           
        )
        
        
    })
  }


    //table body2
    const renderBody1 = () => {

      return Cataloguelist.map(({ itemname, retail }) => {
        const TotalAmount = 0;
        return (
  
          <tr key={itemname}>
            <td style={{ textAlign: 'left', width: '100%', fontSize: '10px',fontWeight: 'bold' }}>{itemname}</td>
            {/* <td style={{ textAlign: 'right', fontSize: '9px', width: '10%' }}>{qty}</td> */}
            {/* <td style={{ textAlign: 'right', fontSize: '9px', width: '10%' }}>{price}</td> */}
            <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{retail} </td> 
  
  
          </tr>
  
        )
  
  
      })
    }
  
 
  //End Table ******************************************************************************
  return (
  <div>
    <Form>
  <Row className="mb-2">
            <div
              style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>
    <Form.Group as={Col} controlId="itemcode">
      <Form.Label>Item Code</Form.Label> 
      {/* //value={formData.itemcode}      */}
      {/* value={softdoc} */}
      <Form.Control onChange={(e) => setFormData({...formData, itemcode: e.target.value})}  value={softdoc} type="text" name="itemcode"  placeholder="Item Code" required/>
    </Form.Group>

    <Form.Group as={Col} controlId="item">
      <Form.Label>Item Description</Form.Label>
      <Form.Control onChange={(e) => setFormData({...formData, itemname: e.target.value})} value={formData.itemname} type="text" placeholder="Item Name" width={600} required/>
    </Form.Group>

    <Form.Group as={Col} controlId="department">
      <Form.Label>Department</Form.Label>
      {/* <select value={this.state.dropdownValue} onChange={this.changeDropdown}>
                <option value="">--Please choose an option--</option> */}
      <Form.Select onChange={(e) => setFormData({...formData, department: e.target.value})} value={formData.department}>
      {Itemlist.map(item => {
                  return (<option key={item.departmentname} value={item.departmentname}>{item.departmentname}</option>);
      })}
    </Form.Select>                 
    </Form.Group>

    <Form.Group as={Col} controlId="category">
      <Form.Label>Category</Form.Label>
      <Form.Select onChange={(e) => setFormData({...formData, category: e.target.value})} value={formData.category}>
      {CatView.map(item => {
                  return (<option key={item.catname} value={item.catname}>{item.catname}</option>);
      })}
    </Form.Select>      
    </Form.Group>

    <Form.Group as={Col} controlId="vat">
      <Form.Label>Vat</Form.Label>
      <Form.Select onChange={(e) => setFormData({...formData, vat: e.target.value})} value={formData.vat}>
      <option>STANDARD</option>
      <option>ZERORATED</option>
      <option>EXPEMPTED</option>
      
    </Form.Select>      
    </Form.Group>

    
  </Row>
  <Row className="mb-3"> 
  <Form.Group as={Col} controlId="cost">
    <Form.Label>Unit Cost</Form.Label>
    <Form.Control onChange={(e) => setFormData({...formData, cost: e.target.value})} value={formData.cost} type="text" placeholder="Buying Price" />
  </Form.Group>

  <Form.Group as={Col} controlId="superstockist">
    <Form.Label>Superstockist</Form.Label>
    <Form.Control onChange={(e) => setFormData({...formData, superstockist: e.target.value})} value={formData.superstockist} type="text" placeholder="Selling Price" />
  </Form.Group>

  <Form.Group as={Col} controlId="stockist">
    <Form.Label>Stockist</Form.Label>
    <Form.Control onChange={(e) => setFormData({...formData, stockist: e.target.value})} value={formData.stockist} type="text" placeholder="Selling Price" />
  </Form.Group>

  <Form.Group as={Col} controlId="wholesale">
    <Form.Label>Wholesale</Form.Label>
    <Form.Control onChange={(e) => setFormData({...formData, wholesale: e.target.value})} value={formData.wholesale} type="text" placeholder="Selling Price" />
  </Form.Group>

  <Form.Group as={Col} controlId="retail">
    <Form.Label>Retail</Form.Label>
    <Form.Control onChange={(e) => setFormData({...formData, retail: e.target.value})} value={formData.retail} type="text" placeholder="Selling Price" />
  </Form.Group>

  <Form.Group as={Col} controlId="supplier">
      <Form.Label>Supplier</Form.Label>
      <Form.Select onChange={(e) => setFormData({...formData, supplier: e.target.value})} value={formData.supplier}>
      {SuppView.map(item => {
                  return (<option key={item.suppliername} value={item.suppliername}>{item.suppliername}</option>);
      })}
      
    </Form.Select>      
    </Form.Group>
  
  </Row>
  
  

  

  

  <Row className="mb-3">
  <Form.Group as={Col} controlId="subsubmit"> 
    <Button variant="primary" type="submit" onClick ={AddCatalogue}>
      Save
    </Button>
  </Form.Group>
  {/* onClick ={UpdateItem} */}
  <Form.Group as={Col} controlId="subupdate"> 
    <Button variant="primary" type="submit" onClick ={UpdateItem}>
      Update
    </Button>
  </Form.Group>

  <Form.Group as={Col} controlId="subdelete"> 
    <Button variant="primary" type="submit" onClick ={DeleteItem}>
      Delete
    </Button>
  </Form.Group>
  {/* onClick ={StockList} */}
  <Form.Group as={Col} controlId="subrefresh">
  <Button variant="primary" type="submit" >
    Refresh
  </Button>
    
   </Form.Group>

   <Form.Group as={Col} controlId="subrefresh">
  <Button variant="primary" type="submit" onClick ={PrintStocks}>
    Catalogue
  </Button>
    
   </Form.Group>

   <Form.Group as={Col} controlId="itemcode">      
      {/* //value={formData.itemcode}      */}
      {/* value={softdoc} */}
      <Form.Control onChange={(e) => setFormData({...formData, itemcode: e.target.value})}  value={formData.itemcode} type="text" name="itemcode"   required/>
    </Form.Group>
  

  </Row>
  <Row className="mb-3">
                <input
                  type="text"
                  placeholder="Search Item"
                  value={wordEntered}
                  onChange={handleFilter}
                />
  
 
  </Row>

  

  </Form>
  
  <div className="scrolling-wrapper2">                  
                      
                                   
                     <Table striped variant ="dark" bordered hover size="sm" id='stock'>
                         <thead>
                             <tr>{renderHeader()}</tr>
                         </thead>
                         <tbody>
                              {renderBody()} 
                             
                         </tbody>
                     </Table>
        
      </div>

      </div>

  )
}

export default Catalogue
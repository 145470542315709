import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        //marginTop: 8
    },
    reportTitle:{
        fontSize: 8,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
  });


  const InvoiceThankYouMsg = () => (
    <>

    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Served By:Admin</Text>
    </View>

   {/*  <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Thank you for your business</Text>
    </View> */}

    

<View style={styles.titleContainer}>
<Text style={styles.reportTitle}>*****Powered By: SOFTCRAFT {'\u00A9'} 2022*****</Text>
</View>

</>


  );
  
  export default InvoiceThankYouMsg
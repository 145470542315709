import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 10,
        textAlign: 'left',
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 8,
            textAlign: 'left',
            fontStyle: 'bold',
            width: 80,
            paddingLeft:'20',
    },

    invoiceDate1: {
        fontSize: 8,
        textAlign: 'right',
        fontStyle: 'bold',
        paddingRight:'15',
},
    label: {
        width: 70,
        textAlign: 'left',
    },
    label1: {
        width: 70,
        textAlign: 'right',
    }
    
  });


  const InvoiceNo = ({invoice}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Doc No:</Text>
                <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
                <Text style={styles.label1}>Date: </Text>
                <Text style={styles.invoiceDate1}>{invoice.trans_date}</Text>
            </View >
            

            
        </Fragment>
  );
  
  export default InvoiceNo
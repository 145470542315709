import React, { useState, useEffect,useRef } from "react";
import ReactDOM from "react-dom";
import "./Sabasaba.css";
import API from './Softcraftapi';
import { Container, Col, Row, Table, Form, Button,Radio } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
// import { DownloadTableExcel } from 'react-export-table-to-excel';
import {ExportToExcel} from './ExportToExcel'
function Stockvalue() {
  const componentRef = useRef();
  const ref = useRef();
    const [orderslist, setOrders] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [custname, setCust] = useState([]);
  const [accname, setAccname] = useState("");
  const [custname0, setCust0] = useState([]);
  const [TotalAmnt, setTotalSale] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchcode, setBranchcode] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [QuerySelect, setQuerySelect] = useState("");
  const fileName = "myfile";
  
  
    //Load ******************************************************************
    //useEffect(() => {
        //API.get("itemdetails/" + selecteditem).then((response) => {
          //VIew all $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
        const ordersViewall = async () => {
          console.log(accname);
          API.get("stockvalueall").then((response) => {
              setOrders(response.data);
              setFilteredData(response.data)
              console.log(response.data);
          });
      };
        //VIew by location $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
        const ordersView = async () => {
            console.log(accname);
            API.get("stockvalue/" + accname).then((response) => {
                setOrders(response.data);
                setFilteredData(response.data)
                console.log(response.data);
            });
        };
        //View by Branch $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
        const BranchValue = async () => {
          console.log(accname);
          API.get("branchvalue",{
            params:{
              valBranchcode: branchcode,
            }
          }).then((response) => {
              setOrders(response.data);
              setFilteredData(response.data)
              console.log(response.data);
          });
      };


       // ordersView();
        //ParaView();
    //}, [])

    useEffect(() => {
      const LoadBranch = async () => {     
        API.get("searchcust").then((response) => {       
          setBranch(response.data);
          console.log(response.data);
        });
      };
      LoadBranch();
    }, [])

    //Customer list******************************************************
  useEffect(() => {
    const LoadCustomers = async () => {     
      API.get("searchcust").then((response) => {       
        setCust(response.data);
        console.log(response.data);
      });
    };
    LoadCustomers();
  }, [])


  

  const TotalSaleall = async () => {    
    API.get("valuetotalall").then((response) => {
      //setTotalSale(response.data);
      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  const TotalSale = async () => {    
    API.get("valuetotal/"+ accname).then((response) => {
      //setTotalSale(response.data);

      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  const TotalSalebranch = async () => {    
    API.get("valuetotalbranch",{
      params:{
        valBranchcode: branchcode,
      }
      
    }).then((response) => {
      //setTotalSale(response.data);

      response.data.map((ItemLabels, index) => {
        setTotalSale(ItemLabels.sale);

      })


    });

  };

  function PrintValue(e) {
    e.preventDefault();
    //additem();
    //CatAdd();
    alert(QuerySelect);
    if(QuerySelect == 'All'){
    ordersViewall();
    TotalSaleall();

    } 
    else 
    if(QuerySelect == 'Branch'){
    BranchValue();
    TotalSalebranch();  
    }
    else
    if(QuerySelect == 'Location'){
    ordersView();
    TotalSale();  
      
    }
    
  
  }

  function PrintStocks(e) {
    e.preventDefault();
    handlePrint();
    
  
  }

  const handlePrint = useReactToPrint({    
    documentTitle: "new documentation",   
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,


  });

  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} style={{ margin: "1000", padding: "0" }}>

        <br></br>
        BUSH TEXTILES
        <br></br>
        P.O BOX MOMBASA
        <br></br>
        





        <Table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </Table>
        Printed By: Admin
        <br></br>
         <label style={{ textAlign: 'right', fontSize: '14px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {TotalAmnt}</label> 
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label>

      </div>


    );
  });

  //Total Sale***********************************************************
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

    //End Reload Table**************************************************************
    //table header
    const renderHeader = () => {
        let headerElement = ['itemcode', 'item', 'cost', 'qtyin', 'qtyout', 'bal','value']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }


     //table header
  const renderHeader1 = () => {
    let headerElement = ['item', 'Price', 'Qty','Value']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '15px',fontWeight: 'bold' }} key={index}>{key.toLowerCase()} </th>
    })
  }



    //table body
    const renderBody = () => {

        return orderslist.map(({ itemcode, itemdesc, cost, qtyin, qtyout, bal,value }) => {
            const TotalAmount = 0;
            return (

                <tr key={itemcode} >
                    <td>{itemcode}</td>
                    <td>{itemdesc}</td>
                    <td>{cost}</td>
                    <td>{qtyin}</td>
                    <td>{qtyout}</td>
                    <td>{bal} </td>
                    <td>{value} </td>

                    {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

                </tr>

            )


        })
    }

    //table body2
  const renderBody1 = () => {

    return orderslist.map(({ itemdesc,cost, bal,value }) => {
      const TotalAmount = 0;
      return (

        <tr key={itemdesc}>
          <td style={{ textAlign: 'left', width: '100%', fontSize: '10px',fontWeight: 'bold' }}>{itemdesc}</td>
          {/* <td style={{ textAlign: 'right', fontSize: '9px', width: '10%' }}>{qty}</td> */}
          {/* <td style={{ textAlign: 'right', fontSize: '9px', width: '10%' }}>{price}</td> */}
          <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{cost} </td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{bal} </td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{value} </td>



        </tr>

      )


    })
  }

    const handleFilter = (event) => {
      const searchWord = event.target.value;
  
      setWordEntered(searchWord);
      console.log(wordEntered)
      console.log(filteredData)
  
      const newFilter = filteredData.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchWord.toLowerCase())
  
      });
  
  
  
      if (searchWord === "") {
       // setFilteredData([]);
      } else {
        //setFilteredData(newFilter);
        setOrders(newFilter);
      }
  
      console.log(newFilter);
  
    };

   

    const setGender = (event) => {
      console.log(event.target.value);
      QuerySelect = event.target.value;
      console.log(QuerySelect);
  
      };
    

    return (

    <Form>

      
    
    <Row className="mb-3">
    <Form.Group as={Col} controlId="accno">
    <h3>Stocks Value</h3>
    <Row className="mb-3">
                <input
                  type="text"
                  placeholder="Search Item"
                  value={wordEntered}
                  onChange={handleFilter}
                />

 
  </Row>
    </Form.Group>
    <Form.Group as={Col} controlId="accno">
      <Form.Label>SALE DATE:</Form.Label>      
      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
    </Form.Group>

    <Form.Group as={Col} controlId="accno">

    <Form.Check inline label="ALL" type="radio" id="radioA" value="All" checked={QuerySelect === "All"} onChange={(e)=>{setQuerySelect(e.target.value)}}/>
    <Form.Check inline label="by Branch" type="radio" id="radioB" value="Branch" checked={QuerySelect === "Branch"} onChange={(e)=>{setQuerySelect(e.target.value)}}/>
    <Form.Check inline label="by Location" type="radio" id="radioB" value="Location" checked={QuerySelect === "Location"} onChange={(e)=>{setQuerySelect(e.target.value)}}/>

    
      
    </Form.Group>

    
    
      <Form.Group as={Col} controlId="accno">
      <Form.Label>Branch:</Form.Label>
      <br></br>
      
            <select onChange={(e) => setBranchcode(e.target.value)}>
              {branch.map(item => {
                return (<option key={item.branchname} value={item.branchcode} >{item.branchname}</option>);
              })}
            </select>
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>Location:</Form.Label>
      <br></br>
      
            <select onChange={(e) => setAccname(e.target.value)}>
              {custname.map(item => {
                return (<option key={item.accname} value={item.accno} >{item.accname}</option>);
              })}
            </select>
    </Form.Group>

    <Form.Group as={Col} controlId="accname">
        <br></br>
            <Button variant="primary" type="submit" onClick={
              PrintValue}>
              PREVIEW STOCKS
            </Button>
    </Form.Group>   

    </Row>

    <div
              style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>


        <div ref={componentRef}>
            <div className="scrolling-wrappervalue">


                <Table striped bordered hover size="sm" id='employee'>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>


            </div>
        </div>

        <Form>
        <Row className="form-row">

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              PrintStocks}>

              PRINT STOCKS
            </Button>
          </div>

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit">

              Clear All
            </Button>
          </div>


          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              PrintValue}>

              Re-Print
            </Button>
          </div>

          <div className="form-group col-md-2">

            <Button variant="primary" type="submit" onClick={
              PrintValue}>

              EXIT
            </Button>
          </div>
          <div className="form-group col-md-3">
            <Form.Group as={Col} controlId="TotalSaleAmnt">
               <Form.Control type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} /> 
            </Form.Group>
          </div>



        </Row>
      </Form>

      <ExportToExcel apiData={orderslist} fileName={fileName} />

      {/* <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={componentRef.current}
                >

                   <Button> Export excel </Button>

                </DownloadTableExcel> */}
      

        </Form>

        
    )
}

export default Stockvalue
import React from 'react'
import { Navbar, Nav, Container,NavDropdown } from 'react-bootstrap';

function NavAccess() {
  return (
    <>
      <Navbar variant="dark" bg="dark" expand="lg">
  <Container fluid>
    <Navbar.Brand href='/Login'>SOFTCRAFT</Navbar.Brand>      
    <Navbar.Brand href='/Login'>Login</Navbar.Brand>      
  </Container>
</Navbar>
    </>
  )
}

export default NavAccess
import React, { Component, useState,useRef  } from 'react'
import './Madre.css';
import API from './Softcraftapi';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import {useNavigate } from "react-router-dom";
const current = new Date();
const datenew = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;

export default class Reprint extends Component {
    constructor(props) {
        super(props);
        
    
        this.state = {
          // The active selection's index
          activeSuggestion: 0,
          // The suggestions that match the user's input
          filteredSuggestions: [],
          // Whether or not the suggestion list is shown
          showSuggestions: false,
          // What the user has entered
          userInput: "",
          muriga: "",
          docno: "",
          loccode: "",          
          setCust:[],
         
         
        };
    
       /*  this.handlePageClick = this
          .handlePageClick
          .bind(this);
    
          this.butClick = this
          .butClick
          .bind(this);
    
          this.handleChange = this.handleChange.bind(this);
          this.handleprice = this.handleprice.bind(this);
          this.handlepriceretail = this.handlepriceretail.bind(this);
          this.handlepricesuperstockist = this.handlepricesuperstockist.bind(this);
          this.handlepricestockist = this.handlepricestockist.bind(this) */
      }
    
  render() {
    const {
        onChange,
        onKeyDown,
        receivedData,
        onClick,     
  
        
        
        
        
  
        state: {
          startDate,
          endDate,
       
        
        
        
  
        }
  
      } = this;

    return (
      <>
      <FunctionalDate/>
      
      </>
    )
  }
}

const FunctionalDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orderslist, setOrders] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const componentRef = useRef();
  const navigate = useNavigate();

  const viewSales = async () => {
    
    API.get("salesreprint",{
      params:{                
        shanzustartdate: startDate,
        shanzuenddate: endDate,                
      }
    }).then((response) => {
              setOrders(response.data);
        
        console.log(response.data);
    });
};

   //table header
   const renderHeader = () => {
    let headerElement = ['id','date', 'receipt', 'customer',  'Amount']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  //table body
  const renderBody = () => {

    return orderslist.map(({ id,dated, docno, customer, value }) => {
      const TotalAmount = 0;
      return (

        <tr key={id} onClick={() => handleClick(docno,customer,value,dated)}>
          <td>{id} </td> 
          {/* onClick={() => handleClick(id,itemname)}          */}
          <td>{dated}</td>
          <td>{docno}</td>
          <td>{customer}</td>
          <td>{value}</td>          

          {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

        </tr>

      )


    })
  }

   //PRINT RECEIPT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
   
   const ComponentToPrint = React.forwardRef((props, ref) => {
    //console.log("Printing");
    //setChange(Math.round({tenderamount} - {TotalAmnt}))
   // setChange(tenderamount - TotalAmnt)

   //console.log(Change)
   
    return (
      
      //<div ref={ref} style={{ margin: "00", padding: "0" }}>
      //<div ref={ref} style={{getPageMargins}}>
      <div ref={ref} >

        <label style={{ textAlign: 'centre', fontSize: '14px', fontFamily: 'Arail', width: '100%',fontWeight: 'bold' }}>MBIKA  AGENCIES </label>
        <label style={{ textAlign: 'centre', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>KERUGOYA </label>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%',fontWeight: 'bold' }}>***RE-PRINT COPY*** </label>
        <label style={{ textAlign: 'right', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>Sale Date:{JSON.parse(localStorage.getItem('dated'))};</label>
        {/* Customer: {Customername}; */}
        <label style={{ textAlign: 'left', fontSize: '10px', fontFamily: 'Arail' }}>{JSON.parse(localStorage.getItem('clientname'))}</label>
        <label style={{ textAlign: 'right', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}> {JSON.parse(localStorage.getItem('receiptno'))} </label>
        {/* <label style={{ textAlign: 'left', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}> {JSON.parse(localStorage.getItem('receiptno'))}</label> */}

        <table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </table>






        
       
        {/* ({TotalAmnt}) */}
        {/* localStorage.setItem('newtotalsale', JSON.stringify("Total Sale:" + setTotalSale.map((person)=>(person.sale)))); */}
        <label style={{ textAlign: 'right', fontSize: '12px', fontFamily: 'Arail', width: '100%',fontWeight: 'bold' }}> {JSON.parse(localStorage.getItem('newtotalsale'))}</label>
        
        
        <label style={{ textAlign: 'left', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>Served By: {JSON.parse(localStorage.getItem('items'))}</label>
         
        
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>Powered by:SOFTCRAFT {'\u00A9'} 2022</label>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%',fontWeight: 'bold' }}>***RE-PRINT COPY*** </label>

      </div>


    );
    
  });

    //table header
const renderHeader1 = () => {
  let headerElement = ['item', 'qty', 'price', 'total']

  return headerElement.map((key, index) => {
    return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toUpperCase ()} </th>
  })
}


//table body2
const renderBody1 = () => {
  

  return receipt.map(({ id,itemname, price, qty, amount }) => {
    const TotalAmount = 0;
    return (

      <tr key={id}>
        <td style={{ textAlign: 'left', width: '100%', fontSize: '9px',fontWeight: 'bold' }}>{itemname}</td>
        <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{qty}</td>
        <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{price}</td>
        <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{price * qty} </td>



      </tr>

    )
   

  })
  
}

 

  //END RECEIPT PRINT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  const handlePrint = useReactToPrint({
     /* documentTitle: "new documentation",
      marginTop:"100px",
     marginRight:"100px",
   marginBottom:"10px",
   marginLeft:"5px",   */
    //pageStyle:"@page { size: 80mm 80mm}",



   /*  marginTop:"100px",
    marginBottom:"10px",
    pageStyle:"@page {auto; margin: 10mm 0 10mm 0}", */
  
 /*  body {
      margin:0;
      padding:0;
  } */

   // pageStyle:"@page { size: 2.5in 4in }",
   pageStyle:"@page { size: 3.5in 4in,margin: 10mm 0 10mm 0 }",
    //pageStyle: "@page { size: 80mm 1mm }",
    //pageStyle:"@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }",
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,
    


    

    //pagestyle: "print",
    // onAfterPrint={() => { console.log("Document Printed") }},
  });

  const handleClick = async (docno,customer,value,dated) => {  
    localStorage.setItem('receiptno', JSON.stringify("Receipt No:" + docno));
    localStorage.setItem('clientname', JSON.stringify("Customer: "  + customer));
    localStorage.setItem('newtotalsale', JSON.stringify("Total Sale:" + value));
    localStorage.setItem('dated', JSON.stringify( dated));
    
    const receiptPrint = async () => {      
      API.get("reprintreceipt",{
        params:{                
          shanzustartdate: docno,
                          
        }
      }).then((response) => {
          setReceipt(response.data);
          console.log(response.data);
          
      });
  };
  receiptPrint();
  alert("Printing...........")
  await delay(5000);


   


    
    handlePrint();
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  return(

    <>
    <Form>
      <Row className="mb-3">

      <Form.Group as={Col} controlId="accno">
      <Form.Label>FROM DATE:</Form.Label>
       {/* <DatePicker   />       */}
     <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> 
      {/* //onChange={(date) => setStartDate(date)} */}
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>TO DATE:</Form.Label>      
       <DatePicker selected= {endDate} onChange={(date) => setEndDate(date)} /> 
      {/* onChange={(date) => setEndDate(date)} */}
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>PREVIEW:</Form.Label>      
       <Button  onClick={viewSales} >SALES</Button>
    </Form.Group>

    <Form.Group as={Col} controlId="accno">
      <Form.Label>PREVIEW:</Form.Label>      
       <Button   onClick ={() => {
        navigate("/Solace")
      }} >BACK</Button>
    </Form.Group>
    </Row>
    <Row className="mb-3">

    <div >
            {/* <div className="scrolling-wrapper2"> */}
              <Table striped bordered hover size="sm" id='employee1'>
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>
                  {renderBody()}
                </tbody>
              </Table>
            {/* </div> */}
            </div>  
    </Row>

   

      </Form>
      <div
 
               style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>
    
    </>
  )

}

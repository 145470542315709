import React, { useState,useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";
import API from './Softcraftapi';
import {useNavigate } from "react-router-dom";
import CapUser from "./CapUser";
import  Home  from "./Home";
import NavBar from "../NavBar";
import  Ace  from "./Ace";

export default function Login() {
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  var useremail="";


  useEffect(() => {
    const GetMachine = async () => {      
      API.get("machinename").then((response) => {       
        //setCapUser(response.data);  
        console.log(response.data);
      });


    };
    GetMachine();


  }, [])

  
    
     
           
     
      

  
  //88888888888888888888888888888888888888888888888888888888888888888888888
  const Login =async() => {  
    console.log(username);
    //+ username , password  
      API.get("user",{
        params:{
          username:username,
          password: password,


        }
      }).then((res) => {        
        if (res.data.message) {
          setLoginStatus(res.data.message);
        } else {
          setLoginStatus(res.data[0].name);          
          localStorage.setItem('items', JSON.stringify( res.data[0].name));
          localStorage.setItem('branch', JSON.stringify( res.data[0].loccode));
          localStorage.setItem('location', JSON.stringify( res.data[0].location));
          // navigate("/solace");
          navigate("/Home");
        }
        
      }); 
      
      /* alert("Mail  " + password)
      alert("Name  " + username) 

      if (useremail === username) {
       
        
        alert("ok")
      navigate("/solace"); 
      }
      else
      {
        alert('useCount must be used within a CountProvider')

      } */
      
      
    
      
      
     
      
    }; 

  //88888888888888888888888888888888888888888888888888888888888888888888888

  // function validateForm() {
  //   return username.length > 0 && password.length > 0;
  // }

  function handleSubmit(event) {
    event.preventDefault();
    //setUser(username)
    //alert(user)
    Login();
  }

  // const handleChange = (event) => {
  //   setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  // };

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoFocus
            name="username"
            type="text"            
            onChange={(e) => setUsername(e.target.value)} value={username} required
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            onChange={(e) => setPassword(e.target.value)} value={password} required
          />
        </Form.Group>
        <Form.Group size="lg" mb="3" controlId="password">
        <Button block="true" size="lg" type="submit" onClick={Login} >
          Login
        </Button>
        </Form.Group>
        <h2>{loginStatus}</h2>
      </Form>
    </div>
  );
}
import React, { Component, Fragment,useState, useEffect,useContext, useRef } from "react";
import PropTypes from "prop-types";
import  './Madre.css';
import API from './Softcraftapi';
import axios from 'axios';
import { UserContext } from './UserContext';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";
import ReactToPrint from "react-to-print";
//import { response } from "../../../../pos/app";
const current = new Date();
const datenew = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;



//function Apex() { 
  function Apex  (props)  {
    const {user,setUser}= useContext(UserContext); 

   
    
  
  const fetchPost = async () => {    
    API.get("searchitem").then((response) => {      
      //setPosts(response.data);    
      console.log(response.data);     
      //console.log({userInput}); 
      
      //const response = await fetch(url)
    //const data = await response.json()
    //this.setState({people: response.data})
    });
  };
  fetchPost();
  
 
  alert("Supreme")
}


class Autocomplete extends Component {Apex
  static contextType = UserContext
  
  
  
  
  
  
  
    
 /*  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  }; */

  /* static defaultProps = {
    suggestions: []
  }; */

  constructor(props) {
    super(props);
    this.exampeRef = React.createRef();
    this.componentRef = React.createRef();
   // const NewPriceLevel ="WHOLESALE";

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      muriga:"",
      price:"",
      sprice1:"",
      sprice2:"",
      sprice3:"",
      sprice4:"",
      docno:"",      
      loccode:"2001",
      pricecode:"",
      pricelevel:"",
      NewPriceLevel:"WHOLESALE",
      suggestions: null,
      docinfo:[],
      people: [],
      posts: [],
      priceinfo: [],
      setCust:[],
      orderslist:[],
    loading: false,
    value: '',
    
    };
  }

  /* async componentDidMount(){
    const url ="http://localhost:3000/searchitem" //"https://mushroom.azizi.co.ke/searchitem"
    const response = await fetch(url)
    const data = await response.json()
    this.setState({people: data.results, loading: false})


    
  } */
   
  async componentDidMount(){   
   
    //const user = this.context    
    //console.log(user)
    const loccode ="2001"; 
    //const docno ="100036";      
      
        API.get("findsoftdoc",{
          params: {
            softsearch: loccode,
          },
  
        }).then((response) => {
          
          const docinfo = response.data;
        this.setState({ docinfo });        
        console.log(response.data);
        this.setState({
          docno: (docinfo.map((person)=>(person.newdocno))),
          loccode: (docinfo.map((person)=>(person.loccode)))


          
        })
        
                
                
  
        });

        


  //Price Levels ********************************888888888888888888888888888888888************
  API.get("searchlevel").then((response) => {
    
    const priceinfo = response.data;
    this.setState({ priceinfo });        
    console.log(response.data);
    this.setState({
      pricecode: (priceinfo.map((person)=>(person.levelcode))),
      pricelevel: (priceinfo.map((person)=>(person.levelname)))
    }) 
     });   
  //End levels ************************************8888888888888888888888888888888************

  //customer name 33333333333333333333333333333333333333333333333333333333333333333333333

 
        
      API.get("searchcust").then((response) => {
        const setCust = response.data;
        this.setState({setCust});
        console.log(response.data);        
        
      });

   
   


  
  //3333333333333333333333333333333333333333333333333333333333333333333333333333333333333
  
  
  
     
     
  
  
   
  
  }

  add = () => {
    const {user,setUser}= useContext(UserContext); 
    /* this.setState({
      userInput: "2022"
    }) */
  }

  add1 = () => {
    const [posts, setPosts] = useState([]);
    setPosts("Kerugoya")  
    alert("posts")
    this.setState({
      userInput: "2029"
    })
  }

  /* add = () => {
    const url = "https://api.randomuser.me/?results=10"
    const response =  fetch(url)
    const data =  response.json()
    this.setState({people: data.results, loading: false})
  } */
  //onChangelevel

  onChangelevel = e => {  
   //const NewPriceLevel= e.currentTarget.value;
   
  
    
    const newLevel= e.currentTarget.value;
    //alert(newLevel)
    //NewPriceLevel:"101"


    if(e.currentTarget.value === 'RETAIL'){
      alert("RETAIL")
      

     
    }
    else
    if(e.currentTarget.value === 'WHOLESALE'){
      alert("WHOLESALE")

    }
    else
    if(e.currentTarget.value === 'STOCKIST'){
      alert("STOCKIST")

    }
    else
    if(e.currentTarget.value === 'SUPERSTOCKIST'){
      alert("SUPERSTOCKIST")
       /* this.setState({      
      price: sprice1
    });  */

    }

    

    

    /* this.setState({      
      newQty: e.currentTarget.value
    }); */
  };

  onChangeqty = e => {  
    
    const newQty= e.currentTarget.value;

    this.setState({      
      newQty: e.currentTarget.value
    });
  };

  onChangeprice = e => {  
    
    const price= e.currentTarget.value;
   

    this.setState({  
         
      price: e.currentTarget.value
    });
  };
 
  

  onChange = e => {
    const { suggestions } = this.props;
    
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick1(){  
    this.exampleRef.current.focus();
 
   }

  onClick = e => {
    //alert(NewPriceLevel);
   
    /* if (NewPriceLevel==="1234"){
      alert("me")

    } */
    API.get("itemdetails/",{
      params:{    
         selecteditem: e.currentTarget.innerText
    
      },
    } ).then((response) => {  
      const posts = response.data;
      this.setState({ posts });        
      console.log(response.data);
      this.setState({
        muriga: (posts.map((person)=>(person.itemcode))),
        price: (posts.map((person)=>(person.wholesale))),
        sprice1: (posts.map((person)=>(person.superstockist))),
        sprice2: (posts.map((person)=>(person.stockist))),
        sprice3: (posts.map((person)=>(person.retail))),
        sprice4: (posts.map((person)=>(person.retail)))
      }) 
       

    });
    
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    this.refInput.focus();

    //handleClick();
  };

  onKeyDownSubmit = e => {
    //handleSubmit()

    if (e.keyCode === 13) {

      
      
    
    }

  }

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    //const {user,setUser}= useContext(UserContext);
    //const newUser = userInput

           // setUser(newUser)
    

    // User pressed the enter key
    if (e.keyCode === 13) {
      //const newUser = filteredSuggestions[activeSuggestion]

            
      
      API.get("itemdetails/",{
        params:{    
           selecteditem: filteredSuggestions[activeSuggestion]
      
        },
      } ).then((response) => {  
        const posts = response.data;
        this.setState({ posts });        
        console.log(response.data);
        
        this.setState({
          muriga: (posts.map((person)=>(person.itemcode))),
          price: (posts.map((person)=>(person.wholesale))),
          sprice1: (posts.map((person)=>(person.superstockist))),
          sprice2: (posts.map((person)=>(person.stockist))),
          sprice3: (posts.map((person)=>(person.retail))),
          sprice4: (posts.map((person)=>(person.retail)))
        }) 
         
  
      });
      //alert(filteredSuggestions[activeSuggestion])
      this.setState({
       // activeSuggestion: 2,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
       // setUser: filteredSuggestions[activeSuggestion],
      });
      this.refInput.focus();
      //txtqty.focus();
      
      //this.txtqty.current.focus();


    
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  
  
  

  render() {
    //const componentRef = useRef();
    //{renderBody()}
   // {loaditems()}
   //const componentRef = useRef();
  const loaditems =()=>{
    API.get("captain/",{
      params:{
        shanzuloccode:loccode,
        shanzudocno: docno,
       

      },
    }).then((response) => {
      const orderslist = response.data;
      this.setState({orderslist})
       

    });

  }
    

    const handleDelete = (id,itemname)=>{
      
       API.delete("capdelete/",{
        params:{
          shanzuid:id,
          
  
        },
  
       }, {
      }).then(() => {
            
      }); 
      alert(itemname +"  Removed from Cart!!")    
      
      API.get("captain/",{
        params:{
          shanzuloccode:loccode,
          shanzudocno: docno,          
  
        },
      }).then((response) => {
        const orderslist = response.data;
        this.setState({orderslist})           
  
      });
      
      API.get('totalsale/',{
        
        params:{
          shanzuloccode:loccode,
          shanzudocno:docno,    
        },
      }).then((response) => {
        const  setTotalSale = response.data;
        this.setState({setTotalSale})  
        
        this.setState({
          TotalAmnt: (setTotalSale.map((person)=>(person.sale))),
          
        }) 
  
  
      });
  

      {renderBody()}
  
     
    }
    //delete all from cart
  const handleDeleteall = ()=>{
       
     API.delete("capdeleteall/",{
      params:{
        shanzuid:docno,
        

      },

     }, {
    }).then(() => {
           
    });
    alert("Clear all from Cart!!  "+ docno) 
    
    
    API.get("captain/",{
      params:{
        shanzuloccode:loccode,
        shanzudocno: docno,          

      },
    }).then((response) => {
      const orderslist = response.data;
      this.setState({orderslist})           

    });
    
    API.get('totalsale/',{
      
      params:{
        shanzuloccode:loccode,
        shanzudocno:docno,    
      },
    }).then((response) => {
      const  setTotalSale = response.data;
      this.setState({setTotalSale})  
      
      this.setState({
        TotalAmnt: (setTotalSale.map((person)=>(person.sale))),
        
      }) 


    });


    {renderBody()}

    

   
  }

    //table header
  const renderHeader = () => {
    let headerElement = ['id','itemcode', 'itemname', 'price', 'qty', 'Amount']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  //table header
  const renderHeader1 = () => {
    let headerElement = ['item', 'qty', 'price', 'total']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toUpperCase ()} </th>
    })
  }

  //table body
  const renderBody = () => {

    return orderslist.map(({ id,itemcode, itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={id}>
          <td  onClick={() => handleDelete(id,itemname)}>{id} </td>          
          <td>{itemcode}</td>
          <td>{itemname}</td>
          <td>{price}</td>
          <td>{qty}</td>
          <td>{price * qty} </td>

          {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

        </tr>

      )


    })
  }

  //table body2
  const renderBody1 = () => {

    return orderslist.map(({ itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={itemname}>
          <td style={{ textAlign: 'left', width: '100%', fontSize: '9px',fontWeight: 'bold' }}>{itemname}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{qty}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '10%',fontWeight: 'bold' }}>{price}</td>
          <td style={{ textAlign: 'right', fontSize: '10px', width: '20%',fontWeight: 'bold' }}>{price * qty} </td>



        </tr>

      )
     

    })
    
  }

   //PRINT RECEIPT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
   const ComponentToPrint = React.forwardRef((props, ref) => {
    //GetSoftdoc();
    return (
      <div ref={ref} style={{ margin: "1000", padding: "0" }}>

        <br></br>
        MBIKA AGENCIES
        <br></br>
        KERUGOYA
        <br></br>
        Sale Date:{datenew};
        <br></br>
        {/* Customer: {Customername}; */}





        <Table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </Table>
        Served By: Cashier1
       {/*  ({TotalAmnt})
        <br></br>
        <label style={{ textAlign: 'right', fontSize: '14px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {SaleTotal1.TotalsaleN}</label>
       */}  
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label>

      </div>


    );
    
  });
 

  //END RECEIPT PRINT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
 /*  const handlePrint = useReactToPrint({
    documentTitle: "new documentation",
   
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,

  }); */

  
   // const ref = useRef(null);
    
    
    //var muriga ="";
    const addnew = event => {
     // event.Default();
      //const {user,setUser}= useContext(UserContext); 
      /*  this.setState({
        userInput: "2022"
      })  */
      

      const newUser = {userInput}

            setUser(newUser)

        this.setState({
        //userInput: ""
      }) 
      
     // const Itemdetails = async () => {    
        API.get("itemdetails/",{
          params:{    
             selecteditem: userInput
        
          },
        } ).then((response) => {  
          //const posts = response.data;
          this.setState({ posts });        
          console.log(response.data);
          this.setState({
            muriga: (posts.map((person)=>(person.itemcode))),
            price: (posts.map((person)=>(person.retail)))
          }) 
         // muriga  = (posts.map((person)=>(person.itemname)))  
         // alert(muriga)    
         // alert(muriga)    
    
        });
        
      //};
     // window.location.reload(false);

     
    }

    const handleClick = () => {
      ref.current.focus();
    };

  //handele 888888888888888888888888888888888888888888888888
  const  handleSubmit1 = event => {
    
      //event.preventDefault();
      if (event.keyCode === 13) {
       
        this.refInputprice.focus();
        

    
      }
    
   
    
     }
    
  //8888888888888888888888888888888888888888888888888888888

  const  handleSubmit = event => {
    const current = new Date();
  //const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
      //event.preventDefault();
      if (event.keyCode === 13) {
       
        API.post("create", {
          item: userInput,
          icode: muriga,
          price: price,
          qty: newQty,
         docno: docno,
         loccode: loccode,
          unitcost:"200",
          dated: `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`,
          customer: "accname1", 
          
        }).then(() => {
          console.log("Success");
          
    
        });
        //End Post >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  
        alert(docno);      
        API.get("captain/",{
          params:{
            shanzuloccode:loccode,
            shanzudocno: docno,          
    
          },
        }).then((response) => {
          const orderslist = response.data;
          this.setState({orderslist})           
    
        });

        API.get('totalsale/',{
          params:{
            shanzuloccode:loccode,
            shanzudocno:docno,    
          },
        }).then((response) => {
          const  setTotalSale = response.data;
          this.setState({setTotalSale})  
          
          this.setState({
            TotalAmnt: (setTotalSale.map((person)=>(person.sale))),
            
          }) 
    
    
        });
    

        {renderBody()}
        // End search
        this.setState({
          muriga: "",
          price: "",      
          newQty:"",
          userInput:"",
        })
        this.refInputitem.focus(); 
       // window.location.reload();
       //this.textInput.focus();

    
      }
    
   
    //event.Default();
     }
    
    const user = {
      firstName: 'Mbika',
      lastName: 'Perez'
      
  };
  function test(user) {
    

    return user.firstName;
}
    const {
      onChange,
      onClick,
      onClick1,
      onKeyDown,
      onChangeqty,
      onChangeprice,
      onKeyDownSubmit,
      onChangelevel,
         
      
      

            
      
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        muriga,
        price,
        sprice1,
      sprice2,
      sprice3,
      sprice4,        
        newQty="",
        docno,
        loccode,
        pricecode,
        pricelevel,
        priceinfo,
        NewPriceLevel,
        ref,
        setCust,
        orderslist,
        TotalAmnt,
        posts,
        componentRef,
        
       
        

      }
    } = this;
    

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No Item, please check spelling!</em>
          </div>
        );
      }
    }
    
    const { user1, setUser } = this.context

    const butstyle3 = {
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '110px',
      fontSize: '14px',
      height: 50,
  
    };
  
    const labelstyle3 = {
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '200px',
      fontSize: '20px',
      height: 50,
     // backgroundColor: '#8d8d8d',
      backgroundColor: '#eff2f7',
      // #8d8d8d
     
    };

    

    function SoftcraftSave(e) {
      //let componentRef = useRef(); 
      e.preventDefault();
      //Example();
      //alert('Receipt Completed');     
    // CompSale();
     // ParaAdd();
     //handlePrint();
     // GetSoftdoc();
      //window.location.reload();    
     // CartRefresh();
     // window.location.reload(false);
      
  
  
      // OrdersRefresh();
    }

    function SoftcraftRefresh(e) {
     // e.preventDefault();
     
     
      window.location.reload(false);
      alert(docno)

      API.get("captain/",{
        params:{
          shanzuloccode:loccode,
          shanzudocno: docno,          
  
        },
      }).then((response) => {
        const orderslist = response.data;
        console.log(response.data)
        this.setState({orderslist})           
  
      });
      
      API.get('totalsale/',{
        
        params:{
          shanzuloccode:loccode,
          shanzudocno:docno,    
        },
      }).then((response) => {
        const  setTotalSale = response.data;
        this.setState({setTotalSale})  
        
        this.setState({
          TotalAmnt: (setTotalSale.map((person)=>(person.sale))),
          
        }) 
  
  
      });
  

      {renderBody()}
      
  
  
      // OrdersRefresh();
    }

    /* //Total Qty************************************************************
  useEffect(() => {
    const TotalItems = async () => {
      API.get("totalitems").then((res) => {
        setTotalItems(res.data);

        res.data.map((ItemLabels, index) => {
          setTotalItems({ TotalSaleQty: ItemLabels.qty });
          //setContactInfo({ name: "", email: "", phonenumber: "" });


        })


      });

    };
    TotalItems();
  }, []) */


    //888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888
    const Example = () => {
      // <ComponentToPrint ref={componentRef} />
      /* const componentRef = useRef();
      return (
        <div>
          <ReactToPrint
            
            content={() => componentRef.current}
          />
          <ComponentToPrint ref={componentRef} />
        </div>
      ); */
    };
        
    
        //888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888
    

    
    

    return (
      // <Fragment>
      <>
       
       

        

    <Row style={{
        backgroundColor: '#8ad0d3',
      }} className="justify-content-md-left">
    
    {/* <Col xs={8}> */}

    

    <Row className="mb-3">
    {/*  <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
        />  */}
    {/* <Form.Group as={Col} controlId="itemcode">
      <Form.Label>Code:</Form.Label>      
      <Form.Control  type="text" onChange={onChange} onKeyDown={onKeyDown} value={userInput} required/>      
    </Form.Group> */}
    <Col xs={4}>
    <Form.Label>Item:</Form.Label>      
      <Form.Control ref={(input) => { this.refInputitem = input; }} autoFocus type="text"  onChange={onChange} onKeyDown={onKeyDown} value={userInput} required/>      
    </Col>

    

    <Form.Group as={Col} controlId="pricelist">
      <Form.Label>Price Level:</Form.Label>      

                        <select style={{ width: 100, marginLeft: 10, marginTop: 10 }} onClick={onChangelevel}>                        
                            {priceinfo.map(item => {
                                return (<option key={item.levelname} onChange={onChangelevel}   >{item.levelname}</option>);
                            })}
                        </select>               
    </Form.Group>

    <Form.Group as={Col} controlId="txtqty">
      <Form.Label>Qty:</Form.Label>      
      <Form.Control ref={(input) => { this.refInput = input; }} type="text" name="txtqty"  placeholder="" onKeyDown={handleSubmit1} onChange={onChangeqty} value={newQty} required/>      
    </Form.Group>

    <Form.Group as={Col} controlId="itemcode">
      <Form.Label>Price:</Form.Label>      
      <Form.Control ref={(input) => { this.refInputprice = input; }} type="text" name="itemcode"  placeholder="Code:" onKeyDown={handleSubmit} onChange={onChangeprice} value={price} required/>      
    </Form.Group>

    <Form.Group as={Col} controlId="pricelevel">
      <Form.Label>Customer:</Form.Label>
      

                        <select >                        
                            {setCust.map(item => {
                                return (<option key={item.accname} value={item.accno}  >{item.accname}</option>);
                            })}
                        </select>               
    </Form.Group>

    <Form.Group as={Col} controlId="totalbalance">
                <Form.Label>Balance:</Form.Label>
                  <Form.Control ref={this.exampeRef} type="text" name="totalbalance" placeholder="00.00"  required />
                </Form.Group>

    
                {/* <button onClick={this.onClick1}>   */}
                <button >  
        SOFTCRAFT POINT OF SALE  
     </button> 
    

    </Row>

    {/* </Col> */}
    
    </Row>

    {suggestionsListComponent}

    <Row style={{
        backgroundColor: '#8ad0d3',
      }} className="justify-content-md-center">

    <div >
            <div className="scrolling-wrapper2">
              <Table striped bordered hover size="sm" id='employee'>
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>
                  {renderBody()}
                </tbody>
              </Table>
            </div>
          </div>
    
    </Row>

    <Row className="mb-2">
                 <Button style={butstyle3} value="newprint" onClick={handleDeleteall}> CLEAR ALL</Button>                
                
                <Button style={butstyle3} value="newprint" onClick={SoftcraftSave}> PAYMENT</Button>
                
                {/* <Button style={butstyle3} value="newprint" onClick={SoftcraftSave}> PRINT</Button> */}
                <Button style={butstyle3} value="newprint" onClick={SoftcraftRefresh}> REFRESH</Button>
                <Button style={butstyle3} value="newprint" > HOLD</Button>
                <Button style={butstyle3} value="newprint" > UNHOLD</Button>
                

            <div
 
              style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
            {/* <ComponentToPrint ref={(el) => (this.componentRef = el)}/> */}
               <ComponentToPrint ref={componentRef} /> 
              {/* ref={(input) => { this.refInputitem = input; }} */}
               {/* <ComponentToPrint ref={(input) => { this.componentRef = input; }} />  */}
               {/* ref={(input) => { this.refInputitem = input; }} */}

            </div>

                

            <Form.Group as={Col} controlId="totalqty">
                  <Form.Label>QTY:</Form.Label>
                  {/* value={TotalQty.TotalSaleQty} */}
                  <Form.Control style ={labelstyle3} type="text" name="totalqty" placeholder="QTY"  required />
                </Form.Group>


                <Form.Group as={Col} controlId="TotalSaleAmnt">
                  <Form.Label>SALE:</Form.Label>
                  <Form.Control style ={labelstyle3} type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} />
                </Form.Group>


    

    </Row>     
   

{/* <button onClick={addnew}> Add </button> */}

{/* <button onClick={handleSubmit} > Submit </button> */}
</>

      // </Fragment>

      
    );
  }
  




}

export default Autocomplete;


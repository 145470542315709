import React from 'react'
import {useNavigate} from "react-router-dom";

function Home({authorized}) {
  const navigate = useNavigate();
  if(authorized){
    // return <Redirect to ="/CapUser"/>;
    navigate("/CapUser");
         window.location.reload();
  }
  return (
    <div>Home</div>
  )
}

export default Home

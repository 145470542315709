import React, { Component }  from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";

  // Create styles
  const styles = StyleSheet.create({
    page: {
      //backgroundColor: "#d11fb6",
      color: "black",
      fontSize: 11,
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
      
    },
  });
  
  // Create Document Component
  function FirstKings() {
    return (
       <PDFViewer style={styles.viewer}>
       {/* <PDFViewer width="1000" height="300"> */}
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>Hello</Text>
            </View>
            <View style={styles.section}>
              <Text>Welcome to softcraft Technologies</Text>
              <Text>Welcome to softcraft Technologies</Text>
              <Text>Welcome to softcraft Technologies</Text>
              <Text>Welcome to softcraft Technologies</Text>
              <Text>Welcome to softcraft Technologies</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
  export default FirstKings;  
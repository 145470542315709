import React from 'react'
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'

function Orders() {
  return (
    <>
<Container>
  <Row className="justify-content-md-center">
    <Col xs lg ="2">
      <Button>kkkk</Button>
      <Button>kkkk</Button>
      <Button>kkkk</Button>
      <Button>kkkk</Button>
      <Button>kkkk</Button>
      <Button>kkkk</Button>
      <Button>kkkk</Button>
    </Col>
    
    <Col xs lg="2">
      3 of 3
    </Col>
  </Row>
  <Row>
    <Col>1 of 3</Col>
    <Col md="auto">Variable width content</Col>
    <Col xs lg="2">
      3 of 3
    </Col>
  </Row>
</Container>
    </>
  )
}

export default Orders
import React, { Component, useState,useRef  } from 'react'
import './Madre.css';
import API from './Softcraftapi';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
//import {ReactToPrint} from 'react-to-print';
import ReactToPrint from 'react-to-print';
//import  {useReactToPrint}  from "react-to-print";
import { useReactToPrint } from "react-to-print";
import {useNavigate } from "react-router-dom";
const current = new Date();
const datenew = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;




export default class Ace extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      muriga: "",
      docno: "",
      loccode: "",
      docinfo: [],
      priceinfo: [],
      posts: [],
      itemposts:[],
      orderslist: [],
      offset: 0,
      data: [],
      orderslist:[],
      suggestions: null,
      setCust:[],
      Newqty:"1",
      perPage: 15,
      currentPage: 0,
      itemname:"",
      itemcode:"",
      price:"",
      sprice1:0,
      qty:"",
      accname:"",
      newvalue: 'coconut',
      custno:'101',
      wholesale:false,
      retail:false,
      stockist:false,
      superstockist:false
    };

    this.handlePageClick = this
      .handlePageClick
      .bind(this);

      this.butClick = this
      .butClick
      .bind(this);

      this.handleChange = this.handleChange.bind(this);
      this.handleprice = this.handleprice.bind(this);
      this.handlepriceretail = this.handlepriceretail.bind(this);
      this.handlepricesuperstockist = this.handlepricesuperstockist.bind(this);
      this.handlepricestockist = this.handlepricestockist.bind(this)
      
  }
  

  receivedData() {
    const userInput = "BEER";
    API.get("allitems/", {
      params: {
        selecteditem: userInput
      },
    }).then((res) => {

      const data = res.data;
      console.log(res.data);
      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map(pd => <React.Fragment>
        <Button 
        style={{
          float: 'right',
          marginRight: 10,
          marginBottom: 6,
          width: '80px',
          height: '60px',
          fontSize: '10px'
        }}onClick={this.butClick}>{pd.itemname}</Button>
        {/* <img src={pd.itemname} alt="" /> */}
      </React.Fragment>)

      this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),

        postData,
       
      
      })

      


    });
  }

  handleprice=(e)=>{
    alert(e.currentTarget.value)
    //e.currentTarget.che = '#ffd700';
    
    this.setState({
      wholesale: true,
      retail:false,
      stockist:false,
      superstockist:false,
      
    })
    
  }

  handlepriceretail=(e)=>{
    alert(e.currentTarget.value)
    this.setState({
      wholesale: false,
      retail:true,
      stockist:false,
      superstockist:false,
      
    })
    
  }

  handlepricestockist=(e)=>{
    alert(e.currentTarget.value)
    this.setState({
      wholesale: false,
      retail:false,
      stockist:true,
      superstockist:false,
      
    })
    
  }

  handlepricesuperstockist=(e)=>{
    alert(e.currentTarget.value)
    this.setState({
      wholesale: false,
      retail:false,
      stockist:false,
      superstockist:true,
      
    })
    
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.receivedData()
    });

  };

  async componentDidMount() {
    this.receivedData()
    const loccode = JSON.parse(localStorage.getItem('branch'));
    alert(JSON.parse(localStorage.getItem('location')))
    //const docno ="100036";      

    API.get("findsoftdoc", {
      params: {
        softsearch: loccode,
      },

    }).then((response) => {

      const docinfo = response.data;
      this.setState({ docinfo });
      console.log(response.data);
      this.setState({
        docno: (docinfo.map((person) => (person.newdocno))),
        loccode: (docinfo.map((person) => (person.loccode)))
      })
      localStorage.setItem('receiptno', JSON.stringify("Receipt No:" + docinfo.map((person) => (person.newdocno))));
      localStorage.setItem('receiptdoc', JSON.stringify( docinfo.map((person) => (person.newdocno))));

    });


    API.get("searchdepart").then((response) => {
      const posts = response.data;
      this.setState({ posts });

      console.log(response.data);

    });


    API.get("searchcust").then((response) => {
      const setCust = response.data;
      this.setState({setCust});
      console.log(response.data);        
      
    });


    API.get("searchitem").then((response) => {
      const itemposts = response.data;
      //this.setState({itemposts});
      console.log(response.data);
      this.setState({
        itemposts: (itemposts.map((person)=>(person.itemname))),
       
        
      })         
      
    });


    




  }

  onClick1 = e => {
    e.preventDefault();
    //alert("Ace!!!!!!   "+ e.currentTarget.value)

    //const userInput = "WINE";
    const userInput = e.currentTarget.value;
    API.get("allitems/", {
      params: {
        selecteditem: userInput
      },
    }).then((res) => {

      const data = res.data;
      console.log(res.data);
      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map(pd => <React.Fragment>
        <Button
        style={{
          float: 'right',
          marginRight: 10,
          marginBottom: 6,
          width: '80px',
          height: '60px',
          fontSize: '10px'
        }} onClick={this.butClick}>{pd.itemname}</Button>
        {/* <img src={pd.itemname} alt="" /> */}
      </React.Fragment>)

      this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        postData
      })


    });


  }


  butClick = e => {
    e.preventDefault();
    //alert("NewPriceLevel  "+ e.currentTarget.innerText);
   
    /* if (NewPriceLevel==="1234"){
      alert("me")

    } */
    API.get("itemdetails/",{
      params:{    
         selecteditem: e.currentTarget.textContent
    
      },
    } ).then((response) => {  
      const posts = response.data;
      this.setState({ posts });        
      console.log(response.data);
      this.setState({
        itemname: (posts.map((person)=>(person.itemname))),
        itemcode: (posts.map((person)=>(person.itemcode))),
        price: (posts.map((person)=>(person.retail))),
        
      }) 
       

    });
    
    
  };

  

  addItemToCart = e => {
    e.preventDefault();
    
    //this.state.Newqty.push(e.currentTarget.value);
    //alert(e.currentTarget.innerText)
    const addqty = e.currentTarget.innerText;
    var oldqty = this.state.qty;
    oldqty=(oldqty + addqty)
    this.setState({ qty: oldqty });

    //alert (addqty)
    //alert(oldqty)
    //console.log(this.state.qty);


    /* this.setState({
      Newqty: [...this.state.Newqty, e.currentTarget.innerText]
  }); */

  /* this.setState({
    Newqty: (e.currentTarget.innerText)
    
  }) 


    alert(this.Newqty) */

  }

   
    
  onKeyDown = e => {
    console.log(filteredSuggestions)
    const { activeSuggestion, filteredSuggestions } = this.state;
    //const {user,setUser}= useContext(UserContext);
    //const newUser = userInput
    console.log(activeSuggestion)

           // setUser(newUser)
    

    // User pressed the enter key
    if (e.keyCode === 13) {
      //const newUser = filteredSuggestions[activeSuggestion]

            
      
      API.get("itemdetails/",{
        params:{    
           selecteditem: filteredSuggestions[activeSuggestion]
      
        },
      } ).then((response) => {  
        const posts = response.data;
        this.setState({ posts });        
        console.log(response.data);

        
        
        this.setState({
          muriga: (posts.map((person)=>(person.itemcode))),
          itemname: (posts.map((person)=>(person.itemname))),
          itemcode: (posts.map((person)=>(person.itemcode))),
          price: (posts.map((person)=>(person.wholesale))),
          sprice1: (posts.map((person)=>(person.superstockist))),
          sprice2: (posts.map((person)=>(person.stockist))),
          sprice3: (posts.map((person)=>(person.retail))),
          sprice4: (posts.map((person)=>(person.retail))),

          
          
          
        }) 
        if(this.state.wholesale===true){         
          
          this.setState({
            
            price: (posts.map((person)=>(person.wholesale))),

          }) 

        }
        
        if(this.state.retail===true){         
          
          this.setState({
            
            price: (posts.map((person)=>(person.retail))),

          }) 

        }

        if(this.state.stockist===true){         
          
          this.setState({
            
            price: (posts.map((person)=>(person.stockist))),

          }) 

        }

        if(this.state.superstockist===true){         
          
          this.setState({
            
            price: (posts.map((person)=>(person.superstockist))),

          }) 

        }
         
  
      });
      //alert(filteredSuggestions[activeSuggestion])
      this.setState({
       // activeSuggestion: 2,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
       // setUser: filteredSuggestions[activeSuggestion],
      });
     // this.refInput.focus();
      //txtqty.focus();
      
      //this.txtqty.current.focus();


    
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  onChange = e => {
    //= {posts.map((person)=>(person.itemname))}  
    //const { suggestions } = this.props;

   /*  this.setState({
      itemname: (posts.map((person)=>(person.itemname))),
      itemcode: (posts.map((person)=>(person.itemcode))),
      price: (posts.map((person)=>(person.retail))),
      
    })  */
   // const { suggestions } = {itemposts.map((person)=>(person.itemname))};
   /* this.setState({
    suggestions:{itemposts.map((person)=>(person.itemname))},

   }) */
   /* {setCust.map(item => {
    return (<option key={item.accname} value={item.accno}  >{item.accname}</option>);
})} */
   //const { suggestions } = {this.itemposts.map((person)=>(person.itemname))};
   const { suggestions } = this.props;
   
    
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    console.log(filteredSuggestions)

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {
   // const { activeSuggestion, filteredSuggestions } = this.state;
    //const {user,setUser}= useContext(UserContext);
    //const newUser = userInput
    //console.log(activeSuggestion)
    //alert(NewPriceLevel);
   
    /* if (NewPriceLevel==="1234"){
      alert("me")

    } */
   // const orig = '   foo  ';
//console.log(orig.trim()); // 'foo'

    //alert(e.currentTarget.innerText)
    
    API.get("itemdetails/",{
      
      params:{    
         selecteditem: e.currentTarget.innerText
        // selecteditem: filteredSuggestions[activeSuggestion]
        
    
      },
    } ).then((response) => {  
      const posts = response.data;
      this.setState({ posts });        
      console.log(response.data);
      this.setState({
        muriga: (posts.map((person)=>(person.itemcode))),
        itemname: (posts.map((person)=>(person.itemname))),
        itemcode: (posts.map((person)=>(person.itemcode))),
        price: (posts.map((person)=>(person.wholesale))),
        sprice1: (posts.map((person)=>(person.superstockist))),
        sprice2: (posts.map((person)=>(person.stockist))),
        sprice3: (posts.map((person)=>(person.retail))),
        sprice4: (posts.map((person)=>(person.retail)))
      }) 

      if(this.state.wholesale===true){         
          
        this.setState({
          
          price: (posts.map((person)=>(person.wholesale))),

        }) 

      }
      
      if(this.state.retail===true){         
        
        this.setState({
          
          price: (posts.map((person)=>(person.retail))),

        }) 

      }

      if(this.state.stockist===true){         
        
        this.setState({
          
          price: (posts.map((person)=>(person.stockist))),

        }) 

      }

      if(this.state.superstockist===true){         
        
        this.setState({
          
          price: (posts.map((person)=>(person.superstockist))),

        }) 

      }
       

    });
    
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    //this.refInput.focus();

    //handleClick();
  };


  handleChange(event) { 
       this.setState({newvalue: event.target.value,custno:event.target.value}); 

  localStorage.setItem('clientname', JSON.stringify("Customer: " + event.target.value));
 
}
 



  render() {

    const labelstyle2 = {
      float: 'right',
      marginRight: 50,
      marginBottom: 6,
      width: '100px',
      fontSize: '20px',
      
      height: 40,
     // backgroundColor: '#8d8d8d',
      backgroundColor: '#ffd700',
      // #8d8d8d
     
    };

    const labelstyle4 = {
      float: 'right',
      marginRight: 50,
      marginBottom: 6,
      width: '100px',
      fontSize: '20px',
      
      height: 40,
     // backgroundColor: '#8d8d8d',
      backgroundColor: '#adff2f',
      // #8d8d8d
     
    };

    const labelstyle1 = {
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '250px',
      fontSize: '16px',
      height: 40,
     // backgroundColor: '#8d8d8d',
      backgroundColor: '#ffd700',
      // #8d8d8d
     
    };

    const labelstyle3 = {
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '200px',
      fontSize: '20px',
      height: 50,
     // backgroundColor: '#8d8d8d',
      backgroundColor: '#eff2f7',
      // #8d8d8d
     
    };
    const butstyle3 = {
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '110px !important',
      fontSize: '28px',
      height: 50,


    };

    const butstyle = {
      /* // display: inline_block,
      border_radius: '4px',
      background_color: '#f4511e',
      //border: none,
      color: '#FFFFFF',
      //text_align: center,
      font_size: '28px',
      padding: '20px',
      width: '100px',
      transition: 'all 0.5s',
      // cursor: pointer,
      margin: '5px', */
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '80px',
      fontSize: '16px'


    };

    /* style={{
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '110px',
      fontSize: '10px'
    }}  */

    


    const {
      onChange,
      onKeyDown,
      receivedData,
      onClick,  
         

      perPage,
      onClick1,
      addItemToCart,
      handleprice,
      handlepriceretail,
      handlepricestockist,
      handlepricesuperstockist,
      
      
      
      

      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        posts,
        componentRef,
        userInput,
        itemname,
      itemcode,
      price,
      sprice1,
      qty,
      Newqty,
      docno,      
      loccode,
      orderslist,
      setCust,
      TotalAmnt,
      itemposts,
      accname,
      
      
      
      
      

      }

    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No Item, please check spelling!!</em>
          </div>
        );
      }
    }

    const addcustomer=event =>{
      alert("Yes!");
    }

    

    const paraadd = event =>{
      const items = JSON.parse(localStorage.getItem('items'));
      const newreceipt = JSON.parse(localStorage.getItem('receiptno'));
      alert(items)
      API.post("paraadd/", {
  
      
        shanzuloccode:JSON.parse(localStorage.getItem('branch')),
        
         shanzudocno: newreceipt + 1 ,
         shanzunewdocno:  newreceipt + 1,
        
           
         
     }).then(() => {
  
  
  
     });
    }
   

   const handleSubmit = event => {
      event.preventDefault();
      const current = new Date();
    //const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
        //event.preventDefault();
        alert(this.state.custno)
         
          API.post("create", {
            item: itemname,
            icode: itemcode,
            price: price,
            qty: qty,
           docno: docno,
           loccode: "101",
            unitcost:"200",
            dated: `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`,
            customer: this.state.newvalue, 
            accno: this.state.custno,
            username:JSON.parse(localStorage.getItem('items')),
            
          }).then((res) => {
            console.log("Success");
            if (res.data.message) {
              alert(res.data.message);
            } else {
             // alert(res.data[0].itemname);          
             
            }
            
      
          });
          //End Post >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  
           alert(docno);      
          API.get("captain/",{
            params:{
              shanzuloccode:loccode,
              shanzudocno: docno,          
      
            },
          }).then((response) => {
            const orderslist = response.data;
            this.setState({orderslist})           
      
          }); 
  
           API.get('totalsale/',{
            params:{
              shanzuloccode:loccode,
              shanzudocno:docno,    
            },
          }).then((response) => {
            const  setTotalSale = response.data;
            this.setState({setTotalSale})  
            
            this.setState({
              TotalAmnt: (setTotalSale.map((person)=>(person.sale))),
              
            }) 

            localStorage.setItem('newtotalsale', JSON.stringify("Total Sale:" + setTotalSale.map((person)=>(person.sale))));
      
      
          }); 

          API.get("searchdepart").then((response) => {
            const posts = response.data;
            this.setState({ posts });
      
            console.log(response.data);
      
          });
      
  
         
          // End search
          this.setState({
            itemname: "",
            price: "",      
            itemcode:"",
            userInput:"",
            qty:"",
          })
         
  
      
        
      
     
      //event.Default();
      {renderBody()}
       }

     //table header
  const renderHeader = () => {
    let headerElement = ['id','itemcode', 'itemname', 'price', 'qty', 'Amount']

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  //table header
  const renderHeader1 = () => {
    let headerElement = ['item', 'qty', 'price', 'total']

    return headerElement.map((key, index) => {
      return <th style={{ textAlign: 'centre', fontSize: '9px' }} key={index}>{key.toUpperCase ()} </th>
    })
  }

  //table body
  const renderBody = () => {

    return orderslist.map(({ id,itemcode, itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={id}>
          <td  onClick={() => handleDelete(id,itemname)}>{id} </td>          
          <td>{itemcode}</td>
          <td>{itemname}</td>
          <td>{price}</td>
          <td>{qty}</td>
          <td>{price * qty} </td>

          {/* <td className='opration'>
                  <button onClick={() => removeData(id)}>Delete</button>
              </td> */}

        </tr>

      )


    })
  }

  //table body2
  const renderBody1 = () => {

    return orderslist.map(({ itemname, price, qty, amount }) => {
      const TotalAmount = 0;
      return (

        <tr key={itemname}>
          <td style={{ textAlign: 'left', width: '100%', fontSize: '8px',fontWeight: 'bold' }}>{itemname}</td>
          <td style={{ textAlign: 'right', fontSize: '8px', width: '10%',fontWeight: 'bold' }}>{qty}</td>
          <td style={{ textAlign: 'right', fontSize: '8px', width: '10%',fontWeight: 'bold' }}>{price}</td>
          <td style={{ textAlign: 'right', fontSize: '8px', width: '20%',fontWeight: 'bold' }}>{price * qty} </td>



        </tr>

      )
     

    })
    
  }
  const marginTop="100px"
const marginRight="5px"
const marginBottom="10px"
const marginLeft="5px"
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

   //PRINT RECEIPT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
   
   const ComponentToPrint = React.forwardRef((props, ref)=> {
    
    //GetSoftdoc();
   // alert("printing")
   //style={{ display: "none" }}
    return (
      // <div  style={{ margin: "1000", padding: "0" }}>
     
      <div style={{getPageMargins}}>         

        
        {/* Customer: {Customername}; */}
        <label style={{ textAlign: 'centre', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>MBIKA  AGENCIES </label>
        <label style={{ textAlign: 'centre', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>KERUGOYA </label>
        <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Sale Date:{datenew};</label>
        <label style={{ textAlign: 'left', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Customer: {this.state.newvalue}</label>
        <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Receipt No: {this.state.docno}</label>





        <Table id='sale'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </Table>

        <br></br>
        <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {TotalAmnt}</label>
        {/* <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Amount Tendered: {TotalAmnt}</label> */}
        {/* <label style={{ textAlign: 'right', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Change: {TotalAmnt}</label> */}

        
        <label style={{ textAlign: 'left', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}>Served By: {JSON.parse(localStorage.getItem('items'))}</label>
       {/*  ({TotalAmnt})
        <br></br>
        <label style={{ textAlign: 'right', fontSize: '14px', fontFamily: 'Arail', width: '100%' }}>Total Sale: {SaleTotal1.TotalsaleN}</label>
       */}  
        <br></br>
        {/* <label style={{ textAlign: 'centre', fontSize: '12px', fontFamily: 'Arail', width: '100%' }}>******KARIBU TENA*********</label> */}

      </div>


    );
    
  });
   
 

  //END RECEIPT PRINT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const itemRefresh =()=>{
    API.get("searchitem").then((response) => {
      const itemposts = response.data;
      //this.setState({itemposts});
      console.log(response.data);
      this.setState({
        itemposts: (itemposts.map((person)=>(person.itemname))),
       
        
      })         
      
    });
  }

  const loaditems =()=>{
    API.get("captain/",{
      params:{
        shanzuloccode:loccode,
        shanzudocno: docno,
       

      },
    }).then((response) => {
      const orderslist = response.data;
      this.setState({orderslist})
       

    });

  }
  const clearqty =()=>{
    alert("clear")
    this.setState({
      itemname: "",
      price: "",      
      itemcode:"",
      userInput:"",
      qty:"",
    })

  }

  const handleDelete = (id,itemname)=>{
      
    API.delete("capdelete/",{
     params:{
       shanzuid:id,
       

     },

    }, {
   }).then(() => {
         
   }); 
   alert(itemname +"  Removed from Cart!!")    
   
   API.get("captain/",{
     params:{
       shanzuloccode:loccode,
       shanzudocno: docno,          

     },
   }).then((response) => {
     const orderslist = response.data;
     this.setState({orderslist})           

   });
   
   API.get('totalsale/',{
     
     params:{
       shanzuloccode:loccode,
       shanzudocno:docno,    
     },
   }).then((response) => {
     const  setTotalSale = response.data;
     this.setState({setTotalSale})  
     
     this.setState({
       TotalAmnt: (setTotalSale.map((person)=>(person.sale))),
       
     }) 

     localStorage.setItem('newtotalsale', JSON.stringify("Total Sale:" + setTotalSale.map((person)=>(person.sale))));


   });


   {renderBody()}

  
 }

    return (
      <div className="form-container">
      
          <Row>
          {/* <div className="scrolling-wrapperace">
            <span>
              {posts.map(post => (
                <Button
                style={{
                  float: 'right',
                  marginRight: 10,
                  marginBottom: 6,
                  width: '100px',
                  height: '40px',
                  fontSize: '10px'
                }} value={post.departmentname} onClick={onClick1}>{post.departmentname} </Button>
                // onMouseOver={() => setItem(post.itemname)}
                //onClick={receivedData} 
              ))}
            </span>

          </div> */}
          <Row className="mb-2">
          <Col xs={4}>
            <Form.Label>Item:</Form.Label>      
            <Form.Control ref={(input) => { this.refInputitem = input; }} autoFocus type="text"  onChange={onChange} onKeyDown={onKeyDown} value={userInput} required/>      
          </Col>
            <Col xs={2}>
              <p>Customer:</p>
            </Col>
                        <Col xs={4}>
                        <select onChange={this.handleChange}>                        
                            {setCust.map(item => {
                                return (<option key={item.accno} value={item.accname}  >{item.accname} </option>);
                                
                            })}
                        </select> 
                        </Col>

                        <Form.Group as={Col} controlId="accno">

    <Form.Check inline label="Wholesale" type="radio" id="radioA" value="Wholesale"  onClick={handleprice} />
    <Form.Check inline label="Retail" type="radio" id="radioB" value="Retail"  onClick={handlepriceretail}  />
    <Form.Check inline label="Stockist" type="radio" id="radioC" value="Stockist"  onClick={handlepricestockist}  />
    <Form.Check inline label="Superstockist" type="radio" id="radioD" value="SuperStockist"  onClick={handlepricesuperstockist}  />
    {/* <Form.Check inline label="by Branch" type="radio" id="radioB" value="Branch" checked={QuerySelect === "Branch"} onChange={(e)=>{setQuerySelect(e.target.value)}}/> */}
    {/* <Form.Check inline label="by Location" type="radio" id="radioB" value="Location" checked={QuerySelect === "Location"} onChange={(e)=>{setQuerySelect(e.target.value)}}/> */}

    
      
    </Form.Group>
            </Row>
          {/* <div>
              {this.state.postData}

            </div> */}
            {/* <Row className="mb-2">
            

          <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </Row> */}
          </Row>
  <Row>
  {suggestionsListComponent}
        
              
<Button style={butstyle} value="1" onClick={addItemToCart}> 1</Button>
<Button style={butstyle} value="2" onClick={addItemToCart}> 2</Button>
<Button style={butstyle} value="3" onClick={addItemToCart}> 3</Button>
<Button style={butstyle} value="4" onClick={addItemToCart}> 4</Button>
<Button style={butstyle} value="5" onClick={addItemToCart}> 5</Button>
<Button style={butstyle} value="6" onClick={addItemToCart}> 6</Button>
<Button style={butstyle} value="7" onClick={addItemToCart}> 7</Button>
<Button style={butstyle} value="8" onClick={addItemToCart}> 8</Button>
<Button style={butstyle} value="9" onClick={addItemToCart}> 9</Button>
<Button style={butstyle} value="10" onClick={addItemToCart}> 10</Button>
<Button style={butstyle} value="0" onClick={addItemToCart}> 0</Button>
<Button style={butstyle} value="." onClick={addItemToCart}> .</Button>
{/*  <Button style={butstyle} value="Clear" onClick={onChange3}> Clear </Button>
<Button style={butstyle} value="Amount" onClick={changeamnt}> PRICE </Button>
<Button style={butstyle} value="Qty" onClick={changeqty}> QTY </Button> */}
<Button
  size="small"
  type="primary"
  style={{
    float: 'right',
    backgroundColor:'#ff6347',
    marginRight: 10,
    marginBottom: 6,
    width: '170px',
    height: '40px',
    fontSize: '16px'
  }}

  

  

  onClick={handleSubmit}

>
  ADD
</Button> 


 

    <Button
    size="small"
    type="primary"
    style={{
      float: 'right',
      marginRight: 10,
      marginBottom: 6,
      width: '80px',
      height: '40px',
      fontSize: '16px'
    }}
    onClick={clearqty} >Clear</Button>

    
</Row>        
<Row className="mb-2">
    
    <Col xs={6}>
    <Form.Label>Item:</Form.Label>      
      <Form.Control style ={labelstyle1} type="text" name="txtitem" value={itemname}  required/>     
    
    </Col>

    {/* <Form.Group as={Col} controlId="txtcode">
    <Form.Label>Code:</Form.Label>      
      <Form.Control  type="text" name="txtcode" value={itemcode}  required/>          
    </Form.Group> */}

    <Col xs={3}>
    <Form.Label>Price:</Form.Label>      
      <Form.Control style ={labelstyle4} type="text" name="txtprice"  value={price} required/>          
    </Col>

    <Col xs={3}>
    <Form.Label>Qty:</Form.Label>      
      <Form.Control style ={labelstyle2} type="text" name="txtqty"  value={qty} required/>          
    </Col>
  </Row>
          


<div>
            {/* <div ref={el=>(this.ComponentRef=el)}> */}
            <div >
            {/* <div className="scrolling-wrapper2"> */}
              <Table striped bordered hover size="sm" id='employee1'>
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>
                  {renderBody()}
                </tbody>
              </Table>
            {/* </div> */}
            </div>  
           
</div>
<Row className="mb-2">
<Col xs ={4}>
{/* <ReactToPrint trigger={()=>{
  //paraadd()
              return<Button
              size="small"
  type="primary"
  style={{
    float: 'centre',
    backgroundColor:'#ffd700',
    marginRight: 20,
    marginBottom: 6,
    width: '150px',
    height: '40px',
    fontSize: '15px'
  }}

   
  
              >Print</Button>
              
            }}
            // content ={()=> this.ComponentRef}
            content ={()=> this.ComponentRef}
            documentTitle="Receipt"
            pagestyle ="print"
            

            /> */}
</Col>
<Col xs={4}>
  Total Qty:
  <Form.Control style ={labelstyle3} type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} />
</Col>
    
    <Col xs={4}>
    Total Sale:

<Form.Control style ={labelstyle3} type="text" name="TotalSaleAmnt" placeholder="Total" value={TotalAmnt} />
</Col>
</Row>
{/* //ref={el=>(this.ComponentRef=el)} */}

{/* <div  ref={el=>(this.ComponentRef=el)}
 
 //style={{ display: "none" }}// This make ComponentToPrint show   only while printing
>
 <ComponentToPrint  />
</div>  */}
<FunctionalComponent/>



      </div>

    )
  }
}

const FunctionalComponent = () => {
  const [orderslist, setOrders] = useState([]);
  const [accname, setAccname] = useState(JSON.parse(localStorage.getItem('branch')));
  const componentRef = useRef();
  const navigate = useNavigate();

  
  const reprint=() =>{
    navigate("/Reprint");
  }
 



var muriga = 0;
    
    
    
const GetSoftdoc = async () => {
  //alert("Morining dumbsell")
  API.get("findsoftdoc",{
    params: {
      softsearch: accname,
    },

  }).then((res) => {
    
    //console.log(res.data)
    muriga = (res.data.map((person)=>(person.newdocno)))
   // alert(muriga);
    

    

    const ordersView = async () => {
      alert(muriga)
      alert(accname)
    
      API.get("captain/",{
        params:{
          shanzuloccode:accname,
          shanzudocno:muriga,
         
  
        },
      }).then((response) => {
        setOrders(response.data);
        console.log(response.data)


      });
      //alert("New doc 1  " + muriga)

    };
    ordersView();

    

    
   
    

  });

  


};



//table header
const renderHeader1 = () => {
  let headerElement = ['item', 'qty', 'price', 'total']

  return headerElement.map((key, index) => {
    return <th style={{ textAlign: 'centre', fontSize: '10px' }} key={index}>{key.toUpperCase ()} </th>
  })
}


//table body2
const renderBody1 = () => {
  

  return orderslist.map(({ id,itemname, price, qty, amount }) => {
    const TotalAmount = 0;
    return (

      <tr key={id}>
        <td style={{ textAlign: 'left', width: '100%', fontSize: '9px',fontWeight: 'bold' }}>{itemname}</td>
        <td style={{ textAlign: 'right', fontSize: '9px', width: '10%',fontWeight: 'bold' }}>{qty}</td>
        <td style={{ textAlign: 'right', fontSize: '9px', width: '10%',fontWeight: 'bold' }}>{price}</td>
        <td style={{ textAlign: 'right', fontSize: '9px', width: '20%',fontWeight: 'bold' }}>{price * qty} </td>



      </tr>

    )
   

  })
  
}


//Para Docno Save 8888888888888888888888888888888888888888888888888888888888888888888888
const ParaAdd = () => {
  alert("Printing........")
  //alert(JSON.parse(localStorage.getItem('receiptdoc')))
  const newreceipt = JSON.parse(localStorage.getItem('receiptdoc'));
  muriga = newreceipt;
  //alert("Murigah" + muriga)

 API.post("paraadd/", {

  
    shanzuloccode:accname,
     shanzudocno: muriga ,
     shanzunewdocno:  Number(muriga) + Number(1),
    
       
     
 }).then(() => {



 });


};

const marginTop="100px"
const marginRight="5px"
const marginBottom="10px"
const marginLeft="5px"
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

//let copy = React.string({js|\u00a9|js});

   //PRINT RECEIPT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
   
   const ComponentToPrint = React.forwardRef((props, ref) => {
    //console.log("Printing");
    //setChange(Math.round({tenderamount} - {TotalAmnt}))
   // setChange(tenderamount - TotalAmnt)

   //console.log(Change)
   
    return (
      
      //<div ref={ref} style={{ margin: "00", padding: "0" }}>
      //<div ref={ref} style={{getPageMargins}}>
      <div ref={ref} >

        <label style={{ textAlign: 'centre', fontSize: '14px', fontFamily: 'Arail', width: '100%',fontWeight: 'bold' }}>MBIKA  AGENCIES </label>
        <label style={{ textAlign: 'centre', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>KERUGOYA </label>
        <label style={{ textAlign: 'right', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>Sale Date:{datenew};</label>
        {/* Customer: {Customername}; */}
        <label style={{ textAlign: 'left', fontSize: '10px', fontFamily: 'Arail' }}>{JSON.parse(localStorage.getItem('clientname'))}</label>
        <label style={{ textAlign: 'right', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}> {JSON.parse(localStorage.getItem('receiptno'))} </label>
        {/* <label style={{ textAlign: 'left', fontSize: '16px', fontFamily: 'Arail', width: '100%' }}> {JSON.parse(localStorage.getItem('receiptno'))}</label> */}

        <table id='employee'>
          <thead >
            <tr>{renderHeader1()} </tr>
          </thead>
          <tbody>
            {renderBody1()}
          </tbody>
        </table>






        
       
        {/* ({TotalAmnt}) */}
        {/* localStorage.setItem('newtotalsale', JSON.stringify("Total Sale:" + setTotalSale.map((person)=>(person.sale)))); */}
        <label style={{ textAlign: 'right', fontSize: '12px', fontFamily: 'Arail', width: '100%',fontWeight: 'bold' }}> {JSON.parse(localStorage.getItem('newtotalsale'))}</label>
        
        
        <label style={{ textAlign: 'left', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>Served By: {JSON.parse(localStorage.getItem('items'))}</label>
         
        
        <br></br>
        <label style={{ textAlign: 'centre', fontSize: '10px', fontFamily: 'Arail', width: '100%' }}>***SOFTCRAFT {'\u00A9'} 2022***</label>

      </div>


    );
    
  });
 

  //END RECEIPT PRINT$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  const handlePrint = useReactToPrint({
     /* documentTitle: "new documentation",
      marginTop:"100px",
     marginRight:"100px",
   marginBottom:"10px",
   marginLeft:"5px",   */
    //pageStyle:"@page { size: 80mm 80mm}",



   /*  marginTop:"100px",
    marginBottom:"10px",
    pageStyle:"@page {auto; margin: 10mm 0 10mm 0}", */
  
 /*  body {
      margin:0;
      padding:0;
  } */

   // pageStyle:"@page { size: 2.5in 4in }",
   pageStyle:"@page { size: 3.5in 4in,margin: 10mm 0 10mm 0 }",
    //pageStyle: "@page { size: 80mm 1mm }",
    //pageStyle:"@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }",
    headerElement: "BUSH TEXTILES",
    textAlign: 'center',
    format: 'CODE128',
    fontSize: 8,


    content: () => componentRef.current,
    


    

    //pagestyle: "print",
    // onAfterPrint={() => { console.log("Document Printed") }},
  });

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleClick = async event => {
    
    console.log('before');
    GetSoftdoc();
    await delay(1000);
    console.log('after');
    ParaAdd();
    await delay(2000);
    console.log('end');
    
    handlePrint();
    
    console.log('finish');
    await delay(8000);

    window.location.reload(); 
  };

  return(
    <div>
     
      <Button
      style={{
        float: 'centre',
        backgroundColor:'#ffd700',
        marginRight: 20,
        marginBottom: 6,
        width: '130px',
        height: '40px',
        fontSize: '15px'
      }}
      
      onClick={handleClick}>PRINT</Button>

<Button
      style={{
        float: 'centre',
        backgroundColor:'#ffd700',
        marginRight: 20,
        marginBottom: 6,
        width: '130px',
        height: '40px',
        fontSize: '15px'
      }}
      
      >CLEAR </Button>

<Button
      style={{
        float: 'centre',
        backgroundColor:'#ffd700',
        marginRight: 20,
        marginBottom: 6,
        width: '130px',
        height: '40px',
        fontSize: '15px'
      }}
      
      >REFRESH </Button>

<Button
      style={{
        float: 'centre',
        backgroundColor:'#ffd700',
        marginRight: 20,
        marginBottom: 6,
        width: '130px',
        height: '40px',
        fontSize: '15px'
      }}
      
      >HOLD </Button>

<Button
      style={{
        float: 'centre',
        backgroundColor:'#ff4500',
        marginRight: 20,
        marginBottom: 6,
        width: '130px',
        height: '40px',
        fontSize: '15px'
      }}
      
      >RECALL </Button>

<Button
      style={{
        float: 'centre',
        backgroundColor:'#32cd32',
        marginRight: 20,
        marginBottom: 6,
        width: '130px',
        height: '40px',
        fontSize: '15px'
      }}

      
    onClick={reprint}  >REPRINT </Button>

      <div
 
               style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
              <ComponentToPrint ref={componentRef} />
            </div>

    </div>
  ) 
};
